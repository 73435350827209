<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle">
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn">
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                                  fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="box-body">
                        <div class="border-b-0 border-gray-200 dark:border-white/10">
                            <nav class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400"
                                 aria-label="Tabs">
                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 active"
                                        id="hs-tab-js-behavior-item-1" data-hs-tab="#hs-tab-js-behavior-1"
                                        aria-controls="hs-tab-js-behavior-1">
                                    Information de base
                                </button>


                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent hs-tab-active:text-primary
                                                dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm
                                                hover:text-primary dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300"
                                        id="hs-tab-js-behavior-item-4" data-hs-tab="#hs-tab-js-behavior-4"
                                        aria-controls="hs-tab-js-behavior-4">
                                    Autre adresse(s)
                                </button>
                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent hs-tab-active:text-primary
                                                dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm
                                                hover:text-primary dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300"
                                        id="hs-tab-js-behavior-item-5" data-hs-tab="#hs-tab-js-behavior-5"
                                        aria-controls="hs-tab-js-behavior-5">
                                    Autre contact(s)
                                </button>


                            </nav>
                        </div>

                        <div class="">
                            <div id="hs-tab-js-behavior-1" role="tabpanel" aria-labelledby="hs-tab-js-behavior-item-1"
                                 class="">
                                <div class="ti-custom-validation mt-5">
                                    <div class="grid sm:grid-cols-3 gap-6">



                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">N°
                                                matricule</label>
                                            <input type="text" v-model="Items.matricul"
                                                   class="firstName my-auto ti-form-input  rounded-sm"
                                                   placeholder="N° matricule" required>
                                            <span class="firstNameError text-red-500 text-xs hidden">error</span>
                                        </div>
                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Nom et
                                                prénom(s)</label>
                                            <input type="text" v-model="Items.name"
                                                   class="lastName my-auto ti-form-input  rounded-sm"
                                                   placeholder="Nom et prénom(s)" required>
                                            <span class="lastNameError text-red-500 text-xs hidden">error</span>
                                        </div>


                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Téléphone</label>
                                            <input type="number" v-model="Items.telephone"
                                                   class="phonenumber my-auto ti-form-input  rounded-sm"
                                                   placeholder="+229 ** ** ** **" required>
                                            <span class="phoneError text-red-500 text-xs hidden">error</span>
                                        </div>

                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Adresse</label>
                                            <input type="text" v-model="Items.adresse"
                                                   class="adresse my-auto ti-form-input  rounded-sm"
                                                   placeholder="Adresse" required>
                                            <span class="lastNameError text-red-500 text-xs hidden">error</span>
                                        </div>

                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Sexe</label>
                                            <ul class="flex flex-col sm:flex-row">
                                                <li class="ti-list-group w-full gap-x-2.5 py-2 px-4 bg-white dark:bg-bodybg border text-gray-800
                                                                rounded-none border-e-0 sm:-ms-px sm:mt-0  !rounded-e-sm dark:bg-bgdark dark:border-white/10 dark:text-white">
                                                    <div class="relative flex items-start w-full">
                                                        <div class="flex items-center h-5">
                                                            <input id="ti-radio-validation-11"
                                                                   v-model="Items.jsonv.sexe" value="Masculin"
                                                                   type="radio" class="ti-form-radio"
                                                                   :checked="Items.jsonv.sexe === 'Masculin'" required>
                                                        </div>
                                                        <label for="ti-radio-validation-11"
                                                               class="ms-3 block w-full text-sm text-gray-600 dark:text-[#8c9097] dark:text-white/50">
                                                            Masculin
                                                        </label>
                                                    </div>
                                                </li>

                                                <li class="ti-list-group w-full gap-x-2.5 py-2 px-4 bg-white dark:bg-bodybg border text-gray-800 sm:-ms-px
                                                                !rounded-none !border-e-0 dark:bg-bgdark dark:border-white/10 dark:text-white">
                                                    <div class="relative flex items-start w-full">
                                                        <div class="flex items-center h-5">
                                                            <input id="ti-radio-validation-12"
                                                                   v-model="Items.jsonv.sexe" value="Féminin"
                                                                   :checked="Items.jsonv.sexe === 'Féminin'"
                                                                   type="radio" class="ti-form-radio" required>
                                                        </div>
                                                        <label for="ti-radio-validation-12" class="ms-3 block w-full text-sm text-gray-600
                                                                        dark:text-[#8c9097] dark:text-white/50">
                                                            Féminin
                                                        </label>
                                                    </div>
                                                </li>


                                            </ul>
                                        </div>


                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Qualification</label>


                                            <div class="grid grid-cols-12  gap-x-6">

                                                <div class="xl:col-span-10  col-span-12" >
                                                    <input placeholder="Veuillez sélectionner une qualification" v-if="Items.jsonv.qualification===null" type="text" class="form-control" disabled>
                                                    <input v-else :value="Items.jsonv.qualification.name" type="text" class="form-control" disabled>
                                                </div>
                                                <div class="xl:col-span-2  col-span-12">

                                                    <button title="Sélectionner une qualification" @click="btnmodallistefichierdebase('qualification')"  class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>


                                                </div>




                                            </div>


                                            <span class="emailError text-red-500 text-xs hidden">error</span>
                                        </div>

                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Mode de
                                                paiement</label>
                                            <select v-model="Items.jsonv.modedepaiement" id="inputState4"
                                                    class="form-select !py-[0.59rem]">
                                                <option selected value=null>Veuillez sélectionner un mode paiement</option>
                                                <option :key="index" :value="modedepaiement" v-for="(modedepaiement,index) of datamodedereglement">{{modedepaiement.name}}</option>

                                            </select>
                                            <span class="emailError text-red-500 text-xs hidden">error</span>
                                        </div>

                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Fonction</label>

                                            <div class="grid grid-cols-12  gap-x-6">

                                                <div class="xl:col-span-10  col-span-12" >
                                                    <input placeholder="Veuillez sélectionner une fonction"  v-if="Items.jsonv.fonction===null" type="text" class="form-control" disabled>
                                                    <input v-else :value="Items.jsonv.fonction.name" type="text" class="form-control" disabled>
                                                </div>
                                                <div class="xl:col-span-2  col-span-12">
                                                    <button title="Sélectionner une fonction" @click="btnmodallistefichierdebase('fonction')"  class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>


                                                </div>




                                            </div>




                                            <span class="emailError text-red-500 text-xs hidden">error</span>
                                        </div>



                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Service</label>


                                            <div class="grid grid-cols-12  gap-x-6">

                                                <div class="xl:col-span-10  col-span-12" >
                                                    <input placeholder="Veuillez sélectionner un service"  v-if="Items.jsonv.service===null" type="text" class="form-control" disabled>
                                                    <input v-else :value="Items.jsonv.service.name" type="text" class="form-control" disabled>
                                                </div>
                                                <div class="xl:col-span-2  col-span-12">

                                                    <button title="Sélectionner un service"  @click="btnmodallistefichierdebase('service')"  class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>


                                                </div>




                                            </div>



                                            <span class="emailError text-red-500 text-xs hidden">error</span>
                                        </div>




                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Type d'occasionnel
                                            </label>
                                            <select v-model="Items.jsondata.typecollaborateur" id="inputState123" class="form-select !py-[0.59rem]">
                                                <option value="OUVRIER">Occasionnel payé par jours</option>
                                                <option value="AO">Occasionnel payé par tâches</option>
                                            </select>
                                            <span class="BanqueError text-red-500 text-xs hidden">error</span>
                                        </div>

                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">A pris
                                                service le</label>
                                            <input type="date" v-model="Items.jsonv.aprisleservicele"
                                                   @change="comparedate"      class="prisservicele ti-form-input  rounded-sm flatpickr-input"
                                                   required>
                                            <span class="dobError text-red-500 text-xs hidden">error</span>
                                        </div>

                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Date
                                                fin</label>
                                            <input type="date" v-model="Items.jsonv.datefin"
                                                   :disabled="datefindislable"   class="datefin ti-form-input  rounded-sm flatpickr-input" required>
                                            <span class="dobError text-red-500 text-xs hidden">error</span>
                                        </div>

                                    </div>


                                </div>
                            </div>


                            <div id="hs-tab-js-behavior-4" class="hidden" role="tabpanel"
                                 aria-labelledby="hs-tab-js-behavior-item-4">


                                <div class="ti-custom-validation mt-5">

                                    <div class="grid lg:grid-cols-2 gap-6 mt-3">

                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Information
                                                :</label>
                                            <input type="text" v-model="Items.jsondata.adresses.info"
                                                   class="info my-auto ti-form-input  rounded-sm" placeholder="Adresse">
                                            <span class="infoerror text-red-500 text-xs hidden">error</span>
                                        </div>


                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Information
                                                1:</label>
                                            <input type="text" v-model="Items.jsondata.adresses.info1"
                                                   class="info1 my-auto ti-form-input  rounded-sm"
                                                   placeholder="Adresse 1">
                                            <span class="infoerror1 text-red-500 text-xs hidden">error</span>
                                        </div>


                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Information
                                                2:</label>
                                            <input type="text" v-model="Items.jsondata.adresses.info2"
                                                   class="info2 my-auto ti-form-input  rounded-sm"
                                                   placeholder="Adresse 2">
                                            <span class="infoerror2 text-red-500 text-xs hidden">error</span>
                                        </div>


                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Information
                                                3:</label>
                                            <input type="text" v-model="Items.jsondata.adresses.info3"
                                                   class="info3 my-auto ti-form-input  rounded-sm"
                                                   placeholder="Adresse 3">
                                            <span class="infoerror3 text-red-500 text-xs hidden">error</span>
                                        </div>


                                    </div>


                                </div>


                            </div>


                            <div id="hs-tab-js-behavior-5" class="hidden" role="tabpanel"
                                 aria-labelledby="hs-tab-js-behavior-item-5">


                                <div class="ti-custom-validation mt-5">

                                    <div class="grid lg:grid-cols-2 gap-6 mt-3">

                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Information
                                                :</label>
                                            <input type="text" v-model="Items.jsondata.contacts.info"
                                                   class="infocontact my-auto ti-form-input  rounded-sm"
                                                   placeholder="Contact">
                                            <span class="infocontacterror text-red-500 text-xs hidden">error</span>
                                        </div>


                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Information
                                                1:</label>
                                            <input type="text" v-model="Items.jsondata.contacts.info1"
                                                   class="infocontact1 my-auto ti-form-input  rounded-sm"
                                                   placeholder="Contact 1">
                                            <span class="infocontacterror1 text-red-500 text-xs hidden">error</span>
                                        </div>


                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Information
                                                2:</label>
                                            <input type="text" v-model="Items.jsondata.contacts.info2"
                                                   class="infocontact2 my-auto ti-form-input  rounded-sm"
                                                   placeholder="Contact 2">
                                            <span class="infocontacterror2 text-red-500 text-xs hidden">error</span>
                                        </div>


                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Information
                                                3:</label>
                                            <input type="text" v-model="Items.jsondata.contacts.info3"
                                                   class="infocontact3 my-auto ti-form-input  rounded-sm"
                                                   placeholder="Contact 3">
                                            <span class="infocontacterror3 text-red-500 text-xs hidden">error</span>
                                        </div>


                                    </div>


                                </div>


                            </div>


                        </div>
                    </div>


                </div>
                <div class="ti-modal-footer">
                    <button style="background: red" @click="close" type="button"
                            class="hs-dropdown-toggle ti-btn ti-btn-secondary-full">
                        Fermer
                    </button>
                    <button @click="saveItems" style="background: green" v-if="typeaction==='add'" class="ti-btn ti-btn-primary-full"
                    >
                        Enregistrer
                    </button>
                    <button style="background: green" @click="updateItems"  v-if="typeaction==='update'" class="ti-btn ti-btn-primary-full"
                    >
                        Modifier
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>


    <modallistefichierdebase @elementSelectionne="handleElementSelectionne" ref="modallistefichierdebase"></modallistefichierdebase>



</template>

<script>
    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/definition/menu";
    // import allfunction from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios = require('axios');
    import vSelect from '@vueform/multiselect';
    import modallistefichierdebase from "@/components/views/pages/definition/modal/modal_liste_fichierdebase";
    import { currentYear, randomString} from "@/allfunction";

    export default {
        /* eslint-disable */
        name: "modalform",
        watch: {},

        computed: {


            datefindislable: function () {

                if (this.Items.jsonv.typedecontrat === "CDI") {
                    return true;
                    //  return "";
                } else {
                    return false;
                    // return "disabled";
                }

            },

            comptebanquairedislable: function () {

                this.Items.jsonv.banque.comptebanquaire="";


                if (this.Items.jsonv.banque.namebanque===null ||  this.Items.jsonv.banque.namebanque==='null') {


                    this.Items.jsonv.banque.namebanque=null



                    return true;
                    //  return "";
                } else {


                    return false;
                    // return "disabled";
                }

            }


        },
        components: {
            templatesite,
            appheader,
            appsidebar,
            appmenu,
            modallistefichierdebase,
            vSelect,

        },

        data() {
            return {

                isdisplay: false,
                titlemodal: "",
                messageerror:"",
                messagesucces:"",
                typeaction: "add",
                otherdatas: [],
                dataservice: [],
                datafonction: [],
                dataemploie: [],
                dataqualification: [],
                datamodedereglement: [],
                databanque: [],
                Items: {
                    pid: '', matricul: '', name: '', nele: null, adresse: '',
                    lieudenaissance: '', telephone: '', nbredenfant: 0, persuse: 0,
                    jsonv: {
                        sexe: 'Masculin',
                        situationmatrimonial: 'Célibataire',
                        emploie: null,
                        qualification: null,
                        modedepaiement: null,
                        service: null,
                        fonction: null,
                        banque: {
                            namebanque: null,
                            comptebanquaire: ''
                        },
                        typedesuiviepresence:'regledegestion',
                        referencescnss: '',
                        referencescni: '',
                        referencesifu: '',
                        dernierdiplome: '',
                        typedepersonnel: 'Permanent',
                        typedecontrat: 'CDD',
                        medecinexecutant: 'non',
                        aprisleservicele: null,
                        datefin: null,
                        photo: '',


                    },
                    jsondata: {
                        comptetva: null,
                        typecollaborateur: 'none',
                        general: {adresse: '', telephone: '', ifu: ''},
                        adresses: {info: '', info1: '', info2: '', info3: ''},
                        contacts: {info: '', info1: '', info2: '', info3: ''},
                        autreinfo: {
                            referencescnss: '',
                            referencescni: '',
                            referencesifu: '',
                            dernierdiplome: '',
                            deductiondesretard: '1',
                            congedue: 0,
                        },
                        paramdindication: {param: null, param1: null},

                    }
                }

            };
        },
        methods: {

            handleElementSelectionne(elementSelectionne) {
                // Gérer l'élément sélectionné reçu de ModalComponent

                //  console.log("elementSelectionne ********** ",elementSelectionne)




                if (elementSelectionne.typ==="service"){
                    this.Items.jsonv.service=elementSelectionne.value
                }

                if (elementSelectionne.typ==="fonction"){
                    this.Items.jsonv.fonction=elementSelectionne.value
                }

                if (elementSelectionne.typ==="qualification"){
                    this.Items.jsonv.qualification=elementSelectionne.value
                }




            },


            comparedate(){
                /*
                                if (this.cltfrs.jsonv.datefin!==null){

                                    if (this.cltfrs.jsonv.aprisleservicele===null){

                                        toastr['error']( 'Veuillez saisir la date de prise de service',"<b></b>");
                                    }
                                    else{
                                        if (this.cltfrs.jsonv.typedecontrat!=="CDI"){

                // les dates étant saisies en français, on les transforme
                // Ici sont acceptés comme séparateurs le tiret, le slash et l'espace
                                            let   tabdeb,tabfin=[];
                                            tabdeb = (this.cltfrs.jsonv.aprisleservicele.split(/[- //]/));
                                             tabfin = (this.cltfrs.jsonv.datefin.split(/[- //]/));
                                            let  Odeb = new Date(tabdeb[2],tabdeb[1],tabdeb[0]);
                                            let  Ofin = new Date(tabfin[2],tabfin[1],tabfin[0]);
                                            if(Odeb > Ofin) {
                                                this.cltfrs.jsonv.aprisleservicele=null;
                                                this.cltfrs.jsonv.datefin=null;

                                                toastr['error']( 'La date de fin ne doit pas être antérieure la date de prise de service',"<b></b>");

                                                return false
                                            };

                                        }

                                    }

                */

            }

            ,

            close() {

                this.isdisplay = false;

                this.$emit('closed');

            },


            btnmodallistefichierdebase(typ){

                let listedatable=[],
                    titlemodal="",
                    typedoc=typ;



                if (typ==="service"){

                    listedatable=this.dataservice
                    titlemodal="Liste des services"
                }

                if (typ==="fonction"){

                    listedatable=this.datafonction
                    titlemodal="Liste des fonctions"
                }

                if (typ==="qualification"){

                    listedatable=this.dataqualification
                    titlemodal="Liste des qualifications"
                }



                this.$refs.modallistefichierdebase.show(titlemodal,listedatable,typedoc);

            },


            async returnOtherdatasbyType(valuetype) {

                const t = this;
                let url = process.env.VUE_APP_API_URL + '/egl745874pdefinition771714dfe/tier/otherdatafdonfo784546dff/' + valuetype + '/' + this.$store.state.appsociete + '/' + randomString();
                let response = axios.get(url, {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res = await response
                    .then(function (res) {


                        if (res.data.success) {




                            console.log("res.data.m ***************   ",res.data.m)


                            return res.data.m;

                        } else {
                            return [];
                        }


                    })
                    .catch((error) => {

                            return []


                        }
                    )
                    .finally(() => {
                    })


            },
            async loadservice() {

                const t = this;
                let url = process.env.VUE_APP_API_URL + '/egl745874pdefinition771714dfe/tier/otherdatafdonfo784546dff/service/' + this.$store.state.appsociete + '/' + randomString();
                let response = axios.get(url, {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res = await response
                    .then(function (res) {


                        if (res.data.success) {



                            t.dataservice=res.data.m;


                        } else {
                            t.dataservice= [];
                        }


                    })
                    .catch((error) => {

                            t.dataservice= [];


                        }
                    )
                    .finally(() => {
                    })


            },

            async loadfonction() {

                const t = this;
                let url = process.env.VUE_APP_API_URL + '/egl745874pdefinition771714dfe/tier/otherdatafdonfo784546dff/fonction/' + this.$store.state.appsociete + '/' + randomString();
                let response = axios.get(url, {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res = await response
                    .then(function (res) {


                        if (res.data.success) {



                            t.datafonction=res.data.m;


                        } else {
                            t.datafonction= [];
                        }


                    })
                    .catch((error) => {

                            t.datafonction= [];


                        }
                    )
                    .finally(() => {
                    })


            },


            async loademploie() {
/*
                const t = this;
                let url = process.env.VUE_APP_API_URL + '/egl745874pdefinition771714dfe/tier/otherdatafdonfo784546dff/categorie/' + this.$store.state.appsociete + '/' + randomString();
                let response = axios.get(url, {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res = await response
                    .then(function (res) {


                        if (res.data.success) {



                            t.dataemploie=res.data.m;


                        } else {
                            t.dataemploie= [];
                        }


                    })
                    .catch((error) => {

                            t.dataemploie= [];


                        }
                    )
                    .finally(() => {
                    })
*/

            },


            async loadqualification() {

                const t = this;
                let url = process.env.VUE_APP_API_URL + '/egl745874pdefinition771714dfe/tier/otherdatafdonfo784546dff/qualification/' + this.$store.state.appsociete + '/' + randomString();
                let response = axios.get(url, {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res = await response
                    .then(function (res) {


                        if (res.data.success) {



                            t.dataqualification=res.data.m;


                        } else {
                            t.dataqualification= [];
                        }


                    })
                    .catch((error) => {

                            t.dataqualification= [];


                        }
                    )
                    .finally(() => {
                    })


            },



            async loadmdreglement() {

                const t = this;
                let url = process.env.VUE_APP_API_URL + '/egl745874pdefinition771714dfe/tier/otherdatafdonfo784546dff/mdreglement/' + this.$store.state.appsociete + '/' + randomString();
                let response = axios.get(url, {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res = await response
                    .then(function (res) {


                        if (res.data.success) {



                            t.datamodedereglement=res.data.m;


                        } else {
                            t.datamodedereglement= [];
                        }


                    })
                    .catch((error) => {

                            t.datamodedereglement= [];


                        }
                    )
                    .finally(() => {
                    })


            },



            async loadbanque() {
/*
                const t = this;
                let url = process.env.VUE_APP_API_URL + '/egl745874pdefinition771714dfe/tier/otherdatafdonfo784546dff/banque/' + this.$store.state.appsociete + '/' + randomString();
                let response = axios.get(url, {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res = await response
                    .then(function (res) {


                        if (res.data.success) {



                            t.databanque=res.data.m;


                        } else {
                            t.databanque= [];
                        }


                    })
                    .catch((error) => {

                            t.databanque= [];


                        }
                    )
                    .finally(() => {
                    })
*/

            },


            show(titlemodal, typeaction, otherdatas, Items) {

                this.loadservice();
                this.loadfonction();
                this.loademploie();
                this.loadqualification();
                this.loadmdreglement();
                this.loadbanque();

                this.titlemodal = titlemodal
                this.typeaction = typeaction
                this.otherdatas = otherdatas
                this.Items = Items

                this.isdisplay = true;

            },



            async saveItems(){

                this.$store.dispatch('lancerchargement',true)



                if (this.Items.name==="" || this.Items.adresse===""
                    ||  this.Items.telephone===""
                    ||
                    this.Items.jsonv.qualification===null ||
                    this.Items.jsonv.modedepaiement===null ||
                    this.Items.jsonv.service===null ||
                    this.Items.jsonv.fonction===null

                )
                {

                    this.$store.dispatch('lancerchargement',false)
                    toastr['error']( 'Veuillez saisir toutes les informations au niveau de l\'onglet informations' +
                        ' de base',"<b></b>");
                    return
                }


                if (
                    this.Items.jsonv.aprisleservicele===null

                ){

                    this.$store.dispatch('lancerchargement',false)
                    toastr['error']( 'Veuillez saisir la date de prise service',"<b></b>");
                    return
                }

                if (this.Items.jsonv.typedecontrat!=="CDI"){
                    if (
                        this.Items.jsonv.datefin===null

                    ){

                        this.$store.dispatch('lancerchargement',false)
                        toastr['error']( 'Veuillez saisir la date de fin',"<b></b>");
                        return
                    }
                }



                if (this.Items.jsonv.typedecontrat!=="CDI"){

// les dates étant saisies en français, on les transforme
// Ici sont acceptés comme séparateurs le tiret, le slash et l'espace
                    let   tabdeb,tabfin=[];
                    tabdeb = (this.Items.jsonv.aprisleservicele.split(/[- //]/));
                    tabfin = (this.Items.jsonv.datefin.split(/[- //]/));
                    let  Odeb = new Date(tabdeb[2],tabdeb[1],tabdeb[0]);
                    let  Ofin = new Date(tabfin[2],tabfin[1],tabfin[0]);
                    if(Odeb > Ofin) {
                        this.Items.jsonv.aprisleservicele=null;
                        this.Items.jsonv.datefin=null;


                        this.$store.dispatch('lancerchargement',false)
                        toastr['error']( 'La date de fin ne doit pas être antérieure la date de prise de service',"<b></b>");

                        return
                    };

                }


                //  this.cltfrs.beartoken=this.$store.state.beartoken;
                // const t = this;
                //let response =   axios  .post('/api/pers86555486kjbiubbkj887/_shfjdhf846589/'+this.$store.state.appsociete+'/'+this.$store.state.beartoken, this.cltfrs)

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874pdefinition771714dfe/tiers/occasionnel/'+this.$store.state.appsociete+'/add/'+randomString();
                let response = axios.post(url, this.Items,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })


                let res= await response
                    .then(function (res){

                        t.$store.dispatch('lancerchargement',false)
                        if(res.data.success){


                            t.Items=  {
                                pid:'',matricul:'',name:'',nele:'1951-01-01',adresse:'',
                                lieudenaissance:'',telephone:'',nbredenfant:0,persuse:0,
                                jsonv:{
                                    sexe:'Masculin',
                                    situationmatrimonial:'Celibataire',
                                    emploie:null,
                                    qualification:null,
                                    modedepaiement:null,
                                    service:null,
                                    fonction:null,
                                    banque:{
                                        namebanque:null,
                                        comptebanquaire:''
                                    },
                                    typedesuiviepresence:'regledegestion',
                                    referencescnss:'',
                                    referencescni:'',
                                    referencesifu:'',
                                    dernierdiplome:'',
                                    typedepersonnel:'Permanent',
                                    typedecontrat:'CDD',
                                    medecinexecutant:'non',
                                    aprisleservicele:currentYear()+"-01-01",
                                    datefin:currentYear()+"-12-31",
                                    photo:'',


                                },
                                jsondata:{
                                    comptetva:null,
                                    typecollaborateur:'OUVRIER',
                                    general:{adresse:'',telephone:'',ifu:''},
                                    adresses:{info:'',info1:'',info2:'',info3:''},
                                    contacts:{info:'',info1:'',info2:'',info3:''},
                                    autreinfo:{
                                        referencescnss:'',
                                        referencescni:'',
                                        referencesifu:'',
                                        dernierdiplome:'',
                                        deductiondesretard:'1',
                                        congedue:0,
                                    },
                                    paramdindication:{param:null,param1:null},

                                }
                            }



                            toastr['success']( res.data.message,"<b></b>");



                        }else{


                            let errormessage="";

                            if (res.data.datamessage.length>0){

                                for (let message of res.data.datamessage){

                                    if (errormessage===""){

                                        errormessage=message;

                                    }else{

                                        errormessage+=" , "+message;

                                    }


                                }

                            }
                            else{
                                errormessage=res.data.message
                            }


                            toastr['error'](errormessage,"<b></b>");

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))






            },



            async updateItems(){

                this.$store.dispatch('lancerchargement',true)



                if (this.Items.name==="" || this.Items.adresse===""
                    ||  this.Items.telephone===""
                    ||
                    this.Items.jsonv.qualification===null ||
                    this.Items.jsonv.modedepaiement===null ||
                    this.Items.jsonv.service===null ||
                    this.Items.jsonv.fonction===null

                )
                {

                    this.$store.dispatch('lancerchargement',false)
                    toastr['error']( 'Veuillez saisir toutes les informations au niveau de l\'onglet informations' +
                        ' de base',"<b></b>");
                    return
                }


                if (
                    this.Items.jsonv.aprisleservicele===null

                ){

                    this.$store.dispatch('lancerchargement',false)
                    toastr['error']( 'Veuillez saisir la date de prise service',"<b></b>");
                    return
                }

                if (this.Items.jsonv.typedecontrat!=="CDI"){
                    if (
                        this.Items.jsonv.datefin===null

                    ){

                        this.$store.dispatch('lancerchargement',false)
                        toastr['error']( 'Veuillez saisir la date de fin',"<b></b>");
                        return
                    }
                }



                if (this.Items.jsonv.typedecontrat!=="CDI"){

// les dates étant saisies en français, on les transforme
// Ici sont acceptés comme séparateurs le tiret, le slash et l'espace
                    let   tabdeb,tabfin=[];
                    tabdeb = (this.Items.jsonv.aprisleservicele.split(/[- //]/));
                    tabfin = (this.Items.jsonv.datefin.split(/[- //]/));
                    let  Odeb = new Date(tabdeb[2],tabdeb[1],tabdeb[0]);
                    let  Ofin = new Date(tabfin[2],tabfin[1],tabfin[0]);
                    if(Odeb > Ofin) {
                        this.Items.jsonv.aprisleservicele=null;
                        this.Items.jsonv.datefin=null;


                        this.$store.dispatch('lancerchargement',false)
                        toastr['error']( 'La date de fin ne doit pas être antérieure la date de prise de service',"<b></b>");

                        return
                    };

                }


                //  this.cltfrs.beartoken=this.$store.state.beartoken;
                // const t = this;
                //let response =   axios  .post('/api/pers86555486kjbiubbkj887/_shfjdhf846589/'+this.$store.state.appsociete+'/'+this.$store.state.beartoken, this.cltfrs)

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874pdefinition771714dfe/tiers/occasionnel/'+this.$store.state.appsociete+'/iudd/'+randomString();
                let response = axios.post(url, this.Items,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })


                let res= await response
                    .then(function (res){

                        t.$store.dispatch('lancerchargement',false)
                        if(res.data.success){


                            toastr['success']( res.data.message,"<b></b>");

                            t.close();

                        }else{


                            let errormessage="";

                            if (res.data.datamessage.length>0){

                                for (let message of res.data.datamessage){

                                    if (errormessage===""){

                                        errormessage=message;

                                    }else{

                                        errormessage+=" , "+message;

                                    }


                                }

                            }
                            else{
                                errormessage=res.data.message
                            }


                            toastr['error'](errormessage,"<b></b>");

                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                            "<b></b>")
                    )
                    .finally(() =>  this.$store.dispatch('lancerchargement',false))






            },



        },
        mounted() {

            // this.loaddata();
        }


    }
</script>

<style scoped>
    .modalstyle {
        max-width: 90% !important;
    }

    @media screen and (max-width: 768px) {

        .modalstyle {
            max-width: 100% !important;
        }


    }


</style>