<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>

            <!-- Start::menu -->
            <appmenu></appmenu>
            <!-- end::menu -->

            <div class="content" style="margin-top: 0">
                <!-- Start::main-content -->
                <div class="main-content">





                </div>
            </div>
        </template>

    </templatesite>



</template>

<script>

    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/presence/menu";

   /* import allfunction from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');*/


    export default {
        /* eslint-disable */
        name: "ewallet",
        components: {
            templatesite,
            appheader,
            appsidebar,
            appmenu,

        },

        data() {
            return {

            };
        },
        methods: {


        },
        mounted() {

           // this.loaddata();
        }






    }
</script>

<style scoped>

    .divmenuheader{
        margin-top: 60px;
    }
    .divmenuheader header{
        padding: 15px;
    }


</style>