<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>

            <!-- Start::menu -->
            <appmenu></appmenu>
            <!-- end::menu -->

            <div class="content" >
                <!-- Start::main-content -->
                <div class="main-content">
                    <!-- Page Header -->
                    <div class="block justify-between page-header md:flex">
                        <div>
                            <h3 class="!text-defaulttextcolor dark:!text-defaulttextcolor/70
                            dark:text-white dark:hover:text-white text-[1.125rem] font-semibold">
                                Traitement de la paie du mois de/d'  {{$store.state.dataspersonnel.moisp}}
                                </h3>
                        </div>

                    </div>
                    <!-- Page Header Close -->

                    <div class="grid sm:grid-cols-3 gap-6">

                        <div class="space-y-2">

                            <template v-if="$store.state.sysmodule.presencepermanent===1">

                                <button v-if="$store.state.sysmodule.typpointage===1"
                                        class="w-full ti-btn ti-btn-danger-gradient ti-btn-wave">GENERER LE NOMBRE DE JOUR D'ABSENCE <br> POUR LE MOIS DE/D' {{$store.state.dataspersonnel.moisp}}
                                    <br> &nbsp;&nbsp;&nbsp;&nbsp;</button>

                                <button v-if="$store.state.sysmodule.typpointage===1"
                                        class="w-full ti-btn ti-btn-danger-gradient ti-btn-wave">GENERER LE NOMBRE DE JOUR D'ABSENCE ET DE RETARD <br>(POINTAGE MANUEL) POUR LE MOIS DE/D' {{$store.state.dataspersonnel.moisp}}</button>
                                <button v-if="$store.state.sysmodule.typpointage===2" style="font-size: 10.75px;"
                                  @click="btnmiseajourdonnedelamarchine($store.state.dataspersonnel.moisp)"      class="w-full ti-btn ti-btn-danger-gradient ti-btn-wave">GENERER LE NOMBRE DE JOUR D'ABSENCE ET
                                    DE RETARD <br>(LA MACHINE POINTEUSE) POUR LE MOIS DE/D' {{$store.state.dataspersonnel.moisp}}</button>
                            </template>

                            <template v-else>

                                <button @click="btnmiseajournombredejourabsencemanuel('personnel')"  class="w-full ti-btn ti-btn-danger-gradient ti-btn-wav">MISE A JOUR DU NOMBRE DE JOUR D'ABSENCE <br> POUR LE MOIS DE/D'{{$store.state.dataspersonnel.moisp}}</button>

                            </template>


                        </div>

                        <div class="space-y-2">
                            <button @click="btnmoadalaskmiseajourdesdonnes($store.state.dataspersonnel.moisp,'personnel')" type="button" class="w-full ti-btn ti-btn-secondary-gradient ti-btn-wave">

                                MISE A JOUR DES DONNEES DU MOIS DE / D' {{$store.state.dataspersonnel.moisp}}

                            </button>
                        </div>

                        <div class="space-y-2">
                            <button type="button" @click="btnmoadalaskcloturedumois($store.state.dataspersonnel.moisp,'personnel')" class="w-full ti-btn ti-btn-danger-full ti-btn-wave">CLÔTURE DU MOIS DE / D' {{$store.state.dataspersonnel.moisp}} </button>
                        </div>


                    </div>




                </div>
            </div>
        </template>

    </templatesite>

    <moadalaskcloturedumois @closed="closemois" ref="moadalaskcloturedumois"></moadalaskcloturedumois>
    <moadalaskmiseajourdesdonnes ref="moadalaskmiseajourdesdonnes"></moadalaskmiseajourdesdonnes>
    <moadalask_miseajourdonnedelamarchine ref="modalaskmiseajourdonnedelamarchine"></moadalask_miseajourdonnedelamarchine>
    <modalmiseajournombredejourabsencemanuel ref="modalmiseajournombredejourabsencemanuel"></modalmiseajournombredejourabsencemanuel>


</template>

<script>

    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/paie/permanent/menu";
    import moadalask_miseajourdonnedelamarchine
        from "@/components/views/pages/paie/moadalask_miseajourdonnedelamarchine";
    import modalmiseajournombredejourabsencemanuel
        from "@/components/views/pages/paie/modal_miseajournombredejourabsencemanuel";
    import moadalaskmiseajourdesdonnes from "@/components/views/pages/paie/moadalask_miseajourdesdonnes";
    import moadalaskcloturedumois from "@/components/views/pages/paie/moadalask_cloturedumois";




   /* import allfunction from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');*/


    export default {
        /* eslint-disable */
        name: "njj",
        components: {
            templatesite,
            appheader,
            appsidebar,
            appmenu,
            moadalask_miseajourdonnedelamarchine,
            modalmiseajournombredejourabsencemanuel,
            moadalaskmiseajourdesdonnes,
            moadalaskcloturedumois,


        },
        props: ['datas'],

        data() {
            return {

            };
        },
        methods: {

            closemois(){

                this.$router.push({
                    name: 'tbpaie',
                    params: {

                    }

                })

            },

            btnmoadalaskcloturedumois(value,typepersonnel){

                this.$refs.moadalaskcloturedumois.show(value,typepersonnel);


            },

            btnmoadalaskmiseajourdesdonnes(value,typepersonnel){

                this.$refs.moadalaskmiseajourdesdonnes.show(value,typepersonnel);


            },

            btnmiseajourdonnedelamarchine(value){

                this.$refs.modalaskmiseajourdonnedelamarchine.show(value);


            },

            btnmiseajournombredejourabsencemanuel(value){

                this.$refs.modalmiseajournombredejourabsencemanuel.show(value);

            },



        },
        mounted() {


            if(this.$store.state.moisencourIsok===0 || this.$store.state.moisencourIsok!==1){

                toastr['error']( "Veuillez ouvrir un mois pour le traitement du salaire.")

                this.$router.push({ name: 'tbpaie' });
            }
            else{
               // this.init();
            }


           // this.loaddata();
        }






    }
</script>

<style scoped>

    .badge {
        padding: 0.6em 0.75em;
        font-size: 100%;
    }

    .divmenuheader{
        margin-top: 60px;
    }
    .divmenuheader header{
        padding: 15px;
    }


</style>