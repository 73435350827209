<template>
    <aside class="app-sidebar" id="sidebar" @mouseenter="mouseEntered" @mouseleave="mouseLeave">

        <!-- Start::main-sidebar-header -->
        <div class="main-sidebar-header">
            <router-link :to="{name:'acceuilpage'}" class="header-logo">
                <img src="/assets/logopaie.png" alt="logo" class="desktop-logo">
                <img src="/assets/logopaie.png" alt="logo" class="toggle-logo">
                <img src="/assets/logopaie.png" alt="logo" class="desktop-dark">
                <img src="/assets/logopaie.png" alt="logo" class="toggle-dark">
                <img src="/assets/logopaie.png" alt="logo" class="desktop-white">
                <img src="/assets/logopaie.png" alt="logo" class="toggle-white">
            </router-link>


        </div>

        <div class="main-sidebar" id="sidebar-scroll" data-simplebar="init">


            <nav class="main-menu-container nav nav-pills flex-column sub-open">
                <div class="slide-left" id="slide-left"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24"
                                                             height="24" viewBox="0 0 24 24">
                    <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path>
                </svg></div>
                <ul class="main-menu">
                    <!-- Start::slide__category -->
                    <li class="slide__category"><span class="category-name">Menu</span></li>
                    <!-- End::slide__category -->



                    <li class="slide">
                        <router-link @click="clickmenu" :to="{name: 'acceuilpage'}" class="side-menu__item">
                            <i class="bx bx-home side-menu__icon"></i>
                            <span class="side-menu__label">TABLEAU DE BORD</span>
                        </router-link>
                    </li>


                    <li class="slide">
                        <router-link @click="clickmenu" :to="{name: 'tbdefinition'}"  class="side-menu__item">
                            <i class="bx bx-send side-menu__icon"></i>
                            <span class="side-menu__label">DEFINITION</span>
                        </router-link>
                    </li>


                    <li class="slide">
                        <router-link @click="clickmenu" :to="{name:'tbpaie'}" class="side-menu__item">
                            <i class="bx bx-send side-menu__icon"></i>
                            <span class="side-menu__label">GESTION DE <br> LA PAIE</span>
                        </router-link>
                    </li>


                    <li class="slide">
                        <router-link @click="clickmenu" :to="{name:'tbpresence'}"   class="side-menu__item">
                            <i class="bx bx-send side-menu__icon"></i>
                            <span class="side-menu__label">GESTION DE <br> LA PRESENCE</span>
                        </router-link>
                    </li>


                    <li class="slide">
                        <!--router-link @click="clickmenu" :to="{name:'trapport'}" class="side-menu__item"-->
                        <router-link @click="clickmenu" to="#" class="side-menu__item">
                            <i class="bx bx-send side-menu__icon"></i>
                            <span class="side-menu__label">RAPPORT</span>
                        </router-link>
                    </li>


                    <li class="slide">
                        <router-link @click="clickmenu" to="#" class="side-menu__item">
                            <i class="bx bx-cog header-link-icon animate-spin-slow side-menu__icon"></i>
                            <span class="side-menu__label">SUPPORT</span>
                        </router-link>
                    </li>

                    <li class="slide">
                        <router-link @click="clickmenu" to="#" class="side-menu__item">
                            <i class="bx bx-cog header-link-icon animate-spin-slow side-menu__icon"></i>
                            <span class="side-menu__label">PARAMETRE</span>
                        </router-link>
                    </li>



                    <!--li :class="menuli1 === '' ? 'slide has-sub' : 'slide has-sub active open'"  @click="toggleClassli('li1')">
                        <a href="javascript:void(0);" class="side-menu__item">
                            <i class="bx bx-home side-menu__icon"></i>
                            <span class="side-menu__label">Service<span
                                    class="badge !bg-warning/10 !text-warning !py-[0.25rem] !px-[0.45rem] !text-[0.75em] ms-2">12</span></span>
                            <i class="fe fe-chevron-right side-menu__angle"></i>
                        </a>
                        <ul   :class="menuul1 === '' ? 'slide-menu child1' : 'slide-menu child1 sousmenuactive'" >
                            <li class="slide side-menu__label1">
                                <router-link to="#" class="side-menu__item">1Xbet</router-link>
                            </li>
                            <li class="slide">
                                <router-link  to="#" class="side-menu__item">Canal plus</router-link>
                            </li>
                            <li class="slide">
                                <router-link  to="#" class="side-menu__item">SBEE</router-link>
                            </li>

                        </ul>
                    </li-->



                </ul>
                <div class="slide-right" id="slide-right"><svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24"
                                                               height="24" viewBox="0 0 24 24">
                    <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
                </svg>
                </div>
            </nav>
            <!-- End::nav -->



        </div>
        <!-- End::main-sidebar -->

    </aside>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {

        /* eslint-disable */
        name: "appsidebar",
        computed:{
            ...mapGetters(['lancerchargement','classmenupc','classdefautmenu','niveauclickmenu','myscreenSize','isAuthenticated'])



        },
        data:()=>({

            menuli1:"",
            menuli2:"",
            menuli3:"",
            menuli4:"",
            menuli5:"",
            menuli6:"",
            menuli7:"",
            menuli8:"",
            menuli9:"",
            menuli10:"",

            menua1:"",
            menua2:"",
            menua3:"",
            menua4:"",
            menua5:"",
            menua6:"",
            menua7:"",
            menua8:"",
            menua9:"",
            menua10:"",


            menuul1:"",
            menuul2:"",
            menuul3:"",
            menuul4:"",
            menuul5:"",
            menuul6:"",
            menuul7:"",
            menuul8:"",
            menuul9:"",
            menuul10:"",



        }),
        components: {

        },
        methods: {

             mouseEntered() {
        let html = document.querySelector("html");
        html.setAttribute("icon-overlay", "open");
    },
             mouseLeave() {
        let html = document.querySelector("html");
        html.removeAttribute("icon-overlay");
    },

            toggleClassli(value) {

               /* this.menua1="";
                this.menua2="";
                this.menua3="";
                this.menua4="";
                this.menua5="";
                this.menua6="";
                this.menua7="";
                this.menua8="";
                this.menua9="";
                this.menua10="";*/

                 if (value==="li1"){




                     if (this.menuli1===""){
                         this.menuli1="click";
                         this.menuul1="view";

                         this.menuli2="";
                         this.menuli3="";
                         this.menuli4="";
                         this.menuli5="";
                         this.menuli6="";
                         this.menuli7="";
                         this.menuli8="";
                         this.menuli9="";
                         this.menuli10="";

                         this.menuul2="";
                         this.menuul3="";
                         this.menuul4="";
                         this.menuul5="";
                         this.menuul6="";
                         this.menuul7="";
                         this.menuul8="";
                         this.menuul9="";
                         this.menuul10="";

                     }else{
                         this.menuli1="";
                         this.menuul1="";

                         this.menuli2="";
                         this.menuli3="";
                         this.menuli4="";
                         this.menuli5="";
                         this.menuli6="";
                         this.menuli7="";
                         this.menuli8="";
                         this.menuli9="";
                         this.menuli10="";

                         this.menuul2="";
                         this.menuul3="";
                         this.menuul4="";
                         this.menuul5="";
                         this.menuul6="";
                         this.menuul7="";
                         this.menuul8="";
                         this.menuul9="";
                         this.menuul10="";

                     }



                 }



/*
                if (liElementClass==="slide has-sub") {
                   // liElement.classList.remove('slide has-sub');
                    //liElement.classList.add('slide has-sub active open');

                    liElement.classList.replace('slide has-sub', 'slide has-sub active open');


                }
                else {


                 //  liElement.classList.remove('slide has-sub active open');
                   // liElement.classList.add('slide has-sub');

                    liElement.classList.replace('slide has-sub active open','slide has-sub');



                }
*/

            //    const slideHasSub = document.querySelectorAll(".nav > ul > .slide.has-sub");


                console.log("this.menuli1   ",this.menuli1)

            },

            clickmenu(){

                if (this.myscreenSize <= 990) { // ecran mobile
                let html = document.querySelector("html");
                let responsiveoverlay = document.querySelector("#responsive-overlay");

                html.removeAttribute('data-toggled');
                responsiveoverlay.classList.remove("active");

                html.setAttribute("data-toggled", "close");
                }
            }





        },
        mounted() {


        }

    }
</script>

<style scoped>

    .sousmenuactive{
        position: relative;
        left: 0px;
        top: 0px;
        margin: 0px;
        box-sizing: border-box;
        transform: translate3d(119.2px, 8px, 0px);
        display: block;


    }




</style>