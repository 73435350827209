<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="box-body">
                        <div class="border-b-0 border-gray-200 dark:border-white/10">
                            <nav class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" aria-label="Tabs">
                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 active"
                                        id="hs-tab-js-behavior-item-1" data-hs-tab="#hs-tab-js-behavior-1" aria-controls="hs-tab-js-behavior-1">
                                    -
                                </button>



                            </nav>
                        </div>

                        <div class="">
                            <div id="hs-tab-js-behavior-1" role="tabpanel" aria-labelledby="hs-tab-js-behavior-item-1" class="">
                                <div class="ti-custom-validation mt-5" >

                                    <div class="grid grid-cols-12 gap-x-6">

                                        <div class="mb-4  xl:col-span-4 col-span-12">
                                            <label  class="sr-only">Date début</label>
                                            <input v-model="mdatedebut" type="date" class="form-control">


                                        </div>

                                        <div class="mb-4  xl:col-span-4 col-span-12">
                                            <label  class="sr-only">Date fin</label>
                                            <input v-model="mdatefin" type="date" class="form-control">


                                        </div>


                                        <div class="mb-4  xl:col-span-4 col-span-12">

                                            <button @click="btnvoirrapportdepointage" class="ti-btn ti-btn-success-full w-full"><i class="ri-refresh-line"></i> Actualiser</button>


                                        </div>



                                    </div>




                                    <!-- Start::row-1 -->
                                    <div class="w-full grid grid-cols-12">
                                        <div class="xl:col-span-12 col-span-12">
                                            <div class="box  overflow-hidden">
                                                <div class="box-header justify-between">
                                                    <!--div class="box-title">

                                                    </div-->
                                                    <div class="flex flex-wrap gap-2"></div>
                                                    <div class="w-full  grid grid-cols-2">


                                                        <div class="mb-2 sm:mb-0">
                                                            <div class="download-data">
                                                                <button type="button" @click="ExportExcel('xlsx','POINT DE PRESENCE')" class="ti-btn ti-btn-primary" >
                                                                    <i class="ri-file-excel-fill"></i> Exporter en excel</button>
                                                                <button @click="print4" type="button" class="ti-btn ti-btn-facebook" ><i class="ri-printer-fill"></i> Imprimer</button>
                                                            </div>
                                                        </div>


                                                        <div>
                                                            <input v-model="searchword" class="form-control form-control-lg" type="text"
                                                                   placeholder="Rechercher"
                                                                   aria-label=".form-control-lg example">
                                                        </div>

                                                    </div>
                                                </div>


                                                <div class="box-body !p-0">



                                                    <div class="table-responsive mybodyheight200px">


                                                        <div  class="my-5 page pagebreak"  >


                                                            <div class="p-5">

                                                                <section class="top-content mb-5 bb d-flex justify-content-between">
                                                                    <div class="logo">
                                                                        <img v-if="$store.state.etatlogo" :src="$store.state.etatlogo" alt="" class="img-fluid">
                                                                    </div>
                                                                    <div class="top-left">
                                                                        <div class="logo">
                                                                            <img :src="'https://chart.googleapis.com/chart?cht=qr&chl=hpaie&chs=100x100&chld=L|0'" alt="" class="img-fluid">
                                                                        </div>
                                                                    </div>
                                                                </section>

                                                                <section class="mt-5">
                                                                    <div class="grid lg:grid-cols-2 gap-6">

                                                                        <div class="col-6">
                                                                            <table class="table table-bordered">
                                                                                <tbody >

                                                                                <tr>
                                                                                    <td >Raison Social : {{$store.state.etatraisonsocial}}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td >Ifu : {{$store.state.etatifu}} /  RC N° RCCM: {{$store.state.etatrccm}} </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td >Téléphone : {{$store.state.etattelephone}} / {{$store.state.etatadresse}}</td>
                                                                                </tr>

                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div class="col-6 ">



                                                                            <table class="table table-bordered w-full">
                                                                                <tbody >
                                                                                <tr>
                                                                                    <td style="text-align: center" colspan="3" >POINTAGE ET CUMMULS DE TEMPS</td>

                                                                                </tr>
                                                                                <tr>
                                                                                    <td style="text-align: center">PERIODE</td>
                                                                                    <td style="text-align: center">DATE D'EDITION</td>
                                                                                    <td style="text-align: center">TIRE PAR</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style="text-align: center"><strong>{{mdatedebut}}</strong><strong>  Au : {{mdatefin}}</strong></td>
                                                                                    <td style="text-align: center">{{datenow}}</td>
                                                                                    <td style="text-align: center">{{$store.state.appusername}}</td>
                                                                                </tr>




                                                                                </tbody>
                                                                            </table>


                                                                        </div>

                                                                    </div>






                                                                </section>

                                                                <section   class="product-area mt-3">


                                                                    <div class="table-responsive mt-3">

                                                                        <table ref="exportable_table" class="table table-striped
                                                         table-bordered table-hover dataTable no-footer dtr-inline mytable w-full">

                                                                            <tbody>

                                                                            <template v-if="filterdatatable.length">

                                                                                <template    v-for="(datavalue,index) in filterdatatable" :key="index">


                                                                                    <tr>
                                                                                        <td colspan="2" style="text-align: center;" class="text-center"><strong>Nom et prénom : {{datavalue.name}}</strong>
                                                                                            <br>

                                                                                            <strong>Service : {{datavalue.service}}</strong>
                                                                                        </td>

                                                                                    </tr>



                                                                                    <tr>
                                                                                        <td colspan="2">

                                                                                            <table class="w-full table-bordered-primary" style="width: 100%;">
                                                                                                <tbody>
                                                                                                <tr>
                                                                                                    <td class="text-center">Date</td>
                                                                                                    <td class="text-center">Entrée</td>
                                                                                                    <td class="text-center">Sortie</td>
                                                                                                    <td class="text-center">Entrée</td>
                                                                                                    <td class="text-center">Sortie</td>
                                                                                                    <td class="text-center">Entrée</td>
                                                                                                    <td class="text-center">Sortie</td>
                                                                                                    <td class="text-center">Entrée</td>
                                                                                                    <td class="text-center">Sortie</td>
                                                                                                    <td class="text-center">Entrée</td>
                                                                                                    <td class="text-center">Sortie</td>
                                                                                                    <td class="text-center">Cummul d'heure de travail</td>
                                                                                                    <td class="text-center">Présence</td>
                                                                                                    <td class="text-center">Retard</td>
                                                                                                    <td class="text-center">Etat</td>
                                                                                                </tr>

                                                                                                <template v-for="(element,mindex) in datavalue.listeforelement" :key="mindex">

                                                                                                    <template v-if="element.mdateline.observation===''">

                                                                                                        <tr>

                                                                                                            <!--td>{{element.mdateline.dateday}}</td-->
                                                                                                            <td>{{element.mdate}}</td>
                                                                                                            <td style="text-align: center;" class="text-center">{{element.mdateline.entre1}}</td>
                                                                                                            <td style="text-align: center;" class="text-center">{{element.mdateline.sortie1}}</td>
                                                                                                            <td style="text-align: center;" class="text-center">{{element.mdateline.entre2}}</td>
                                                                                                            <td style="text-align: center;" class="text-center">{{element.mdateline.sortie2}}</td>
                                                                                                            <td style="text-align: center;" class="text-center">{{element.mdateline.entre3}}</td>
                                                                                                            <td style="text-align: center;" class="text-center">{{element.mdateline.sortie3}}</td>
                                                                                                            <td style="text-align: center;" class="text-center">{{element.mdateline.entre4}}</td>
                                                                                                            <td style="text-align: center;" class="text-center">{{element.mdateline.sortie4}}</td>
                                                                                                            <td style="text-align: center;" class="text-center">{{element.mdateline.entre5}}</td>
                                                                                                            <td style="text-align: center;" class="text-center">{{element.mdateline.sortie5}}</td>
                                                                                                            <td style="text-align: center;" class="text-center">{{element.mdateline.cummulheure}} heures</td>
                                                                                                            <td style="text-align: center;" class="text-center">
                                                                                                                <span v-if="element.mdateline.presence===0" style="color: red">?</span>
                                                                                                                <span v-if="element.mdateline.presence===1" style="color: green"><i class="ri-check-line"></i></span>

                                                                                                            </td>
                                                                                                            <td style="text-align: center;"  class="text-center">{{element.mdateline.retard}} minutes</td>
                                                                                                            <td style="text-align: center;"  class="text-center">
                                                                                                                <span v-if="element.mdateline.etat===1" style="color: red">?</span>
                                                                                                                <span v-if="element.mdateline.etat===0" style="color: green"><i class="fa fa-check"></i></span>

                                                                                                            </td>
                                                                                                        </tr>

                                                                                                    </template>
                                                                                                    <template v-if="element.mdateline.observation!==''">
                                                                                                        <tr>
                                                                                                            <!--td>{{element.mdateline.dateday}}</td-->
                                                                                                            <td>{{element.mdate}}</td>
                                                                                                            <td colspan="13" style="text-align: center;" class="text-center"><strong>{{element.mdateline.observation}}</strong></td>

                                                                                                            <td style="text-align: center;"  class="text-center">
                                                                                                                <span v-if="element.mdateline.etat===0" style="color: red">?</span>
                                                                                                                <span v-if="element.mdateline.etat===1" style="color: green"><i class="fa fa-check"></i></span>


                                                                                                            </td>

                                                                                                        </tr>
                                                                                                    </template>



                                                                                                </template>


                                                                                                </tbody>
                                                                                            </table>


                                                                                        </td>

                                                                                    </tr>


                                                                                    <tr><td colspan="14">
                                                                                        <div style="width: 100%;height: 30px"></div>
                                                                                    </td></tr>



                                                                                </template>

                                                                            </template>

                                                                            </tbody>

                                                                        </table>

                                                                    </div>

                                                                </section>

                                                                <section class="balance-info">
                                                                    <div class="row">

                                                                    </div>
                                                                </section>
                                                                <section class="balance-info mt-3">
                                                                    <div class="row">

                                                                    </div>
                                                                </section>


                                                                <!--footer>
                                                                    <hr>
                                                                    <p class="m-0 text-center">
                                                                        RC N° RCCM:RB/COT/13-A18181 IFU N°:1201301296109 Tél: (229) XX XX XX XX / XX XX XX XX Cotonou Rép du BENIN
                                                                    </p>

                                                                </footer-->










                                                            </div>




                                                        </div>




                                                    </div>



                                                </div>





                                                <div class="box-footer border-t-0">
                                                    <div class="flex items-center flex-wrap overflow-auto">
                                                        <div class="mb-2 sm:mb-0">

                                                        </div>
                                                        <div class="ms-auto">


                                                            <!--nav aria-label="Page navigation">
                                                                <ul class="ti-pagination  mb-0">
                                                                    <li class="page-item "><button  :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]"  @click="page--">Previous</button></li>
                                                                    <li class="page-item">

                                                                        <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                                                :key="key" v-for="(pageNumber,key) in pages.slice(page-1, page+5)"
                                                                                @click="page = pageNumber">{{pageNumber}}</button>

                                                                    </li>
                                                                    <li class="page-item" ><button :class="{ 'disabled': page => pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++" >Next</button></li>
                                                                </ul>
                                                            </nav-->



                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <!--End::row-1 -->



                                </div>
                            </div>



                        </div>
                    </div>




                </div>
                <div class="ti-modal-footer">
                    <button style="background: red" @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" >
                        Fermer
                    </button>
                    <button style="background: green" v-if="typeaction==='add'" class="ti-btn ti-btn-primary-full" href="javascript:void(0);">
                       Enregistrer
                    </button>
                    <button style="background: green" v-if="typeaction==='update'" class="ti-btn ti-btn-primary-full" href="javascript:void(0);">
                       Modifier
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>






    <div class="row" v-show="false">

        <div id="invoice">
            <page id="printOrder-data"   class="my-5 page printOrder-data " :size="taillemodel">


                <div   class="my-5 page printOrder-data pagebreak"  >


                    <div class="p-5">

                        <section class="top-content mb-5 bb d-flex justify-content-between">
                            <div class="logo">
                                <img v-if="$store.state.etatlogo" :src="$store.state.etatlogo" alt="" class="img-fluid">
                            </div>
                            <div class="top-left">
                                <div class="logo">
                                    <img :src="'https://chart.googleapis.com/chart?cht=qr&chl=hpaie&chs=100x100&chld=L|0'" alt="" class="img-fluid">
                                </div>
                            </div>
                        </section>

                        <section class="mt-5">
                            <div class="row">

                                <div class="col-6">
                                    <table class="table table-bordered">
                                        <tbody >

                                        <tr>
                                            <td >Raison Social : {{$store.state.etatraisonsocial}}</td>
                                        </tr>
                                        <tr>
                                            <td >Ifu : {{$store.state.etatifu}} /  RC N° RCCM: {{$store.state.etatrccm}} </td>
                                        </tr>
                                        <tr>
                                            <td >Téléphone : {{$store.state.etattelephone}} / {{$store.state.etatadresse}}</td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <div class="col-6 ">


                                    <table class="table table-bordered">
                                        <tbody >

                                        <tr>
                                            <td style="text-align: center" colspan="3" >POINTAGE ET CUMMULS DE TEMPS</td>

                                        </tr>
                                        <tr>
                                            <td style="text-align: center">PERIODE</td>
                                            <td style="text-align: center">DATE D'EDITION</td>
                                            <td style="text-align: center">TIRE PAR</td>
                                        </tr>
                                        <tr>
                                            <td style="text-align: center"><strong>{{mdatedebut}}</strong><strong>  Au : {{mdatefin}}</strong></td>
                                            <td style="text-align: center">{{datenow}}</td>
                                            <td style="text-align: center">{{$store.state.appusername}}</td>
                                        </tr>




                                        </tbody>
                                    </table>


                                </div>

                            </div>






                        </section>

                        <section   class="product-area mt-3">

                            <table ref="exportable_table" class="table table-striped
                                                         table-bordered table-hover dataTable no-footer dtr-inline mytable w-full">

                                <tbody>

                                <template v-if="filterdatatable.length">

                                    <template    v-for="(datavalue,index) in filterdatatable" :key="index">


                                        <tr>
                                            <td colspan="2" style="text-align: center;" class="text-center"><strong>Nom et prénom : {{datavalue.name}}</strong>
                                                <br>

                                                <strong>Service : {{datavalue.service}}</strong>
                                            </td>

                                        </tr>



                                        <tr>
                                            <td colspan="2">

                                                <table class="w-full table-bordered-primary" style="width: 100%;">
                                                    <tbody>
                                                    <tr>
                                                        <td class="text-center">Date</td>
                                                        <td class="text-center">Entrée</td>
                                                        <td class="text-center">Sortie</td>
                                                        <td class="text-center">Entrée</td>
                                                        <td class="text-center">Sortie</td>
                                                        <td class="text-center">Entrée</td>
                                                        <td class="text-center">Sortie</td>
                                                        <td class="text-center">Entrée</td>
                                                        <td class="text-center">Sortie</td>
                                                        <td class="text-center">Entrée</td>
                                                        <td class="text-center">Sortie</td>
                                                        <td class="text-center">Cummul d'heure de travail</td>
                                                        <td class="text-center">Présence</td>
                                                        <td class="text-center">Retard</td>
                                                        <td class="text-center">Etat</td>
                                                    </tr>

                                                    <template v-for="(element,mindex) in datavalue.listeforelement" :key="mindex">

                                                        <template v-if="element.mdateline.observation===''">

                                                            <tr>

                                                                <!--td>{{element.mdateline.dateday}}</td-->
                                                                <td>{{element.mdate}}</td>
                                                                <td style="text-align: center;" class="text-center">{{element.mdateline.entre1}}</td>
                                                                <td style="text-align: center;" class="text-center">{{element.mdateline.sortie1}}</td>
                                                                <td style="text-align: center;" class="text-center">{{element.mdateline.entre2}}</td>
                                                                <td style="text-align: center;" class="text-center">{{element.mdateline.sortie2}}</td>
                                                                <td style="text-align: center;" class="text-center">{{element.mdateline.entre3}}</td>
                                                                <td style="text-align: center;" class="text-center">{{element.mdateline.sortie3}}</td>
                                                                <td style="text-align: center;" class="text-center">{{element.mdateline.entre4}}</td>
                                                                <td style="text-align: center;" class="text-center">{{element.mdateline.sortie4}}</td>
                                                                <td style="text-align: center;" class="text-center">{{element.mdateline.entre5}}</td>
                                                                <td style="text-align: center;" class="text-center">{{element.mdateline.sortie5}}</td>
                                                                <td style="text-align: center;" class="text-center">{{element.mdateline.cummulheure}} heures</td>
                                                                <td style="text-align: center;" class="text-center">
                                                                    <span v-if="element.mdateline.presence===0" style="color: red">?</span>
                                                                    <span v-if="element.mdateline.presence===1" style="color: green"><i class="ri-check-line"></i></span>

                                                                </td>
                                                                <td style="text-align: center;"  class="text-center">{{element.mdateline.retard}} minutes</td>
                                                                <td style="text-align: center;"  class="text-center">
                                                                    <span v-if="element.mdateline.etat===1" style="color: red">?</span>
                                                                    <span v-if="element.mdateline.etat===0" style="color: green"><i class="fa fa-check"></i></span>

                                                                </td>
                                                            </tr>

                                                        </template>
                                                        <template v-if="element.mdateline.observation!==''">
                                                            <tr>
                                                                <!--td>{{element.mdateline.dateday}}</td-->
                                                                <td>{{element.mdate}}</td>
                                                                <td colspan="13" style="text-align: center;" class="text-center"><strong>{{element.mdateline.observation}}</strong></td>

                                                                <td style="text-align: center;"  class="text-center">
                                                                    <span v-if="element.mdateline.etat===0" style="color: red">?</span>
                                                                    <span v-if="element.mdateline.etat===1" style="color: green"><i class="fa fa-check"></i></span>


                                                                </td>

                                                            </tr>
                                                        </template>



                                                    </template>


                                                    </tbody>
                                                </table>


                                            </td>

                                        </tr>


                                        <tr><td colspan="14">
                                            <div style="width: 100%;height: 30px"></div>
                                        </td></tr>



                                    </template>

                                </template>

                                </tbody>

                            </table>

                        </section>

                        <section class="balance-info">
                            <div class="row">

                            </div>
                        </section>
                        <section class="balance-info mt-3">
                            <div class="row">

                            </div>
                        </section>


                        <!--footer>
                            <hr>
                            <p class="m-0 text-center">
                                RC N° RCCM:RB/COT/13-A18181 IFU N°:1201301296109 Tél: (229) XX XX XX XX / XX XX XX XX Cotonou Rép du BENIN
                            </p>

                        </footer-->










                    </div>



                </div>


            </page>

        </div>

    </div>






</template>

<script>

    import {currentDate, currentDateTime, ExportExcel, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "modalform",
        watch: {


        },

        computed:{


            filterdatatable(){

                let mvalue=this.datatable;
                if (this.searchword!==''){
                     mvalue = mvalue.filter( post => post.name.toLowerCase().includes(this.searchword.toLowerCase()))
                        }

                return mvalue ;

               // return []
            },




        },
        components: {


        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",
                searchword:"",
                typedoc:"",
                typeaction:"add",
                taillemodel:"A4landscape",
                datatable:[],
                mdatedebut:currentDate(),
                mdatefin:currentDate(),
                datenow:currentDateTime(),







            };
        },
        methods: {




            ExportExcel(type,name, fn, dl) {
                name=name+"_"+currentDateTime()
                var elt = this.$refs.exportable_table;
                ExportExcel(type,name, fn, dl,elt);

            },


            async print4 () {

                let t=this

                let options = {  name: '_blank', }


                if (this.taillemodel==="A4landscape" ){

                    options = {
                        name: '_blank',
                        specs: [
                            'fullscreen=yes',
                            'titlebar=yes',
                            'scrollbars=yes'
                        ],
                        styles: [
                            process.env.VUE_APP_URL+'print/bootstrap.min.css',
                            process.env.VUE_APP_URL+'print/stylelandscape1.css',
                            process.env.VUE_APP_URL+'print/stylelandscape.css'
                        ],


                        replace: true,
                        // timeout: 1000, // default timeout before the print window appears
                        autoClose: false, // if false, the window will not close after printing
                        windowTitle: window.document.title, // override the window title




                    }
                }

                if (this.taillemodel==="A4" ){

                    options = {
                        name: '_blank',
                        specs: [
                            'fullscreen=yes',
                            'titlebar=yes',
                            'scrollbars=yes'
                        ],
                        styles: [
                            process.env.VUE_APP_URL+'print/bootstrap.min.css',
                            process.env.VUE_APP_URL+'print/styleportrait.css',
                            process.env.VUE_APP_URL+'print/styleportraisizebulletin.css'
                        ],


                        replace: true,
                        // timeout: 1000, // default timeout before the print window appears
                        autoClose: false, // if false, the window will not close after printing
                        windowTitle: window.document.title, // override the window title




                    }
                }


                this.$htmlToPaper('printOrder-data', options, () => {
                    console.log('Printing completed or was cancelled!');
                });

            },



            close(){

                this.isdisplay=false;

                this.$emit('closed');

            },

            show(titlemodal,typeaction){

                this.titlemodal=titlemodal
                this.typeaction=typeaction

                this.isdisplay=true;



            },


            async  btnvoirrapportdepointage(){


                this.$store.dispatch('lancerchargement',true)


                const t = this;

                let url=process.env.VUE_APP_API_URL+'/egl74dkjskpresence771714dfe/presence/pointage/'+this.$store.state.appsociete+'/rapportdepointage/'+this.mdatedebut+'/'+this.mdatefin+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)

                        if(res.data.success){


                            t.datatable=res.data.m

                            /*t.dataimportcsvok=[]
                            t.dataimportcsv=[]

                            toastr['success']( res.data.message,"<b></b>");*/

                        }
                        else{


                            t.datatable==[]

                            /*t.dataimportcsvok=[]
                            t.dataimportcsv=[]

                            toastr['error']( res.data.message,"<b></b>");*/
                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })





            }








        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>
    .modalstyle{
        max-width: 95%!important;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


    }





</style>