<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title headertitle" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="box-body">
                        <div class="border-b-0 border-gray-200 dark:border-white/10">
                            <nav class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" aria-label="Tabs">
                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 active"
                                        id="hs-tab-js-behavior-item-1" data-hs-tab="#hs-tab-js-behavior-1" aria-controls="hs-tab-js-behavior-1">
                                    -
                                </button>



                            </nav>
                        </div>

                        <div class="">
                            <div id="hs-tab-js-behavior-1" role="tabpanel" aria-labelledby="hs-tab-js-behavior-item-1" class="">
                                <div class="ti-custom-validation mt-5" >

                                    <!-- Start::row-1 -->
                                    <div class="w-full grid grid-cols-12">
                                        <div class="xl:col-span-12 col-span-12">
                                            <div class="box  overflow-hidden">
                                                <div class="box-header justify-between">

                                                    <div class="w-full grid grid-cols-12 gap-x-6">



                                                        <div class="mb-4  xl:col-span-12 col-span-12">

                                                            <label for="hs-select-label" class="ti-form-select
                                                            rounded-sm !py-2 !px-3-label">Type de personnel</label>

                                                            <select @change="changevalue" v-model="typepersonnel" id="hs-select-label" class="ti-form-select rounded-sm !py-2 !px-3">
                                                                <option value="personnel">Permanent</option>
                                                                <option value="occasionnel">Occasionnel</option>
                                                            </select>

                                                        </div>

                                                        <div class="mb-4  xl:col-span-3 col-span-12">

                                                            <label for="hs-select-label" class="ti-form-select
                                                            rounded-sm !py-2 !px-3-label">Heure d'arrivée</label>

                                                            <input id="datearrivee" class="form-control form-control-lg" type="date"
                                                                   @change="changevalue" v-model="chargedate"    aria-label=".form-control-lg example">
                                                        </div>

                                                        <div class="mb-4  xl:col-span-3 col-span-12">
                                                            <label for="hs-select-label" class="ti-form-select
                                                            rounded-sm !py-2 !px-3-label">&nbsp;&nbsp;</label>
                                                             <input id="heurearrivee" class="form-control form-control-lg" type="time"
                                                                    @change="changevalue" v-model="heurearrive"   aria-label=".form-control-lg example">
                                                        </div>


                                                        <div class="mb-4  xl:col-span-3 col-span-12">

                                                            <label for="hs-select-label" class="ti-form-select
                                                            rounded-sm !py-2 !px-3-label">Heure de sortie</label>

                                                            <input @change="changevalue" v-model="datededepart" id="datesortie" class="form-control form-control-lg" type="date"
                                                                   aria-label=".form-control-lg example">
                                                        </div>

                                                        <div class="mb-4  xl:col-span-3 col-span-12">
                                                            <label for="hs-select-label" class="ti-form-select
                                                            rounded-sm !py-2 !px-3-label">&nbsp;&nbsp;</label>
                                                             <input id="heuresortie" class="form-control form-control-lg" type="time"
                                                                    @change="changevalue" v-model="heurededepart"  aria-label=".form-control-lg example">
                                                        </div>


                                                        <div class="mb-4  xl:col-span-6 col-span-12">
                                                            <label for="hs-select-label" class="ti-form-select
                                                            rounded-sm !py-2 !px-3-label">Durée de pause( en minute )</label>
                                                             <input id="dureedepause" class="text-center form-control  form-control-lg" min="0" type="number"
                                                                    @change="changevalue" v-model="dureedelapause"  aria-label=".form-control-lg example">
                                                        </div>

                                                        <div class="mb-4  xl:col-span-6 col-span-12">
                                                            <label for="hs-select-label" class="ti-form-select
                                                            rounded-sm !py-2 !px-3-label">Nombre total d'heure de travail</label>
                                                             <input id="nbredheuretotaldetravail" class="text-center form-control form-control-lg" min="0" type="number"
                                                                   disabled v-model="mnbredheuretotal"  aria-label=".form-control-lg example">
                                                        </div>






                                                        <div  class="mb-4  xl:col-span-12 col-span-12">
                                                            <button @click="chargerdate(chargedate,typepersonnel)"
                                                                    class="ti-btn ti-btn-primary-full center-block"><i class="ri-refresh-line"></i> Charger</button>

                                                        </div>


                                                        <div class="mb-1 mt-7  xl:col-span-6 col-span-12">
                                                            <input v-model="searchword" class="form-control form-control-lg" type="text"
                                                                   placeholder="Rechercher par le nom et prénom(s)"
                                                                   aria-label=".form-control-lg example">
                                                        </div>

                                                        <div class="mb-1 mt-7  xl:col-span-6 col-span-12">
                                                            <input v-model="searchwordservice" class="form-control form-control-lg" type="text"
                                                                   placeholder="Rechercher par le service"
                                                                   aria-label=".form-control-lg example">
                                                        </div>

                                                    </div>
                                                </div>


                                                <div class="box-body !p-0">



                                                    <!--div class="download-data">
                                                        <button type="button" class="ti-btn ti-btn-primary" ><i class="ri-check-line"></i> CHECK LE TOUT</button>
                                                        <button type="button" class="ti-btn ti-btn-primary" ><i class="ri-delete-row"></i> ANNULER LE TOUT</button>
                                                         </div-->

                                                    <div class="table-responsive mt-3">
                                                        <div class="flex">
                                                          <div class="hs-dropdown ti-dropdown ms-2">
                                                                <button type="button" aria-label="button" class="ti-btn ti-btn-secondary ti-btn-sm" aria-expanded="false">
                                                                    <i class="ti ti-dots-vertical"></i>
                                                                </button>
                                                                <ul class="hs-dropdown-menu ti-dropdown-menu hidden" style="">
                                                                    <li><a @click="saveItemsselected" class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem]
                                                                    !font-medium block" href="javascript:void(0);">Enregistrez le planning des lignes choisies.</a></li>
                                                                    <li><a @click="removeItemsselected" class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem]
                                                                    !font-medium block" href="javascript:void(0);">Annulez le planning des lignes choisies</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div class="flex flex-col h-screen">
                                                            <div class="flex-grow overflow-auto">

                                                            <table class="table mytable table-hover whitespace-nowrap min-w-full">
                                                            <thead>

                                                             <tr>
                                                                 <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">
                                                                     <input class="form-check-input check-all" type="checkbox"
                                                                            @change="onChangecheckallrowfortable"
                                                                            id="all-tasks" v-model="checkallrowfortable" aria-label="...">
                                                                 </th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">NOM ET PRÉNOM(S)</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">SERVICE</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start"  >ETAT</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">ACTION</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>



                                                            <template v-if="filterdatatable.length>0">

                                                                <template  v-for="(datavalue,index) in filterdatatable" :key="index">

                                                                    <tr :style="getstyle(datavalue.__valuebgd)"  class="border-t hover:bg-gray-200 dark:hover:bg-light border
                                                                    border-defaultborder task-list">


                                                                        <td class="task-checkbox">
                                                                            <input class="form-check-input" type="checkbox"
                                                                                   @change="onChangecheckallrowline(datavalue,index)"
                                                                                   v-model="datavalue.__checkline" aria-label="...">
                                                                        </td>
                                                                        <td  data-label="NOM ET PRÉNOM(S)  : "   >{{ datavalue.__name }}</td>
                                                                        <td  data-label="SERVICE : "   >{{ datavalue.__service }}</td>
                                                                        <td data-label="ETAT  : "  >
                                                                            <span style="width: 100%;font-size: 90%;display: inline!important;font-weight: bold;" v-if="datavalue.__etatplanning===1" class="badge bg-success/10 text-success">Planning défini</span>

                                                                            <span style="width: 100%;font-size: 90%;display: inline!important;font-weight: bold;" v-else  class="badge bg-danger/10 text-danger">planning non défini</span>
                                                                        </td>

                                                                        <td data-label="ACTION  : "  >

                                                                            <button v-if="datavalue.__ssid!==''" @click="annulerplanning(datavalue,index)" class="ti-btn ti-btn-danger-full">
                                                                                <i class="ri-delete-row"></i> Annuler</button>

                                                                            <button v-else @click="savevalue(datavalue,index)" class="ti-btn ti-btn-primary-full"><i class="ri-check-line"></i> check</button>




                                                                        </td>

                                                                    </tr>


                                                                    <tr :style="getstyle(datavalue.__valuebgd)" v-if="datavalue.__valuebgd!==0 && datavalue.__msg!==''">
                                                                        <td colspan="20"> <div style="font-size: 12px; font-weight: bold;" v-html="datavalue.__msg"></div> </td>
                                                                    </tr>
                                                                    <tr v-if="datavalue.__etatplanning===1">
                                                                        <td colspan="10">

                                                                            <table class="table table-bordered w-full">
                                                                                <thead>
                                                                                <tr>
                                                                                    <th>Heure d'arrivée</th>
                                                                                    <th>Heure de départ</th>
                                                                                    <th>Durée de pause</th>
                                                                                    <th>Nombre total d'heure de travail</th>

                                                                                </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                <tr>
                                                                                    <td class="text-center">{{datavalue.__ha}}</td>
                                                                                    <td class="text-center">{{datavalue.__hd}}</td>
                                                                                    <td class="text-center">{{datavalue.__p}}</td>
                                                                                    <td class="text-center">{{datavalue.__nb}}</td>
                                                                                </tr>

                                                                                </tbody>



                                                                            </table>


                                                                        </td>
                                                                    </tr>


                                                                </template>

                                                            </template>


                                                            <template v-else>
                                                                <tr >
                                                                    <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                                </tr>

                                                            </template>



                                                            </tbody>



                                                        </table>


                                                            </div>
                                                        </div>




                                                    </div>
                                                </div>





                                                <div class="box-footer border-t-0">
                                                    <div class="flex items-center flex-wrap overflow-auto">
                                                        <div class="mb-2 sm:mb-0">

                                                        </div>
                                                        <div class="ms-auto">


                                                            <!--nav aria-label="Page navigation">
                                                                <ul class="ti-pagination  mb-0">
                                                                    <li class="page-item "><button  :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]"  @click="page--">Previous</button></li>
                                                                    <li class="page-item">

                                                                        <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                                                :key="key" v-for="(pageNumber,key) in pages.slice(page-1, page+5)"
                                                                                @click="page = pageNumber">{{pageNumber}}</button>

                                                                    </li>
                                                                    <li class="page-item" ><button :class="{ 'disabled': page => pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++" >Next</button></li>
                                                                </ul>
                                                            </nav-->



                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--End::row-1 -->















                                </div>
                            </div>



                        </div>
                    </div>




                </div>
                <div class="ti-modal-footer">
                    <button style="background: red" @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" >
                        Fermer
                    </button>
                    <button style="background: green" v-if="typeaction==='add'" class="ti-btn ti-btn-primary-full" href="javascript:void(0);">
                       Enregistrer
                    </button>
                    <button style="background: green" v-if="typeaction==='update'" class="ti-btn ti-btn-primary-full" href="javascript:void(0);">
                       Modifier
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



</template>

<script>

    import {currentDate, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');


    export default {
        /* eslint-disable */
        name: "modalform",
        watch: {


        },

        computed:{

/*
            filterdatatable(){
                let mvalue=this.datatable;
                this.montanttotaloperation=0;
                if (this.searchword!==''){

                    this.page=this.Defaultpage;
                    this.perPage=this.DefaultperPage;

                    mvalue = this.datatablesearch.filter( post => post.__name.toLowerCase().includes(this.searchword.toLowerCase())
                        // || post.__service.toLowerCase().includes(this.searchwordservice.toLowerCase())

                    )

                }

                if (this.searchwordservice!==''){

                    this.page=this.Defaultpage;
                    this.perPage=this.DefaultperPage;

                    mvalue = mvalue.filter( post => post.__service.toLowerCase().includes(this.searchwordservice.toLowerCase())
                        // || post.__service.toLowerCase().includes(this.searchwordservice.toLowerCase())

                    )

                }


                //console.log('mvalue ',mvalue)
                return (mvalue) ;
            },
*/
            mdatedarrive(){

                this.datededarrive=this.chargedate
                return this.datededarrive
            },
            mnbredheuretotal(){
                /*
                  const dateFromAPI = "2016-02-10T00:00:00Z";

                const now = new Date();
                const datefromAPITimeStamp = (new Date(dateFromAPI)).getTime();
                const nowTimeStamp = now.getTime();

                const microSecondsDiff = Math.abs(datefromAPITimeStamp - nowTimeStamp);

                // Math.round is used instead of Math.floor to account for certain DST cases
                // Number of milliseconds per day =
                //   24 hrs/day * 60 minutes/hour * 60 seconds/minute * 1000 ms/second
                const daysDiff = Math.round(microSecondsDiff / (1000 * 60 * 60  * 24));

                console.log(daysDiff);



                                */

                this.nbredheuretotal=0

                //create date format
                /*     var timeStart = new Date(this.Item.dateheuresupp+" "+ this.Item.heuredebut).getHours();
                     var timeEnd = new Date(this.Item.dateheuresupp+" "+ this.Item.heurefin).getHours();
             */

                var timeStart = new Date(this.mdatedarrive+" "+ this.heurearrive);
                var timeEnd = new Date(this.datededepart+" "+ this.heurededepart);

                if(this.datededepart!=="" && this.heurededepart!==""){


                    if(timeStart.getTime()>timeEnd.getTime()){
                        toastr['error']( "L'heure d'arrivée ne doit pas être supérieur à l'heure de départ. Veuillez changer.",
                            "<b></b>");

                        this.datededepart=''
                        this.heurededepart=''
                        return


                    }



                }


                var optime= Math.abs(timeEnd.getTime()-timeStart.getTime());


                /*   var mcollecttimeheureStart=optime.getHours()
                 var mcollecttimeminuteend=(optime.getMinutes()/60)
                   var mhourDiff=mcollecttimeheureStart+Math.round(mcollecttimeminuteend)
              */
                /*console.log('mcollecttimeheureStart ',mcollecttimeheureStart)
                console.log('mcollecttimeminuteend ',mcollecttimeminuteend)*/
                // console.log('optime ',optime)
                //console.log('mhourDiff ',mhourDiff)

                const microSecondsDiff = optime;

//   24 hrs/day * 60 minutes/hour * 60 seconds/minute * 1000 ms/second
                //const daysDiff = Math.round(microSecondsDiff / (1000 * 60 * 60  * 24));
                const heureDiff = Math.round(microSecondsDiff / (1000 * 60 * 60));

                //   console.log('daysDiff ',heureDiff);



                /* var collecttimeheureStart=timeStart.getHours()
                 var collecttimeheureend=timeEnd.getHours()

                 var collecttimeminuteStart=timeStart.getMinutes()
                 var collecttimeminuteend=timeEnd.getMinutes()

                 collecttimeheureStart=collecttimeheureStart+(collecttimeminuteStart/60)
                 collecttimeheureend=collecttimeheureend+(collecttimeminuteend/60)*/


                /* console.log('timeStart ',collecttimeheureStart)
                 console.log('timeEnd ',collecttimeheureend) */

                // var hourDiff = collecttimeheureend - collecttimeheureStart;
                var hourDiff = heureDiff;

                /*  console.log('hourDiff ',hourDiff)*/


                if (hourDiff>0){
                    this.nbredheuretotal=Math.round(hourDiff-(this.dureedelapause/60))
                }else{ this.nbredheuretotal=hourDiff}



                return this.nbredheuretotal

            },

           filterdatatable(){

                let mvalue=this.datatable;


                if (this.searchwordservice!==''){
                    let t=this;


                    mvalue = mvalue.filter( function (post) {
                        return    post.__service.toLowerCase().includes(t.searchwordservice.toLowerCase())

                    })

                }


                if (this.searchword!==''){
                    let t=this;


                    mvalue = mvalue.filter( function (post) {
                        return    post.__name.toLowerCase().includes(t.searchword.toLowerCase())
                        /*||
                        post.nameclient.toLowerCase().includes(t.searchword.toLowerCase()) ||
                        post.numbon.toLowerCase().includes(t.searchword.toLowerCase())*/
                    })

                }



                return mvalue ;
            },



        },
        components: {


        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",
                searchword:"",
                typedoc:"",
                typeaction:"add",
                checkallrowfortable:false,



                typsql:"",
                dataval:[],
                listpers:[],
                listtiers:[],
                datatable:[],
                datatablecheck:[],
                datatablesearch:[],
                loading:true,
                isdisplayform:false,
                messagesuppression:"",
                mshow:false,
                searchwordservice:'',
                titlepage:'',
                mname:'',
                titlebtnadd:'',
                routertype:'',
                montanttotaloperation:0,
                chargedate:currentDate(),
                datededepart:currentDate(),
                heurededepart:'',
                datededarrive:'',
                heurearrive:'',
                typepersonnel:'personnel',
                dureedelapause:0,
                nbredheuretotal:0,




            };
        },
        methods: {


            saveItemsselected(){

                let mvalue=[];
                mvalue=this.datatablecheck
                if (mvalue.length>0){
                    this.$store.dispatch('lancerchargement',true)
                    let t=this;
                    mvalue.forEach(function (value,index) {

                        t.savevalue(value.value,value.index)

                    })

                    this.$store.dispatch('lancerchargement',false)
                }

                },

            removeItemsselected(){



                let mvalue=[];
                mvalue=this.datatablecheck
                if (mvalue.length>0){
                    this.$store.dispatch('lancerchargement',true)
                    let t=this;
                    mvalue.forEach(function (value,index) {

                        t.annulerplanning(value.value,value.index)

                    })

                    this.$store.dispatch('lancerchargement',false)
                }
            },

            onChangecheckallrowline(mvalue,index){


                //console.log("mvalue ********* 1111 ",mvalue)

               if (mvalue.__checkline===true){

                   this.datatablecheck.push({index:index,value:mvalue})


                }
               else{

                   let idToRemove = index;

                        // Find the index of the object with the specified id
                   let mindex = this.datatablecheck.findIndex(obj => obj.index === idToRemove);

                   // If the object is found, remove it
                   if (mindex !== -1) {
                       this.datatablecheck.splice(mindex, 1);
                   }


               }

               // console.log("mvalue ********* 2222 ",mvalue)

            },

            onChangecheckallrowfortable(){


                let mvalue=[];
                mvalue=this.filterdatatable




                if (this.checkallrowfortable===true){

                    this.datatablecheck=[]
                    if (mvalue.length>0){
                        this.$store.dispatch('lancerchargement',true)
                        let t=this;
                        mvalue.forEach(function (value,index) {
                            value.__checkline=true

                            t.datatablecheck.push({index:index,value:value})

                        })

                        this.$store.dispatch('lancerchargement',false)

                    }


                }else{


                    //if (mvalue.length>0){
                        this.$store.dispatch('lancerchargement',true)
                        let t=this;
                        mvalue.forEach(function (value,index) {
                            value.__checkline=false
                        })

                    t.datatablecheck=[]

                        this.$store.dispatch('lancerchargement',false)

                  //  }



                }

            },

            close(){

                this.isdisplay=false;

                this.$emit('closed');

            },

            show(titlemodal,typeaction){

                this.titlemodal=titlemodal
                this.typeaction=typeaction

                this.isdisplay=true;

            },




            changevalue(){

                if(this.datatable.length>0){
                    this.datatable=[];
                    this.datatablesearch=[];

                }



            },

            async chargerdate(value,typepersonnel){
                this.routertype=this.$route.params.typ
                let typ=this.$route.params.typ



                if (this.heurearrive===""){
                    toastr['error']( "Veuillez saisir l'heure d'arrivée.")
                    return

                }

                if (this.datededarrive===""){
                    toastr['error']( "Veuillez saisir la date d'arrivée.")
                    return

                }

                if (this.datededepart===""){

                    toastr['error']( "Veuillez saisir la date de départ. ")
                    return
                }


                if (this.heurededepart===""){

                    toastr['error']( "Veuillez saisir l'heure de départ. ")
                    return

                }


                if (parseInt(this.nbredheuretotal)<=0){

                    toastr['error']( "Le nombre total d'heure n'est pas valide.")
                    return
                }


                var timeStart = new Date(this.mdatedarrive+" "+ this.heurearrive);
                var timeEnd = new Date(this.datededepart+" "+ this.heurededepart);

                if(this.datededepart!=="" && this.heurededepart!==""){


                    if(timeStart.getTime()>timeEnd.getTime()){
                        toastr['error']( "L'heure d'arrivée ne doit pas être supérieur à l'heure de départ. Veuillez changer.",
                            "<b></b>");

                        this.datededepart=''
                        this.heurededepart=''
                        return


                    }



                }



                this.$store.dispatch('lancerchargement',true)

                //m/egl74dkjskpresence771714dfe/presence/planning/:keyuser/all/:dateop/:ftoken//
                //*********************** formatge title page *********************************//

                // this.$store.dispatch('listedesfonctions',this.routertype)
                //let response =   axios  .get('/api/___jkkjdf865pppplanning/_8489init/'+this.$store.state.appsociete+'/'+typepersonnel+'/'+this.$store.state.beartoken+'/'+value+'/')

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74dkjskpresence771714dfe/presence/planning/'+this.$store.state.appsociete+'/all/'+typepersonnel+'/'+this.mdatedarrive+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })
                    .then((res)=>{
                       // this.$store.dispatch('beartoken',res.data.beartoken)
                        this.$store.dispatch('lancerchargement',false)

                      //  this.datatable=res.data.m;


                        if(res.data.success){


                            this.datatable=res.data.m;



                        }
                        else{
                            this.datatable=[];


                        }


                    })
                    .catch((error) => {
                        toastr['error']( "Votre requête n'est pas valide. Veuillez actualiser la page.","<b></b>");
                        this.$store.dispatch('lancerchargement',false)
                        this.datatable=[];
                    })

                    .finally(() => this.$store.dispatch('lancerchargement',false))




            },

            getstyle(value){

                if (value===0){return ''}
                if (value===1){return 'background-color: green!important;color:white!important'}
                if (value===2){return 'background-color: red!important;color:white!important'}


            },

            async  savevalue(value,index){



                value.__dureedelapause=this.dureedelapause;
                value.__nbredheuretotal=this.nbredheuretotal;
                value.__typepersonnel=this.typepersonnel;
                value.__datededepart=this.datededepart;
                value.__heurededepart=this.heurededepart;
                value.__datededarrive=this.datededarrive;
                value.__heurearrive=this.heurearrive;






                value.__valuebgd=0;
                value.__msg=" <br/>";


                if (value.__nbredheuretotal<=0){
                    value.__valuebgd=2;
                    value.__msg+="Le nombre total d'heure n'est pas valide. <br/>";

                }

                if (value.__heurearrive===""){
                    value.__valuebgd=2;
                    value.__msg+="Veuillez saisir l'heure d'arrivée. <br/>";

                }

                if (value.__datededarrive===""){
                    value.__valuebgd=2;
                    value.__msg+="Veuillez saisir la date d'arrivée. <br/>";

                }

                if (value.__datededepart===""){
                    value.__valuebgd=2;
                    value.__msg+="Veuillez saisir la date de départ. <br/>";

                }


                if (value.__heurededepart===""){
                    value.__valuebgd=2;
                    value.__msg+="Veuillez saisir l'heure de départ. <br/>";

                }
                if (value.__valuebgd!==0){
                    return;

                }



                var timeStart = new Date(this.mdatedarrive+" "+ this.heurearrive);
                var timeEnd = new Date(this.datededepart+" "+ this.heurededepart);

                if(this.datededepart!=="" && this.heurededepart!==""){


                    if(timeStart.getTime()>timeEnd.getTime()){
                        toastr['error']( "L'heure d'arrivée ne doit pas être supérieur à l'heure de départ. Veuillez changer.",
                            "<b></b>");

                        this.datededepart=''
                        this.heurededepart=''
                        return


                    }



                }




                const t = this;

                let url=process.env.VUE_APP_API_URL+'/egl74dkjskpresence771714dfe/presence/planning/'+this.$store.state.appsociete+'/operation/check/'+randomString();
                let response =   axios  .post(url,value,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })


                let res= await response
                    .then(function (res){
                        /* t.$store.dispatch('beartoken',res.data.beartoken)*/
                        /* t.$store.dispatch('lancerchargement',false)*/
                        if(res.data.success){


                            // toastr['success']( res.data.message,"<b>Enregistrement</b>");
                            // t.$store.dispatch('filterdatatable',res.data.lst)

                            // t.isdisplay=false

                            value.__valuebgd=1;
                            value.__etatplanning=1;
                            value.__msg="";
                            value.__ssid=res.data.___p;

                            value.__ha=t.datededarrive+" "+t.heurearrive
                            value.__hd=t.datededepart+" "+t.heurededepart
                            value.__p=t.dureedelapause
                            value.__nb=t.nbredheuretotal

                            value.__checkline=false



                        }else{

                            value.__valuebgd=2;
                            value.__msg+=res.data.message+" . <br/>";


                        }


                    })
                    .catch(
                        function (error) {
                            value.__valuebgd=2;
                            value.__msg+="Une erreur c'est produite lors du check, veuillez actualiser la page et reprendre. <br/>";

                        }
                    )
                    .finally(() => this.$store.dispatch('lancerchargement',false))











            },


            async  annulerplanning(value,index){



                value.__dureedelapause=this.dureedelapause;
                value.__nbredheuretotal=this.nbredheuretotal;
                value.__typepersonnel=this.typepersonnel;
                value.__datededepart=this.datededepart;
                value.__heurededepart=this.heurededepart;
                value.__datededarrive=this.datededarrive;
                value.__heurearrive=this.heurearrive;









                value.__valuebgd=0;
                value.__msg=" <br/>";


                if (value.__nbredheuretotal<=0){
                    value.__valuebgd=2;
                    value.__msg+="Le nombre total d'heure n'est pas valide. <br/>";

                }

                if (value.__heurearrive===""){
                    value.__valuebgd=2;
                    value.__msg+="Veuillez saisir l'heure d'arrivée. <br/>";

                }

                if (value.__datededarrive===""){
                    value.__valuebgd=2;
                    value.__msg+="Veuillez saisir la date d'arrivée. <br/>";

                }

                if (value.__datededepart===""){
                    value.__valuebgd=2;
                    value.__msg+="Veuillez saisir la date de départ. <br/>";

                }


                if (value.__heurededepart===""){
                    value.__valuebgd=2;
                    value.__msg+="Veuillez saisir l'heure de départ. <br/>";

                }
                if (value.__valuebgd!==0){
                    return;

                }



                var timeStart = new Date(this.mdatedarrive+" "+ this.heurearrive);
                var timeEnd = new Date(this.datededepart+" "+ this.heurededepart);

                if(this.datededepart!=="" && this.heurededepart!==""){


                    if(timeStart.getTime()>timeEnd.getTime()){
                        toastr['error']( "L'heure d'arrivée ne doit pas être supérieur à l'heure de départ. Veuillez changer.",
                            "<b></b>");

                        this.datededepart=''
                        this.heurededepart=''
                        return


                    }



                }




               // const t = this;
                // this.loading=true;

               // let response =   axios  .post('/api/___jkkjdf865pppplanning/____deluidpofpers564554ddd545f/'+this.$store.state.appsociete+'/'+this.typepersonnel+'/'+value.__bb, value)





                const t = this;

                let url=process.env.VUE_APP_API_URL+'/egl74dkjskpresence771714dfe/presence/planning/'+this.$store.state.appsociete+'/operation/deletecheck/'+randomString();
                let response =   axios  .post(url,value,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })



                let res= await response
                    .then(function (res){
                        /* t.$store.dispatch('beartoken',res.data.beartoken)*/
                        /* t.$store.dispatch('lancerchargement',false)*/
                        if(res.data.success){


                            // toastr['success']( res.data.message,"<b>Enregistrement</b>");
                            // t.$store.dispatch('filterdatatable',res.data.lst)

                            // t.isdisplay=false

                            value.__valuebgd=1;
                            value.__etatplanning=0;
                            value.__msg="";
                            value.__ssid="";

                            value.__ha=""
                            value.__hd=""
                            value.__p=""
                            value.__nb=""

                        }else{

                            value.__valuebgd=2;
                            value.__msg+=res.data.message+" . <br/>";


                        }


                    })
                    .catch(
                        function (error) {
                            value.__valuebgd=2;
                            value.__msg+="Une erreur c'est produite lors du check, veuillez actualiser la page et reprendre. <br/>";

                        }
                    )
                    .finally(() => this.$store.dispatch('lancerchargement',false))











            },


            async load(){
                /*  this.routertype=this.$route.params.typ
                  let typ=this.$route.params.typ
  */
                // this.$store.dispatch('lancerchargement',true)

                //*********************** formatge title page *********************************//

                this.titlepage="Définir le planning de présence";


                // this.titlepage=this.titlepage

                let t=this

                this.datatable=[];
                this.datatablesearch=[];
                this.listpers=[];
                this.listtiers=[];


            },

            checkall(){
                let mvalue=[];
                mvalue=this.filterdatatable
                if (mvalue.length>0){
                    this.$store.dispatch('lancerchargement',true)
                    let t=this;
                    mvalue.forEach(function (value,index) {
                        t.savevalue(value,index)

                        //console.log(index);
                    })

                    this.$store.dispatch('lancerchargement',false)

                }


            },
            removeall(){

                let mvalue=[];
                mvalue=this.filterdatatable
                if (mvalue.length>0){
                    this.$store.dispatch('lancerchargement',true)
                    let t=this;
                    mvalue.forEach(function (value,index) {
                        t.annulerplanning(value,index)

                    })

                    this.$store.dispatch('lancerchargement',false)

                }


            },







        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>
    .modalstyle{
        max-width: 90%!important;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


    }





</style>