<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="box-body">
                        <div class="border-b-0 border-gray-200 dark:border-white/10">
                            <nav class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" aria-label="Tabs">
                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 active"
                                        id="hs-tab-js-behavior-item-1" data-hs-tab="#hs-tab-js-behavior-1" aria-controls="hs-tab-js-behavior-1">
                                    -
                                </button>



                            </nav>
                        </div>

                        <div class="">
                            <div id="hs-tab-js-behavior-1" role="tabpanel" aria-labelledby="hs-tab-js-behavior-item-1" class="">
                                <div class="ti-custom-validation mt-5" >

                                    <div class="grid grid-cols-12 gap-x-6">

                                        <div class="mb-4  xl:col-span-12 col-span-12">
                                            <label for="small-file-input" class="sr-only">Choose file</label>
                                            <input @change="loadCSV($event)" accept=".csv" type="file" name="small-file-input" id="small-file-input"
                                                   class="block w-full border border-gray-200 focus:shadow-sm dark:focus:shadow-white/10
                                                    rounded-sm text-sm focus:z-10 focus:outline-0 focus:border-gray-200 dark:focus:border-white/10
                                                    dark:border-white/10 dark:text-white/50
                                       file:border-0
                                      file:bg-light file:me-4
                                      file:py-2 file:px-4
                                      dark:file:bg-black/20 dark:file:text-white/50">
                                        </div>

                                    </div>




                                    <!-- Start::row-1 -->
                                    <div class="w-full grid grid-cols-12">
                                        <div class="xl:col-span-12 col-span-12">
                                            <div class="box  overflow-hidden">
                                                <div class="box-header justify-between">
                                                    <div class="box-title">

                                                    </div>
                                                    <div class="flex flex-wrap gap-2">



                                                    </div>
                                                    <div class="w-full  grid grid-cols-2">


                                                        <div class="mb-2 sm:mb-0">
                                                            <div data-v-2f5924ee="" style="margin-top: 10px;"><button data-v-2f5924ee="" style="background-color: greenyellow;">&nbsp;</button> <span data-v-2f5924ee="" style="font-weight: bold; color: black;">Personnel verifier</span></div>
                                                            <div data-v-2f5924ee="" style="margin-top: 10px;"><button data-v-2f5924ee="" style="background-color: red;">&nbsp;</button> <span data-v-2f5924ee="" style="font-weight: bold; color: black;">Personnel non verifier</span></div>

                                                        </div>


                                                        <div>
                                                            <input v-model="searchword" class="form-control form-control-lg" type="text"
                                                                   placeholder="Rechercher"
                                                                   aria-label=".form-control-lg example">
                                                        </div>

                                                    </div>
                                                </div>


                                                <div class="box-body !p-0">
                                                    <div class="table-responsive">
                                                        <div class="flex flex-col h-screen">
                                                            <div class="flex-grow overflow-auto">

                                                        <table class="relative w-full border table mytable table-hover whitespace-nowrap min-w-full">
                                                            <thead>

                                                             <tr>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">DN</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">UID</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">NAME</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">STATUTS</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">ACTION</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">APB</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">JOBCODE</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">DATETIME</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">MACHINE</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>


                                                            <template v-if="filterdatatable.length">
                                                                <template  v-for="(datavalue,index) in filterdatatable" :key="index">



                                                                    <template v-if="datavalue.checkline===1">
                                                                        <tr
                                                                                style="background-color: greenyellow!important;color: black;  font-weight: 600;/*background: khaki;*/" >
                                                                            <td  data-label="N°  : "   >{{ index+1 }}</td>
                                                                            <td  data-label="DN  : "   >{{ datavalue.dn }}</td>
                                                                            <td  data-label="UID  : "   >{{ datavalue.uid }}</td>
                                                                            <td  data-label="NAME  : "   >{{ datavalue.name }}  </td>
                                                                            <td  data-label="STATUTS  : "   >{{ datavalue.statuts }}</td>
                                                                            <td  data-label="ACTION  : "   >{{ datavalue.action }}</td>
                                                                            <td  data-label="APB  : "   >{{ datavalue.apb }}</td>
                                                                            <td  data-label="JOBCODE  : "   >{{ datavalue.jobcode }}</td>
                                                                            <td  data-label="DATETIME  : "   >{{ datavalue.datetime }}</td>
                                                                            <td  data-label="MACHINE  : "   >{{ datavalue.machine.name }}</td>


                                                                        </tr>


                                                                    </template>
                                                                    <template v-else>
                                                                        <tr
                                                                                style="background-color: red!important;color: white;  font-weight: 600;/*background: khaki;*/" >
                                                                            <td  data-label="N°  : "   >{{ index+1 }}</td>
                                                                            <td  data-label="DN  : "   >{{ datavalue.dn }}</td>
                                                                            <td  data-label="UID  : "   >{{ datavalue.uid }}</td>
                                                                            <td  data-label="NAME  : "   >{{ datavalue.name }}  </td>
                                                                            <td  data-label="STATUTS  : "   >{{ datavalue.statuts }}</td>
                                                                            <td  data-label="ACTION  : "   >{{ datavalue.action }}</td>
                                                                            <td  data-label="APB  : "   >{{ datavalue.apb }}</td>
                                                                            <td  data-label="JOBCODE  : "   >{{ datavalue.jobcode }}</td>
                                                                            <td  data-label="DATETIME  : "   >{{ datavalue.datetime }}</td>
                                                                            <td  data-label="MACHINE  : "   >{{ datavalue.machine.name }}</td>


                                                                        </tr>

                                                                    </template>



                                                                </template>
                                                            </template>
                                                            <template v-else>
                                                                <tr >
                                                                    <td colspan="20"><div class="alert alert-danger text-center">Aucune ligne n'a été trouvée.</div></td>
                                                                </tr>

                                                            </template>



                                                            </tbody>


                                                            <!--tfoot>
                                                            <tr>
                                                                <td colspan="20">
                                                                     </td>
                                                            </tr>
                                                            </tfoot-->

                                                        </table>

                                                    </div>
                                                    </div>
                                                    </div>
                                                </div>




                                                <div class="box-footer !p-0">
                                                    <button @click="importationdesfichierscsv" class="w-full ti-btn ti-btn-secondary-full ti-btn-wave"> <i class="bi bi-upload"></i> Importer le fichier ( Personnel vérifier )</button>

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                    <!--End::row-1 -->















                                </div>
                            </div>



                        </div>
                    </div>




                </div>
                <div class="ti-modal-footer">
                    <button style="background: red" @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" >
                        Fermer
                    </button>
                    <button style="background: green" v-if="typeaction==='add'" class="ti-btn ti-btn-primary-full" href="javascript:void(0);">
                       Enregistrer
                    </button>
                    <button style="background: green" v-if="typeaction==='update'" class="ti-btn ti-btn-primary-full" href="javascript:void(0);">
                       Modifier
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



</template>

<script>

    import {randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');
    export default {
        /* eslint-disable */
        name: "modalform",
        watch: {


        },

        computed:{
            filterdatatable(){

                let mvalue=this.dataimportcsv;



                if (this.searchword!==''){
                    let t=this;


                    mvalue = mvalue.filter( function (post) {
                        return    post.name.toLowerCase().includes(t.searchword.toLowerCase())

                    })

                }



                return mvalue ;
            },



        },
        components: {


        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",
                searchword:"",
                typedoc:"",
                typeaction:"add",
                dataexport:[],

                dataval:[],
                dataimportcsv:[],
                dataimportcsvok:[],
                datapersonnel:[],
                listtiers:[],
                datatable:[],
                datatablesearch:[],

                channel_name: '',
                channel_fields: [],
                channel_entries: [],
                parse_header: [],
                parse_csv: [],
            };
        },
        methods: {

            close(){

                this.isdisplay=false;

                this.$emit('closed');

            },

            async  show(titlemodal,typeaction){

                this.titlemodal=titlemodal
                this.typeaction=typeaction

                //this.isdisplay=true;



                this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74dkjskpresence771714dfe/presence/pointage/'+this.$store.state.appsociete+'/importation/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)

                        if(res.data.success){


                            t.datapersonnel=res.data.m;

                            t.isdisplay=true;

                        }
                        else{
                            t.datapersonnel=[];
                            t.isdisplay=true;
                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })





            },



            csvJSON(csv){
                var vm = this
                var lines = csv.split("\n")
                var result = []
                vm.dataimportcsvok = []
                vm.dataimportcsv = []
                var headers = lines[0].split(",")
                vm.parse_header = lines[0].split(",")
                /*lines[0].split(",").forEach(function (key) {
                    vm.sortOrders[key] = 1
                })*/

                lines.map(function(line, indexLine){
                    if (indexLine < 1) return // Jump header line

                    var obj = {}
                    var currentline = line.split(",")

                    headers.map(function(header, indexHeader){

                        let bvar=currentline[indexHeader].split("\n")
                        let arrayrows = bvar[0].slice(bvar[0].indexOf("\n") + 1).split("\n");
                        let rows=arrayrows[0].split("\t")
                        /* console.log('rows[] ',rows[0])
                         console.log('rows[] ',rows[1])
                         console.log('rows[] ',rows[2])
                         console.log('rows[] ',rows[3])
                         console.log('rows[] ',rows[4])
                         console.log('rows[] ',rows[5])
                         console.log('rows[] ',rows[6])
                         console.log('rows[] ',rows[7])
                         console.log('rows[9] ',rows[8])*/

                        if (rows[8] === undefined || rows[8] === null) {

                        }else{


                            if (parseInt(rows[2])!==0 && rows[3]!=="" && rows[3] !== undefined && rows[3] !== null){

                                vm.dataimportcsv.push({
                                    'no':parseInt(rows[0]),
                                    'dn':parseInt(rows[1]),
                                    'uid':parseInt(rows[2]),
                                    'name':rows[3],
                                    'machine':{pid:randomString(),name:''},
                                    'statuts':rows[4],
                                    'action':rows[5],
                                    'apb':rows[6],
                                    'jobcode':rows[7],
                                    'datetime':rows[8],
                                    'checkline':0,
                                    'pid':"",
                                })

                            }



                        }

                        obj[header] = currentline[indexHeader]

                    })

                    result.push(obj)
                })

                let orderedStories = vm.dataimportcsv.sort((a, b) => {
                    // if (this.sortBy == 'alphabetically') {
                    let fa = a.datetime.toLowerCase(), fb = b.datetime.toLowerCase()

                    if (fa < fb) {
                        return -1
                    }
                    if (fa > fb) {
                        return 1
                    }
                    return 0
                    // }

                })

                let vmdataimportcsv=orderedStories

                vm.datapersonnel.forEach(function (personnel) {

                    vmdataimportcsv.forEach(function (line) {


                        if (line.uid===personnel.pdin ){

                            line.checkline=1
                            line.pid=personnel.pid

                            vm.dataimportcsvok.push(line)

                        }
                        /*else{
                            line.checkline=0
                            line.pid=""

                        }*/
                    })



                })
                vm.dataimportcsv=vmdataimportcsv

                result.pop() // remove the last item because undefined values

                return result // JavaScript object
            },

            loadCSV(e) {
                var vm = this
                if (window.FileReader) {
                    var reader = new FileReader();
                    reader.readAsText(e.target.files[0]);
                    // Handle errors load
                    reader.onload = function(event) {
                        var csv = event.target.result;

                        vm.parse_csv = vm.csvJSON(csv)

                    };
                    reader.onerror = function(evt) {
                        if(evt.target.error.name == "NotReadableError") {
                            alert("Canno't read file !");
                        }
                    };


                } else {
                    alert('FileReader are not supported in this browser.');
                }




            },



          async  importationdesfichierscsv(){

                if (this.dataimportcsvok.length<=0){return; }
                this.$store.dispatch('lancerchargement',true)

                let Item=  this.dataimportcsvok
                const t = this;

                let url=process.env.VUE_APP_API_URL+'/egl74dkjskpresence771714dfe/presence/pointage/'+this.$store.state.appsociete+'/importationfile/'+randomString();
                let response =   axios  .post(url,Item,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)

                        if(res.data.success){

                            t.dataimportcsvok=[]
                            t.dataimportcsv=[]

                            toastr['success']( res.data.message,"<b></b>");

                        }
                        else{

                            t.dataimportcsvok=[]
                            t.dataimportcsv=[]

                            toastr['error']( res.data.message,"<b></b>");
                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })





            }




        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>
    .modalstyle{
        max-width: 90%!important;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


    }





</style>