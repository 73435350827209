<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title headertitle" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="box-body">
                        <div class="border-b-0 border-gray-200 dark:border-white/10">
                            <nav class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" aria-label="Tabs">
                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 active"
                                        id="hs-tab-js-behavior-item-1" data-hs-tab="#hs-tab-js-behavior-1" aria-controls="hs-tab-js-behavior-1">
                                    -
                                </button>



                            </nav>
                        </div>

                        <div class="">
                            <div id="hs-tab-js-behavior-1" role="tabpanel" aria-labelledby="hs-tab-js-behavior-item-1" class="">
                                <div class="ti-custom-validation mt-5" >

                                    <!-- Start::row-1 -->
                                    <div class="w-full grid grid-cols-12">
                                        <div class="xl:col-span-12 col-span-12">
                                            <div class="box  overflow-hidden">
                                                <div class="box-header justify-between">

                                                    <div class="w-full grid grid-cols-12 gap-x-6">



                                                        <div class="mb-4  xl:col-span-12 col-span-12">
                                                            <input v-model="searchword" class="form-control form-control-lg" type="text"
                                                                   placeholder="Rechercher par le nom et prénom(s)"
                                                                   aria-label=".form-control-lg example">
                                                        </div>



                                                    </div>
                                                </div>


                                                <div class="box-body !p-0">



                                                    <!--div class="download-data">
                                                        <button type="button" class="ti-btn ti-btn-primary" ><i class="ri-file-excel-fill"></i> Exporter en excel</button>
                                                        <button type="button" class="ti-btn ti-btn-facebook" ><i class="ri-printer-fill"></i> Imprimer</button>
                                                    </div-->

                                                    <div class="table-responsive mt-3">

                                                        <div class="flex flex-col h-screen">
                                                            <div class="flex-grow overflow-auto">


                                                            <table class="table mytable table-hover whitespace-nowrap min-w-full">
                                                            <thead>

                                                             <tr>
                                                                 <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">NOM ET PRÉNOM(S)</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start" colspan="3" >OPTION</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">ACTION</th>

                                                            </tr>
                                                            </thead>
                                                            <tbody>


                                                            <template v-if="filterdatatable.length>0">

                                                                <template  v-for="(datavalue,index) in filterdatatable" :key="index">

                                                                    <tr :style="getstyle(datavalue.__valuebgd)"  class="border-t hover:bg-gray-200 dark:hover:bg-light">

                                                                    <td data-label="N°  : ">{{index+1}}</td>
                                                                    <td  data-label="NOM ET PRÉNOM(S)  : "   >{{ datavalue.__name }}</td>
                                                                    <td  data-label="OPTION  : " colspan="3"  >
                                                                        <table class="table table-responsive table-bordered">
                                                                            <tbody>
                                                                            <tr>
                                                                                <td data-label="Temps de présence : ">
                                                                                    <select v-model="datavalue.__typederegle" class="form-control">
                                                                                        <option value="Jour">Jour</option>
                                                                                        <option value="Nuit">Nuit</option>
                                                                                    </select>
                                                                                </td>

                                                                                <td data-label="Heure d'arrivée  : ">
                                                                                    <input  v-model="datavalue.__timearrivee"
                                                                                            type="time" class="form-control" >
                                                                                </td>
                                                                                <td data-label="Heure de départ  : ">
                                                                                    <input    v-model="datavalue.__timesortie"
                                                                                              type="time" class="form-control" >
                                                                                </td>
                                                                                <td data-label="Durée de pause(minute)  : ">
                                                                                    <input    v-model="datavalue.__pause"
                                                                                              type="number" class="form-control text-center" >
                                                                                </td>
                                                                                <td data-label="Heure total de travail  : ">
                                                                                    <input disabled   v-model="datavalue.__heuretotal"
                                                                                           type="number" class="form-control text-center" >
                                                                                </td>

                                                                            </tr>
                                                                            </tbody>
                                                                        </table>


                                                                    </td>

                                                                    <td data-label="ACTION  : "  >
                                                                        <button @click="savevalue(datavalue,index)" class="ti-btn ti-btn-primary-full"><i class="ri-check-line"></i> check</button>

                                                                    </td>

                                                                    </tr>

                                                                    <tr :style="getstyle(datavalue.__valuebgd)" v-if="datavalue.__valuebgd!==0 && datavalue.__msg!==''">
                                                                        <td colspan="20"> <div style="font-size: 12px; font-weight: bold;" v-html="datavalue.__msg"></div> </td>
                                                                    </tr>


                                                                </template>

                                                            </template>


                                                            <template v-else>
                                                                <tr >
                                                                    <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                                </tr>

                                                            </template>


                                                            </tbody>



                                                        </table>


                                                            </div>
                                                        </div>




                                                    </div>
                                                </div>





                                                <div class="box-footer border-t-0">
                                                    <div class="flex items-center flex-wrap overflow-auto">
                                                        <div class="mb-2 sm:mb-0">

                                                        </div>
                                                        <div class="ms-auto">


                                                            <nav aria-label="Page navigation">
                                                                <ul class="ti-pagination  mb-0">
                                                                    <li class="page-item "><button  :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]"  @click="page--">Previous</button></li>
                                                                    <li class="page-item">

                                                                        <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                                                :key="key" v-for="(pageNumber,key) in pages.slice(page-1, page+5)"
                                                                                @click="page = pageNumber">{{pageNumber}}</button>

                                                                    </li>
                                                                    <li class="page-item" ><button :class="{ 'disabled': page => pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++" >Next</button></li>
                                                                </ul>
                                                            </nav>



                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--End::row-1 -->















                                </div>
                            </div>



                        </div>
                    </div>




                </div>
                <div class="ti-modal-footer">
                    <button style="background: red" @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" >
                        Fermer
                    </button>
                    <button style="background: green" v-if="typeaction==='add'" class="ti-btn ti-btn-primary-full" href="javascript:void(0);">
                       Enregistrer
                    </button>
                    <button style="background: green" v-if="typeaction==='update'" class="ti-btn ti-btn-primary-full" href="javascript:void(0);">
                       Modifier
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



</template>

<script>

    const axios =require('axios');
    import {currentDate, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";



    function checkZero(i)
    {
        if (i < 10)
        {
            i = "0" + i
        }  // add zero in front of numbers < 10

        return i;
    }

    export default {
        /* eslint-disable */
        name: "modalform",
        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },

        },

        computed:{
            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                let mvalue=this.datatable;

                //this.page=this.Defaultpage;
                this.perPage=this.DefaultperPage;

                /* if (this.searchtrierletableau!==''){
                     let t=this;

                     mvalue = mvalue.filter( function (post) {

                         return   post.etatcontrat===parseFloat(t.searchtrierletableau)

                     })

                 }*/


                if (this.searchword!==''){
                    let t=this;


                    mvalue = mvalue.filter( function (post) {
                        return    post.__name.toLowerCase().includes(t.searchword.toLowerCase())
                        /*||
                        post.nameclient.toLowerCase().includes(t.searchword.toLowerCase()) ||
                        post.numbon.toLowerCase().includes(t.searchword.toLowerCase())*/
                    })

                }



                return this.paginate(mvalue) ;
            },



        },
        components: {


        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",
                searchword:"",
                typedoc:"",
                typeaction:"add",
                chargedate:currentDate(),

                datatable:[],
                messageerror:"",
                messagesucces:"",

                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 50,
                Defaultpage: 1,
                DefaultperPage: 50,
                pages: [],
            };
        },
        methods: {

            close(){

                this.isdisplay=false;

                this.$emit('closed');

            },

            show(titlemodal,typeaction){

                this.titlemodal=titlemodal
                this.typeaction=typeaction

               // this.isdisplay=true;

                this.load()

            },


            async load(){

                //*********************** formatge title page *********************************//
                //this.titlepage="";


                this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74dkjskpresence771714dfe/presence/regledegestion/'+this.$store.state.appsociete+'/all/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)
                        t.datatable=[];

                        if(res.data.success){


                            t.datatable=res.data.m;

                            t.isdisplay=true;

                        }
                        else{
                            t.datatable=[];

                            t.isdisplay=true;
                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })


            },

            getstyle(value){

                if (value===0){return ''}
                if (value===1){return 'background-color: green!important;color:white!important'}
                if (value===2){return 'background-color: red!important;'}


            },

            async  savevalue(value,index){


                value.__valuebgd=0;
                value.__msg=" <br/>";


                if (value.__timearrivee===""){
                    value.__valuebgd=2;
                    value.__msg+="Veuillez saisir l'heure d'arrivé. <br/>";

                }

                if (value.__timesortie===""){
                    value.__valuebgd=2;
                    value.__msg+="Veuillez saisir l'heure de sortie. <br/>";

                }
                if (value.__valuebgd!==0){
                    return;

                }

                let nbredheuretotal=0

                //create date format


                var timeStart = new Date(this.chargedate+" "+ value.__timearrivee);
                var timeEnd = "";

                if (value.__typederegle==='Jour'){
                    timeEnd = new Date(this.chargedate+" "+ value.__timesortie);
                }
                else{


                    var d = new Date(this.chargedate);
                    d.setDate(d.getDate() + 1);

                    var year  = d.getFullYear();
                    var month = d.getMonth() + 1;
                    var day   = d.getDate();

                    month = checkZero(month);
                    day   = checkZero(day);

                    var mdate = "";

                    mdate += year;
                    mdate += "-";
                    mdate += month;
                    mdate += "-";
                    mdate += day;

                    var ddate = d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDate();

                    timeEnd = new Date(ddate+" "+value.__timesortie);
                }



                if(timeStart.getTime()>timeEnd.getTime()){

                    value.__timesortie=''
                    value.__valuebgd=2;
                    value.__msg+="L'heure d'arrivée ne doit pas être supérieur à l'heure de départ. Veuillez changer.<br/>";


                    return


                }



                var optime= Math.abs(timeEnd.getTime()-timeStart.getTime());



                const microSecondsDiff = optime;

                const heureDiff = Math.round(microSecondsDiff / (1000 * 60 * 60));


                var hourDiff = heureDiff;


                if (hourDiff>0){
                    nbredheuretotal=Math.round(hourDiff-(value.__pause/60))
                }else{ nbredheuretotal=hourDiff}


                value.__heuretotal=nbredheuretotal;


                const t = this;
                // this.loading=true;

              //  let response =   axios  .post('/api/___jkkjdf865pppresence/__savereglegestionpresence85221478/'+this.$store.state.appsociete+'/personnel/'+value.__bb, value)


                let url=process.env.VUE_APP_API_URL+'/egl74dkjskpresence771714dfe/presence/regledegestion/'+this.$store.state.appsociete+'/operation/'+randomString();
                let response =   axios  .post(url,value,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })


                let res= await response
                    .then(function (res){
                        /* t.$store.dispatch('beartoken',res.data.beartoken)*/
                        /* t.$store.dispatch('lancerchargement',false)*/
                        if(res.data.success){


                            // toastr['success']( res.data.message,"<b>Enregistrement</b>");
                            // t.$store.dispatch('listecatalogue',res.data.lst)

                            // t.isdisplay=false

                            value.__valuebgd=1;
                            value.__msg="";
                            value.__chekline=0

                        }else{

                            value.__valuebgd=2;
                            value.__msg+=res.data.message+" . <br/>";


                        }


                    })
                    .catch(
                        function (error) {
                            value.__valuebgd=2;
                            value.__msg+="Une erreur c'est produite lors du check, veuillez actualiser la page et reprendre. <br/>";

                        }
                    )
                    .finally(() => this.$store.dispatch('lancerchargement',false))











            },






            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }







        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>
    .modalstyle{
        max-width: 90%!important;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .form-control {
            width: 100%;
            max-width: 50%!important;

        }


    }





</style>