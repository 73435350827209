<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">
                        <div class="box-header justify-between">
                            <div class="box-title">
                                {{titlemodal}}
                            </div>






                        </div>


                        <div class="box-body !p-0">

                            <div class="grid lg:grid-cols-2 gap-6">


                                <div class="space-y-2">
                                    <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Impression en</label>
                                    <select v-model="taillemodel" class="form-control mb-3">
                                        <option value="A4">A4</option>
                                        <option value="A4landscape">A4 landscape</option>
                                        <option value="A5">A5</option>
                                        <option value="A5landscape">A5 landscape</option>
                                    </select>
                                </div>
                                <div class="space-y-2">
                                    <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Rechercher</label>
                                    <input v-model="searchword" class="form-control form-control-lg" type="text"
                                           placeholder="Rechercher"
                                           aria-label=".form-control-lg example">
                                </div>

                                <button v-if="!viewall" @click="print4" class="ti-btn ti-btn-success-full"> Imprimer</button>


                            </div>

                            <div v-if="viewall" class="grid lg:grid-cols-2 gap-6">


                                <div class="space-y-2">
                                    <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Mois</label>
                                    <select v-model="mmois" id="inputState123" class="form-select !py-[0.59rem]">
                                        <option disabled value="">Veuillez sélectionner un mois</option>
                                        <option value="01">Janvier</option>
                                        <option value="02">Février</option>
                                        <option value="03">Mars</option>
                                        <option value="04">Avril</option>
                                        <option value="05">Mai</option>
                                        <option value="06">Juin</option>
                                        <option value="07">Juillet</option>
                                        <option value="08">Août</option>
                                        <option value="09">Septembre</option>
                                        <option value="10">Octobre</option>
                                        <option value="11">Novembre</option>
                                        <option value="12">Décembre</option>
                                    </select>
                                    <span class="BanqueError text-red-500 text-xs hidden">error</span>
                                </div>

                                <div class="space-y-2">
                                    <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Année</label>
                                    <select v-model="manne" id="inputState1254" class="form-select !py-[0.59rem]">
                                        <option disabled value="">Veuillez sélectionner une année</option>
                                        <option v-for="(year,index) in mgetyear" :key="index" :value="year">{{year}}</option>

                                    </select>
                                    <span class="BanqueError text-red-500 text-xs hidden">error</span>
                                </div>


                                    <button @click="refresh" class="ti-btn ti-btn-primary-full"> Actualiser</button>
                                    <button @click="print4" class="ti-btn ti-btn-success-full"> Imprimer</button>



                            </div>

                            <div class="table-responsive">

                                        <template v-for="(fichedepaie,index) in filterdatatable" :key="index">


                                            <div  class="my-5 page pagebreak"  >


                                                <div class="p-5"  >
                                                    <section class="top-content mb-5 bb d-flex justify-content-between">

                                                        <div class="grid lg:grid-cols-2 gap-6">

                                                        <div class="logo">
                                                            <img style="float: inline-start;"  v-if="$store.state.etatlogo" :src="$store.state.etatlogo" alt="" class="img-fluid">
                                                        </div>
                                                        <div class="top-left">
                                                            <div class="logo">
                                                                <img style="float: inline-end;" :src="'https://chart.googleapis.com/chart?cht=qr&chl=hpaie&chs=100x100&chld=L|0'" alt="" class="img-fluid">
                                                            </div>
                                                        </div>
                                                        </div>

                                                    </section>

                                                    <section style="/*margin-top: 1rem!important;height: 200px;overflow: hidden*/" class="mt-5">
                                                        <div class="grid lg:grid-cols-2 gap-6">

                                                            <div class="col-6">
                                                                <table class="table table-bordered">
                                                                    <tbody >

                                                                    <tr>
                                                                        <td >Raison Social : {{$store.state.etatraisonsocial}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td >Ifu : {{$store.state.etatifu}} /  RC N° RCCM: {{$store.state.etatrccm}} </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td >Téléphone : {{$store.state.etattelephone}} / {{$store.state.etatadresse}}</td>
                                                                    </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div class="col-6">
                                                                <table class="table table-bordered w-full">
                                                                    <tbody >

                                                                    <tr>
                                                                        <td colspan="2" class="text-center" ><strong>{{fichedepaie.titlefiche}}</strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="2" >Nom: {{fichedepaie.nomprenom}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  STYLE="overflow: hidden" >Ifu: {{fichedepaie.ifu}}</td>
                                                                        <td  STYLE="overflow: hidden" >N°CNSS: {{fichedepaie.cnss}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  STYLE="overflow: hidden" >Service: {{fichedepaie.service}}</td>
                                                                        <td  STYLE="overflow: hidden" >Fonction: {{fichedepaie.fonction}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td STYLE="overflow: hidden"  >Banque: {{fichedepaie.banque}}</td>
                                                                        <td STYLE="overflow: hidden"  >N° compte: {{fichedepaie.numerodecompte}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td  STYLE="overflow: hidden" colspan="2">Mode de paiement: {{fichedepaie.modedepaiement}}</td>
                                                                    </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        </div>

                                                    </section>
                                                    <section style="   /* height: 600px;overflow: hidden*/" class="product-area mt-3">
                                                        <table style="/*    height: 550px;*/" class="table table-bordered w-full">
                                                            <thead>
                                                            <tr>
                                                                <th >Rubrique</th>
                                                                <th>Base</th>
                                                                <th >Taux</th>
                                                                <th>Retenue</th>
                                                                <th>Gain</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                <td>Base catégorielle</td>
                                                                <td style="text-align: end;"> {{fichedepaie.basecategorielle}}</td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Salaire de base</td>
                                                                <td style="text-align: end;"> {{fichedepaie.salairedebase}}</td>
                                                                <td style="text-align: end;">{{returnFloatconvert(fichedepaie.jourtravaille_taux)}}</td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;">{{fichedepaie.salairedebaseok}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Heure supplémentaire</td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;">{{fichedepaie.heuresupplementaire}}</td>
                                                            </tr>
                                                            <tr v-if="fichedepaie.primedhabillement!==0">
                                                                <td>Primes d'habillement</td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;">{{fichedepaie.primedhabillement}}</td>
                                                            </tr>
                                                            <tr v-if="fichedepaie.primedelogement!==0">
                                                                <td>Primes de logement</td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;">{{fichedepaie.primedelogement}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Primes de déplacement</td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;">{{fichedepaie.primededeplecement}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Primes de communication</td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;">{{fichedepaie.primedecommunication}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Primes de responsabilité</td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;">{{fichedepaie.primederesponsabilite}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Primes de risque</td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;">{{fichedepaie.primederisque}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Primes de salissure</td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;">{{fichedepaie.primedesalissure}}</td>
                                                            </tr>
                                                            <tr v-if="fichedepaie.congespaye!==0">
                                                                <td>Congés payés</td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;">{{fichedepaie.congespaye}}</td>
                                                            </tr>
                                                            <tr v-if="fichedepaie.rapple!==0">
                                                                <td>Rappel</td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;">{{fichedepaie.rapple}}</td>
                                                            </tr>
                                                            <tr v-if="fichedepaie.autreprimesgratification!==0">
                                                                <td style="text-align: end;">Autres Primes et gratifications</td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;">{{fichedepaie.autreprimesgratification}}</td>
                                                            </tr>
                                                            <!--tr>
                                                                <td>Autres Primes et gratifications</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td>160737</td>
                                                            </tr-->
                                                            <tr>
                                                                <td><strong>SALAIRE BRUT</strong></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;">{{fichedepaie.salairebrut}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>C.N.S.S</td>
                                                                <td style="text-align: end;">{{fichedepaie.salairebrut}}</td>
                                                                <td style="text-align: end;">{{fichedepaie.tauxcnss}}</td>
                                                                <td style="text-align: end;">{{fichedepaie.montantcnss}}</td>
                                                                <td style="text-align: end;"></td>
                                                            </tr>

                                                            <tr>
                                                                <td>I.T.S</td>
                                                                <td style="text-align: end;">{{fichedepaie.salairebrut_its}}</td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;">{{fichedepaie.montantits}}</td>
                                                                <td style="text-align: end;"></td>
                                                            </tr>

                                                            <tr>
                                                                <td>Avance sur salaire</td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;">{{fichedepaie.montantavancesursalaire}}</td>
                                                                <td style="text-align: end;"></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Mensualité de prêt</td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;"></td>
                                                                <td style="text-align: end;">{{fichedepaie.mensualitepret}}</td>
                                                                <td style="text-align: end;"></td>
                                                            </tr>
                                                            <!--tr>
                                                                <td>Autre retenu</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td>5786</td>
                                                                <td></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Retenu sur retard</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td>5786</td>
                                                                <td></td>
                                                            </tr-->
                                                            <tr>
                                                                <td class="text-center" colspan="4"><strong>Net à payer</strong></td>

                                                                <td style="text-align: end;">{{fichedepaie.netapayer}}</td>
                                                            </tr>

                                                            </tbody>


                                                        </table>
                                                    </section>

                                                    <section class="balance-info">
                                                        <div class="row">

                                                        </div>
                                                    </section>
                                                    <section class="balance-info mt-3">
                                                        <div class="grid lg:grid-cols-2 gap-6">


                                                            <div class="col-md-6">
                                                                <p style="text-align: center;"  class="m-0 font-weight-bold"> Employé </p>
                                                                <br>
                                                                <br>
                                                                <br>
                                                                <p style="text-align: center;" >{{fichedepaie.nomprenom}}</p>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <p style="text-align: center;"  class="m-0 font-weight-bold"> Responsable RH </p>
                                                                <br>
                                                                <br>
                                                                <br>
                                                                <p style="text-align: center;" ></p>
                                                            </div>



                                                        </div>
                                                    </section>


                                                    <!--footer>
                                                        <hr>
                                                        <p class="m-0 text-center">
                                                            RC N° RCCM:RB/COT/13-A18181 IFU N°:1201301296109 Tél: (229) XX XX XX XX / XX XX XX XX Cotonou Rép du BENIN
                                                        </p>

                                                    </footer-->
                                                </div>




                                            </div>



                                        </template>

                            </div>


                        </div>





                        <div class="flex flex-col box-footer border-t-0 ">
                            <div class="flex items-center flex-wrap overflow-auto">
                                <div class="mb-2 sm:mb-0">

                                </div>
                                <div class="ms-auto">


                                    <nav aria-label="Page navigation">
                                        <ul class="ti-pagination mb-0">
                                            <li class="page-item">
                                                <button :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]" @click="page--">Previous</button>
                                            </li>
                                            <li class="page-item">
                                                <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                        :key="key" v-for="(pageNumber, key) in displayedPages" @click="page = pageNumber">
                                                    {{ pageNumber }}
                                                </button>
                                            </li>
                                            <li class="page-item">
                                                <button :class="{ 'disabled': page >= pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++">Next</button>
                                            </li>
                                        </ul>
                                    </nav>





                                </div>
                            </div>
                        </div>
                    </div>






                </div>
                <div class="ti-modal-footer">

                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



    <div class="row" v-show="false">



        <div id="invoice">
            <page id="printOrder-data"  class="my-5 page printOrder-data " :size="taillemodel">

                <template v-for="(fichedepaie,index) in filterdatatable" :key="index">


                    <div   class="my-5 page printOrder-data pagebreak"  >


                        <div class="p-5" style="/*height: 824px*/">
                            <section class="top-content mb-5 bb d-flex justify-content-between">
                                <div class="logo">
                                    <img v-if="$store.state.etatlogo" :src="$store.state.etatlogo" alt="" class="img-fluid">
                                </div>
                                <div class="top-left">
                                    <div class="logo">
                                        <img :src="'https://chart.googleapis.com/chart?cht=qr&chl=hpaie&chs=100x100&chld=L|0'" alt="" class="img-fluid">
                                    </div>
                                </div>
                            </section>

                            <section style="/*margin-top: 1rem!important;height: 200px;overflow: hidden*/" class="mt-5">
                                <div class="row">

                                    <div class="col-6">
                                        <table class="table table-bordered">
                                            <tbody >

                                            <tr>
                                                <td >Raison Social : {{$store.state.etatraisonsocial}}</td>
                                            </tr>
                                            <tr>
                                                <td >Ifu : {{$store.state.etatifu}} /  RC N° RCCM: {{$store.state.etatrccm}} </td>
                                            </tr>
                                            <tr>
                                                <td >Téléphone : {{$store.state.etattelephone}} / {{$store.state.etatadresse}}</td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="col-6">
                                        <table class="table table-bordered">
                                            <tbody >

                                            <tr>
                                                <td colspan="2" class="text-center" ><strong>{{fichedepaie.titlefiche}}</strong></td>
                                            </tr>
                                            <tr>
                                                <td colspan="2" >Nom: {{fichedepaie.nomprenom}}</td>
                                            </tr>
                                            <tr>
                                                <td  STYLE="overflow: hidden" >Ifu: {{fichedepaie.ifu}}</td>
                                                <td  STYLE="overflow: hidden" >N°CNSS: {{fichedepaie.cnss}}</td>
                                            </tr>
                                            <tr>
                                                <td  STYLE="overflow: hidden" >Service: {{fichedepaie.service}}</td>
                                                <td  STYLE="overflow: hidden" >Fonction: {{fichedepaie.fonction}}</td>
                                            </tr>
                                            <tr>
                                                <td STYLE="overflow: hidden"  >Banque: {{fichedepaie.banque}}</td>
                                                <td STYLE="overflow: hidden"  >N° compte: {{fichedepaie.numerodecompte}}</td>
                                            </tr>
                                            <tr>
                                                <td  STYLE="overflow: hidden" colspan="2">Mode de paiement: {{fichedepaie.modedepaiement}}</td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                            </section>
                            <section style="/*    height: 600px;overflow: hidden*/" class="product-area mt-3">
                                <table style="    height: 550px;" class="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th >Rubrique</th>
                                        <th>Base</th>
                                        <th >Taux</th>
                                        <th>Retenue</th>
                                        <th>Gain</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Base catégorielle</td>
                                        <td style="text-align: end;"> {{fichedepaie.basecategorielle}}</td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                    </tr>
                                    <tr>
                                        <td>Salaire de base</td>
                                        <td style="text-align: end;"> {{fichedepaie.salairedebase}}</td>
                                        <td style="text-align: end;">{{returnFloatconvert(fichedepaie.jourtravaille_taux)}}</td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;">{{fichedepaie.salairedebaseok}}</td>
                                    </tr>
                                    <tr>
                                        <td>Heure supplémentaire</td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;">{{fichedepaie.heuresupplementaire}}</td>
                                    </tr>
                                    <tr v-if="fichedepaie.primedhabillement!==0">
                                        <td>Primes d'habillement</td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;">{{fichedepaie.primedhabillement}}</td>
                                    </tr>
                                    <tr v-if="fichedepaie.primedelogement!==0">
                                        <td>Primes de logement</td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;">{{fichedepaie.primedelogement}}</td>
                                    </tr>
                                    <tr>
                                        <td>Primes de déplacement</td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;">{{fichedepaie.primededeplecement}}</td>
                                    </tr>
                                    <tr>
                                        <td>Primes de communication</td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;">{{fichedepaie.primedecommunication}}</td>
                                    </tr>
                                    <tr>
                                        <td>Primes de responsabilité</td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;">{{fichedepaie.primederesponsabilite}}</td>
                                    </tr>
                                    <tr>
                                        <td>Primes de risque</td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;">{{fichedepaie.primederisque}}</td>
                                    </tr>
                                    <tr>
                                        <td>Primes de salissure</td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;">{{fichedepaie.primedesalissure}}</td>
                                    </tr>
                                    <tr v-if="fichedepaie.congespaye!==0">
                                        <td>Congés payés</td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;">{{fichedepaie.congespaye}}</td>
                                    </tr>
                                    <tr v-if="fichedepaie.rapple!==0">
                                        <td>Rappel</td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;">{{fichedepaie.rapple}}</td>
                                    </tr>
                                    <tr v-if="fichedepaie.autreprimesgratification!==0">
                                        <td style="text-align: end;">Autres Primes et gratifications</td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;">{{fichedepaie.autreprimesgratification}}</td>
                                    </tr>
                                    <!--tr>
                                        <td>Autres Primes et gratifications</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>160737</td>
                                    </tr-->
                                    <tr>
                                        <td><strong>SALAIRE BRUT</strong></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;">{{fichedepaie.salairebrut}}</td>
                                    </tr>
                                    <tr>
                                        <td>C.N.S.S</td>
                                        <td style="text-align: end;">{{fichedepaie.salairebrut}}</td>
                                        <td style="text-align: end;">{{fichedepaie.tauxcnss}}</td>
                                        <td style="text-align: end;">{{fichedepaie.montantcnss}}</td>
                                        <td style="text-align: end;"></td>
                                    </tr>

                                    <tr>
                                        <td>I.T.S</td>
                                        <td style="text-align: end;">{{fichedepaie.salairebrut_its}}</td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;">{{fichedepaie.montantits}}</td>
                                        <td style="text-align: end;"></td>
                                    </tr>

                                    <tr>
                                        <td>Avance sur salaire</td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;">{{fichedepaie.montantavancesursalaire}}</td>
                                        <td style="text-align: end;"></td>
                                    </tr>
                                    <tr>
                                        <td>Mensualité de prêt</td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;"></td>
                                        <td style="text-align: end;">{{fichedepaie.mensualitepret}}</td>
                                        <td style="text-align: end;"></td>
                                    </tr>
                                    <!--tr>
                                        <td>Autre retenu</td>
                                        <td></td>
                                        <td></td>
                                        <td>5786</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Retenu sur retard</td>
                                        <td></td>
                                        <td></td>
                                        <td>5786</td>
                                        <td></td>
                                    </tr-->
                                    <tr>
                                        <td class="text-center" colspan="4"><strong>Net à payer</strong></td>

                                        <td style="text-align: end;">{{fichedepaie.netapayer}}</td>
                                    </tr>

                                    </tbody>


                                </table>
                            </section>

                            <section class="balance-info">
                                <div class="row">
                                    <!--div class="col-12">

                                        <p class="text-center mt-1">Arrêté la présente Facture proforma à la somme de : un million soixante-quatre
                                            mille (1.064.000) Francs CFA.</p>
                                    </div>
                                    <div class="col-12">
                                        <ul style="    list-style-type: disclosure-closed;font-weight: 700;">
                                            <li>Avance (60%) : 638 400 FCFA à la commande</li>
                                            <li>Solde (425 600 FCFA) : 7 jours après dépôt de la facture.</li>
                                        </ul>


                                    </div-->
                                </div>
                            </section>
                            <section class="balance-info mt-3">
                                <div class="row">


                                    <div class="col-md-6">
                                        <p style="text-align: center;"  class="m-0 font-weight-bold"> Employé </p>
                                        <br>
                                        <br>
                                        <br>
                                        <p style="text-align: center;" >{{fichedepaie.nomprenom}}</p>
                                    </div>
                                    <div class="col-md-6">
                                        <p style="text-align: center;"  class="m-0 font-weight-bold"> Responsable RH </p>
                                        <br>
                                        <br>
                                        <br>
                                        <p style="text-align: center;" ></p>
                                    </div>



                                </div>
                            </section>


                            <!--footer>
                                <hr>
                                <p class="m-0 text-center">
                                    RC N° RCCM:RB/COT/13-A18181 IFU N°:1201301296109 Tél: (229) XX XX XX XX / XX XX XX XX Cotonou Rép du BENIN
                                </p>

                            </footer-->
                        </div>




                    </div>


                    <!--/div-->
                    <!--/div-->

                    <!--/div-->

                </template>


            </page>




        </div>




    </div>






</template>

<script>
    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import { getYear, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },
            searchtrierletableau: function(newvalue,oldvalue){

                this.page=1

            }

        },
        computed:{

            viewall(){
                if (this.mmois==="" || this.manne===""){

                    return true

                }else{

                    return false

                }


            },


            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                let mvalue=this.datatable;

                //this.page=this.Defaultpage;
                this.perPage=this.DefaultperPage;

               /* if (this.searchtrierletableau!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {

                        return   post.etatcontrat===parseFloat(t.searchtrierletableau)

                    })

                }*/


                if (this.searchword!==''){
                    let t=this;


                    mvalue = mvalue.filter( function (post) {
                        return    post.nomprenom.toLowerCase().includes(t.searchword.toLowerCase())
                        /*||
                        post.nameclient.toLowerCase().includes(t.searchword.toLowerCase()) ||
                        post.numbon.toLowerCase().includes(t.searchword.toLowerCase())*/
                    })

                }



               // return this.paginate(mvalue) ;
                return mvalue ;
            },
        },
        components: {
            templatesite,
            appheader,
            appsidebar,
            /*appmenu,*/

        },

        data() {
            return {
                mmois:"",
                manne:"",
                mfontsize:10,
                taillemodel:'A4',
                mgetyear:getYear(),
                isdisplay:false,
                titlemodal:"",
                datatable:[],
                typbulletin:"",
                messageerror:"",
                messagesucces:"",





                searchword:"",
                searchtrierletableau:"",

                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 1,
                Defaultpage: 1,
                DefaultperPage: 1,
                pages: [],
            };
        },
        methods: {


           async print4 () {

                let t=this

                let options = {  name: '_blank', }


                if (this.taillemodel==="A4landscape" ){

                    options = {
                        name: '_blank',
                        specs: [
                            'fullscreen=yes',
                            'titlebar=yes',
                            'scrollbars=yes'
                        ],
                        styles: [
                            process.env.VUE_APP_URL+'print/bootstrap.min.css',
                            process.env.VUE_APP_URL+'print/stylelandscape1.css',
                            process.env.VUE_APP_URL+'print/stylelandscape.css'
                        ],


                        replace: true,
                        // timeout: 1000, // default timeout before the print window appears
                        autoClose: false, // if false, the window will not close after printing
                        windowTitle: window.document.title, // override the window title




                    }
                }

                if (this.taillemodel==="A4" ){

                    options = {
                        name: '_blank',
                        specs: [
                            'fullscreen=yes',
                            'titlebar=yes',
                            'scrollbars=yes'
                        ],
                        styles: [
                            process.env.VUE_APP_URL+'print/bootstrap.min.css',
                            process.env.VUE_APP_URL+'print/styleportrait.css',
                            process.env.VUE_APP_URL+'print/styleportraisizebulletin.css'
                        ],


                        replace: true,
                        // timeout: 1000, // default timeout before the print window appears
                        autoClose: false, // if false, the window will not close after printing
                        windowTitle: window.document.title, // override the window title




                    }
                }


                 this.$htmlToPaper('printOrder-data', options, () => {
                    console.log('Printing completed or was cancelled!');
                });

            },




            returnFloatconvert(value){

                //console.log('value ',value)

                let mval= parseFloat(value)

                return mval.toFixed(4)
            },

            close(){

                this.isdisplay=false;

                //this.$emit('closed');

            },

            selected(datavalue){


                // Suppose que vous avez un objet représentant l'élément sélectionné
                const elementSelectionne = { typ: this.typedoc, value: datavalue};

                // Émettre un événement personnalisé avec l'élément sélectionné
                this.$emit('elementSelectionne', elementSelectionne);

                // Fermer le modal ou effectuer d'autres actions nécessaires
                this.isdisplay=false;
            },


            checkZero(i)
            {
                if (i < 10)
                {
                    i = "0" + i
                };  // add zero in front of numbers < 10

                return i;
            },

           async show(typbulletin,mmois,manne){

                this.typbulletin=typbulletin
                this.mmois=this.checkZero(mmois)
                this.manne=manne

                this.titlemodal="Bulletin de paie"


                this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74dkjskdj5874edition771714dfe/paie/'+this.$store.state.appsociete+'/'+this.mmois+'/'+manne+'/'+typbulletin+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })
               /* let response =   axios  .get(url,{__p:typbulletin,__d:Itemdatas,__tt:typbulletin},{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })*/

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)
                        t.datatable=[];

                        if(res.data.success){

                            t.datatable=res.data.m;

                            t.isdisplay=true;

                        }
                        else{
                            t.datatable=[];
                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })













            },




           async refresh(){

                /*this.typbulletin=typbulletin
                this.mmois=this.checkZero(mmois)
                this.manne=manne*/

              //  this.titlemodal="Bulletin de paie"


                this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74dkjskdj5874edition771714dfe/paie/'+this.$store.state.appsociete+'/'+this.mmois+'/'+this.manne+'/'+this.typbulletin+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)
                        t.datatable=[];

                        if(res.data.success){

                            t.datatable=res.data.m;

                            t.isdisplay=true;

                        }
                        else{
                            t.datatable=[];
                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })













            },






            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>

   .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 90%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>