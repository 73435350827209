<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="box-body">
                        <div class="border-b-0 border-gray-200 dark:border-white/10">
                            <nav class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" aria-label="Tabs">
                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 active"
                                        id="hs-tab-js-behavior-item-1" data-hs-tab="#hs-tab-js-behavior-1" aria-controls="hs-tab-js-behavior-1">
                                    -
                                </button>



                            </nav>
                        </div>

                        <div class="">
                            <div id="hs-tab-js-behavior-1" role="tabpanel" aria-labelledby="hs-tab-js-behavior-item-1" class="">
                                <div class="ti-custom-validation mt-5" >

                                    <div class="grid grid-cols-12 gap-x-6">



                                        <div class="mb-4  xl:col-span-4 col-span-12">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">DATE</label>

                                            <div style="padding: 0px;padding-left: 10px" class="input-group-text text-[#8c9097] dark:text-white/50">
                                                <i style="padding: 5px;" class="ri-calendar-line"></i>
                                                <input type="date"  class="my-auto ti-form-input  rounded-sm"
                                                       placeholder="DATE"  >
                                            </div>
                                        </div>


                                        <div class="mb-4  xl:col-span-4 col-span-12">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">MOTIF</label>
                                            <input id="message"  class="block  my-auto ti-form-input  rounded-sm"
                                                      placeholder="MOTIF...">
                                        </div>



                                        <div class="mb-4  xl:col-span-4 col-span-12">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">LISTE DES TÂCHES</label>
                                            <select id="inputState12" class="form-select !py-[0.59rem]">
                                                <option selected="">State</option>
                                                <option>...</option>
                                            </select>
                                        </div>

                                        <div class="mb-4  xl:col-span-12 col-span-12">
                                            <label for="small-file-input" class="sr-only">Choose file</label>
                                            <input type="file" name="small-file-input" id="small-file-input" class="block w-full border border-gray-200 focus:shadow-sm dark:focus:shadow-white/10 rounded-sm text-sm focus:z-10 focus:outline-0 focus:border-gray-200 dark:focus:border-white/10 dark:border-white/10 dark:text-white/50
                                       file:border-0
                                      file:bg-light file:me-4
                                      file:py-2 file:px-4
                                      dark:file:bg-black/20 dark:file:text-white/50">
                                        </div>

                                    </div>




                                    <!-- Start::row-1 -->
                                    <div class="w-full grid grid-cols-12">
                                        <div class="xl:col-span-12 col-span-12">
                                            <div class="box  overflow-hidden">
                                                <div class="box-header justify-between">
                                                    <div class="box-title">

                                                    </div>
                                                    <div class="flex flex-wrap gap-2">


                                                        <!--button @click="btnajouter" class="ti-btn ti-btn-primary-full !py-1 !px-2 !text-[0.75rem]">
                                                            <i class="ri-add-line  align-middle"></i>Ajouter
                                                        </button-->



                                                        <!--div class="hs-dropdown ti-dropdown">
                                                            <a href="javascript:void(0);" class="ti-btn ti-btn-primary-full !py-1 !px-2 !text-[0.75rem]"
                                                               aria-expanded="false">
                                                                Trier par <i class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                                                            </a>
                                                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden" role="menu">
                                                                <li><a class="ti-dropdown-item" href="javascript:void(0);">Posted Date</a></li>
                                                                <li><a class="ti-dropdown-item" href="javascript:void(0);">Status</a></li>
                                                            </ul>
                                                        </div-->


                                                    </div>
                                                    <div class="w-full  grid grid-cols-2">


                                                        <div class="mb-2 sm:mb-0">
                                                            <div data-v-2f5924ee="" style="margin-top: 10px;"><button data-v-2f5924ee="" style="background-color: greenyellow;">&nbsp;</button> <span data-v-2f5924ee="" style="font-weight: bold; color: black;">Personnel verifier</span></div>
                                                            <div data-v-2f5924ee="" style="margin-top: 10px;"><button data-v-2f5924ee="" style="background-color: red;">&nbsp;</button> <span data-v-2f5924ee="" style="font-weight: bold; color: black;">Personnel non verifier</span></div>

                                                        </div>


                                                        <div>
                                                            <input v-model="searchword" class="form-control form-control-lg" type="text"
                                                                   placeholder="Rechercher"
                                                                   aria-label=".form-control-lg example">
                                                        </div>

                                                    </div>
                                                </div>


                                                <div class="box-body !p-0">
                                                    <div class="table-responsive">

                                                        <table class="table table-hover whitespace-nowrap min-w-full">
                                                            <thead>

                                                             <tr>
                                                                <th scope="col" class="text-start">N°</th>
                                                                <th scope="col" class="text-start">CODE</th>
                                                                <th scope="col" class="text-start">NOM ET PRENOM(S)</th>
                                                                <th scope="col" class="text-start">CODE TÂCHE</th>
                                                                <th scope="col" class="text-start">TÂCHE</th>
                                                                <th scope="col" class="text-start">HONORAIRE</th>
                                                                <th scope="col" class="text-start">QUANTITE</th>
                                                                <th scope="col" class="text-start">MONTANT HONORAIRE</th>
                                                                <th scope="col" class="text-start">DATE</th>
                                                                <th scope="col" class="text-start">SAISIR PAR</th>
                                                                <th scope="col" class="text-start">CREER LE</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>


                                                                <tr >
                                                                    <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                                </tr>



                                                            </tbody>


                                                            <tfoot>
                                                            <tr>
                                                                <td colspan="20">
                                                                    <button class="w-full ti-btn ti-btn-secondary-full ti-btn-wave"> <i class="bi bi-upload"></i> Importer le fichier (personnel vérifier)</button>
                                                                </td>
                                                            </tr>
                                                            </tfoot>

                                                        </table>




                                                        <table class="table table-hover whitespace-nowrap min-w-full">
                                                            <thead>

                                                             <tr>
                                                                <th scope="col" class="text-start">N°</th>
                                                                <th scope="col" class="text-start">CODE</th>
                                                                <th scope="col" class="text-start">NOM ET PRENOM(S)</th>
                                                                <th scope="col" class="text-start">ACTION</th>

                                                            </tr>
                                                            </thead>
                                                            <tbody>


                                                                <tr >
                                                                    <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                                </tr>



                                                            </tbody>

                                                            <tfoot>
                                                            <tr>
                                                                <td colspan="10">
                                                                    <button class="w-full ti-btn ti-btn-secondary-full ti-btn-wave"> <i class="bi bi-save2"></i> Lancer la création du personnel non vérifier</button>
                                                                </td>
                                                            </tr>
                                                            </tfoot>


                                                        </table>
















                                                    </div>
                                                </div>





                                                <div class="box-footer border-t-0">
                                                    <div class="flex items-center flex-wrap overflow-auto">
                                                        <div class="mb-2 sm:mb-0">

                                                        </div>
                                                        <div class="ms-auto">


                                                            <!--nav aria-label="Page navigation">
                                                                <ul class="ti-pagination  mb-0">
                                                                    <li class="page-item "><button  :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]"  @click="page--">Previous</button></li>
                                                                    <li class="page-item">

                                                                        <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                                                :key="key" v-for="(pageNumber,key) in pages.slice(page-1, page+5)"
                                                                                @click="page = pageNumber">{{pageNumber}}</button>

                                                                    </li>
                                                                    <li class="page-item" ><button :class="{ 'disabled': page => pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++" >Next</button></li>
                                                                </ul>
                                                            </nav-->



                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--End::row-1 -->















                                </div>
                            </div>



                        </div>
                    </div>




                </div>
                <div class="ti-modal-footer">
                    <button style="background: red" @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" >
                        Fermer
                    </button>
                    <button style="background: green" v-if="typeaction==='add'" class="ti-btn ti-btn-primary-full" href="javascript:void(0);">
                       Enregistrer
                    </button>
                    <button style="background: green" v-if="typeaction==='update'" class="ti-btn ti-btn-primary-full" href="javascript:void(0);">
                       Modifier
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



</template>

<script>
    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/definition/menu";

    export default {
        /* eslint-disable */
        name: "modalform",
        watch: {


        },

        computed:{




        },
        components: {
            templatesite,
            appheader,
            appsidebar,
            appmenu,

        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",
                searchword:"",
                typedoc:"",
                typeaction:"add",
            };
        },
        methods: {

            close(){

                this.isdisplay=false;

                this.$emit('closed');

            },

            show(titlemodal,typeaction,typedoc){

                this.titlemodal=titlemodal
                this.typeaction=typeaction
                this.typedoc=typedoc

                this.isdisplay=true;

            },





        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>
    .modalstyle{
        max-width: 90%!important;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


    }





</style>