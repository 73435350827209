<template>
    <div class="col-span-12">
        <div class="box">
            <div class="box-header">
                <h5 class="box-title">---</h5>
            </div>
            <div class="box-body">
                <header class="flex flex-wrap sm:justify-start sm:flex-nowrap z-40 w-full bg-white text-sm dark:bg-bodybg">
                    <nav class="w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between" aria-label="Global">
                        <div class="flex items-center justify-between">
                            <router-link :to="{name: 'trapport'}" class="header-logo">
                                Menu
                            </router-link>
                            <div class="sm:hidden">
                                <button type="button" class="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-2 rounded-sm border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-offset-white focus:ring-primary transition-all text-sm dark:bg-bodybg dark:hover:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-white dark:focus:ring-offset-white/10"
                                        data-hs-collapse="#navbar-with-collapse" aria-controls="navbar-with-collapse" aria-label="Toggle navigation">
                                    <svg class="hs-collapse-open:hidden w-4 h-4" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
                                    </svg>
                                    <svg class="hs-collapse-open:block hidden w-4 h-4" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div id="navbar-with-collapse" class="hidden basis-full grow sm:block">
                            <div class="flex flex-col gap-5 mt-5 sm:flex-row sm:items-center sm:justify-end sm:mt-0 sm:ps-5">
                                <router-link class="font-medium text-primary"  :to="{name: 'tbpresence'}" aria-current="page">Menu</router-link>
                                <button @click="btnmodalindexabsenceetconge" class="font-medium text-primary" title="Absences Justifié / Congés"
                                        aria-current="page">Bulletin de salaire</button>

                                <div class="hs-dropdown ti-dropdown">
                                    <a class="ti-btn ti-dropdown-toggle font-medium text-primary"
                                       href="javascript:void(0);"
                                       aria-expanded="false">
                                        Etat sur la paie<i class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                                    </a>
                                    <ul class="hs-dropdown-menu ti-dropdown-menu hidden" aria-labelledby="dropdownMenuLink">

                                        <li><button class="ti-dropdown-item" @click="btnmodalindexpointagedepresencemachine" title="Livre de paie détaillé">Livre de paie détaillé</button></li>
                                        <li><hr class="dropdown-divider"> </li>
                                        <li><button class="ti-dropdown-item" @click="btnmodalimportationdesheuresdepointages" title="Livre de paie cummulé">Livre de paie cummulé</button></li>
                                        <li><hr class="dropdown-divider"> </li>
                                        <li><button class="ti-dropdown-item" @click="btnmodalvoirrapportdepointage" title="Etat des paiements">Etat des paiements</button></li>
                                        <li><hr class="dropdown-divider"> </li>
                                        <li><button class="ti-dropdown-item" @click="btnmodalvoirrapportdepointage" title="Ordre de virement">Ordre de virement</button></li>
                                         <li><hr class="dropdown-divider"> </li>
                                        <li><button class="ti-dropdown-item" @click="btnmodalvoirrapportdepointage" title="Ordre de virement avec chèque joint">Ordre de virement avec chèque joint <span style="color: red">(Bientôt disponible)</span></button></li>
                                        <li><hr class="dropdown-divider"> </li>
                                        <li><button class="ti-dropdown-item" @click="btnmodalvoirrapportdepointage" title="Ordre de virement par débit de notre compte">Ordre de virement par débit de notre compte <span style="color: red">(Bientôt disponible)</span></button></li>

                                    </ul>
                                </div>


                                <div class="hs-dropdown ti-dropdown">
                                    <a class="ti-btn ti-dropdown-toggle font-medium text-primary"
                                       href="javascript:void(0);"
                                       aria-expanded="false">
                                        Déclaration C.N.S.S,I.T.S,...<i class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                                    </a>
                                    <ul class="hs-dropdown-menu ti-dropdown-menu hidden" aria-labelledby="dropdownMenuLink">

                                        <li><button class="ti-dropdown-item" @click="btnmodalindexpointagedepresencemachine" title="Déclaration C.N.S.S">Déclaration C.N.S.S</button></li>
                                        <li><hr class="dropdown-divider"> </li>
                                        <li><button class="ti-dropdown-item" @click="btnmodalimportationdesheuresdepointages" title="Déclaration I.T.S">Déclaration I.T.S</button></li>
                                        <li><hr class="dropdown-divider"> </li>
                                        <li><button class="ti-dropdown-item" @click="btnmodalvoirrapportdepointage" title="Fiche de déclaration C.N.S.S">Fiche de déclaration C.N.S.S <span style="color: red">(Bientôt disponible)</span></button></li>
                                        <li><hr class="dropdown-divider"> </li>
                                        <li><button class="ti-dropdown-item" @click="btnmodalvoirrapportdepointage" title="Fiche de déclaration I.T.S">Fiche de déclaration I.T.S <span style="color: red">(Bientôt disponible)</span></button></li>

                                    </ul>
                                </div>


  
                            </div>
                        </div>
                    </nav>
                </header>
            </div>
        </div>
    </div>

    <modalindexabsenceetconge ref="modalindexabsenceetconge"></modalindexabsenceetconge>
    <modalindexpointagedepresencemachine ref="modalindexpointagedepresencemachine"></modalindexpointagedepresencemachine>
    <modalimportationdesheuresdepointages ref="modalimportationdesheuresdepointages"></modalimportationdesheuresdepointages>
    <modalvoirrapportdepointage ref="modalvoirrapportdepointage"></modalvoirrapportdepointage>
    <modaldefinirplanning ref="modaldefinirplanning"></modaldefinirplanning>
    <modalexportationplanning ref="modalexportationplanning"></modalexportationplanning>
    <modalimportationplanning ref="modalimportationplanning"></modalimportationplanning>
    <modalregledepresence ref="modalregledepresence"></modalregledepresence>

</template>

<script>

    import modalindexabsenceetconge from "@/components/views/pages/presence/absenceetconges/modal_index";
    import modalindexpointagedepresencemachine from "@/components/views/pages/presence/pointagedepresencemachine/modal_index";
    import modalimportationdesheuresdepointages from "@/components/views/pages/presence/pointagedepresencemachine/modal_importation_desheuresdepointages";
    import modalvoirrapportdepointage from "@/components/views/pages/presence/pointagedepresencemachine/modal_voirrapportdepointage";
    import modaldefinirplanning from "@/components/views/pages/presence/planning/modal_definirplanning";
    import modalexportationplanning from "@/components/views/pages/presence/planning/modal_exportationplanning";
    import modalimportationplanning from "@/components/views/pages/presence/planning/modal_importationplanning";
    import modalregledepresence from "@/components/views/pages/presence/regledegestiondepresence/modal_regledepresence";

    export default {
        /* eslint-disable */
        name: "mymenu",
        components: {
            modalindexabsenceetconge,
            modalindexpointagedepresencemachine,
            modalimportationdesheuresdepointages,
            modalvoirrapportdepointage,
            modaldefinirplanning,
            modalexportationplanning,
            modalimportationplanning,
            modalregledepresence,

        },

        data() {
            return {

            };
        },
        methods: {

            btnmodalregledepresence(){

                let titlemodal="Régle de gestion des présences"

                this.$refs.modalregledepresence.show(titlemodal);
            },

            btnmodalimportationplanning(){

                let titlemodal="Importation du planning de présence"

                this.$refs.modalimportationplanning.show(titlemodal);
            },

            btnmodalexportationplanning(){

                let titlemodal="Exportation du planning de présence"

                this.$refs.modalexportationplanning.show(titlemodal);
            },

            btnmodaldefinirplanning(){

                let titlemodal="Définir le planning de présence"

                this.$refs.modaldefinirplanning.show(titlemodal);
            },

            btnmodalindexabsenceetconge(){

                let titlemodal="Absences Justifié / Congés"

                this.$refs.modalindexabsenceetconge.show(titlemodal);
            },

            btnmodalindexpointagedepresencemachine(){

                let titlemodal="Pointage de présence des pointeurs"

                this.$refs.modalindexpointagedepresencemachine.show(titlemodal);
            },

            btnmodalimportationdesheuresdepointages(){

                let titlemodal="IMPORTATION DU FICHIER CSV DE POINTAGE DE PRESENCE"

                this.$refs.modalimportationdesheuresdepointages.show(titlemodal);
            },

            btnmodalvoirrapportdepointage(){

                let titlemodal="Voir le rapport des pointages et des cummuls de temps"

                this.$refs.modalvoirrapportdepointage.show(titlemodal);
            },




        },


    }
</script>

<style scoped>

</style>