<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="box-body">
                        <div class="border-b-0 border-gray-200 dark:border-white/10">
                            <nav class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" aria-label="Tabs">
                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 active"
                                        id="hs-tab-js-behavior-item-1" data-hs-tab="#hs-tab-js-behavior-1" aria-controls="hs-tab-js-behavior-1">
                                    Information de base
                                </button>



                            </nav>
                        </div>

                        <div class="">
                            <div id="hs-tab-js-behavior-1" role="tabpanel" aria-labelledby="hs-tab-js-behavior-item-1" class="">
                                <div class="ti-custom-validation mt-5" >
                                    <div class="grid lg:grid-cols-1 gap-6">


                                        <div class="grid grid-cols-12 gap-x-6">

                                            <div class="space-y-2 xl:col-span-8 col-span-12">
                                                <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Personnel</label>
                                                <div class="grid grid-cols-12  gap-x-6">

                                                    <div class="xl:col-span-10  col-span-12" >
                                                        <input placeholder="Veuillez sélectionner un occasionnel"  v-if="Item.personnel===null"
                                                               type="text" class="form-control" disabled>
                                                        <input v-else :value="Item.personnel.name" type="text" class="form-control" disabled>
                                                    </div>
                                                    <div class="xl:col-span-2  col-span-12">
                                                        <button title="Sélectionner un occasionnel"  @click="btnmodallistedupersonnel('occasionnel')"  class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="space-y-2 xl:col-span-4 col-span-12">
                                                <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">HONORAIRE</label>
                                                <input :value="formatMontant(returnNetapyer)" type="text" min="0" class="my-auto ti-form-input text-center rounded-sm"
                                                       placeholder="NET A PAYER"   disabled>

                                            </div>


                                        </div>


                                        <div class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">MOTIF</label>
                                            <textarea id="message" rows="4" v-model="Item.motif" class="block  my-auto ti-form-input  rounded-sm"
                                                      placeholder="MOTIF..."></textarea>
                                        </div>


                                        <div   class="space-y-2">
                                            <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">MONTANT</label>
                                            <input v-model="Item.montant"  type="number" min="0" class="firstName my-auto ti-form-input  rounded-sm"
                                                   placeholder="MONTANT"   required>

                                        </div>



                                    </div>


                                </div>
                            </div>



                        </div>
                    </div>




                </div>
                <div class="ti-modal-footer">
                    <button style="background: red" @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" >
                        Fermer
                    </button>
                    <button style="background: green" v-if="typeaction==='add'" @click="saveItem" class="ti-btn ti-btn-primary-full" href="javascript:void(0);">
                        Enregistrer
                    </button>
                    <button style="background: green" v-if="typeaction==='update'" class="ti-btn ti-btn-primary-full" href="javascript:void(0);">
                        Modifier
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



    <modallistedupersonnel @elementSelectionne="handleElementSelectionne" ref="modallistedupersonnel"></modallistedupersonnel>

</template>

<script>
    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/definition/menu";
    import modallistedupersonnel from "@/components/views/pages/paie/modal/modal_liste_du_personnel";
    import {currencyFormatDE, currentDate, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');





    export default {
        /* eslint-disable */
        name: "modalform",
        watch: {


        },

        computed:{



            returnNetapyer(){

                let value=0

                if (this.Item.personnel!==null){

                    value=this.Item.personnel.netapayer
                }

                this.mmontantsalaire=value

                return value;
            }



        },
        components: {
            templatesite,
            appheader,
            appsidebar,
            appmenu,
            modallistedupersonnel,

        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",
                mmontantsalaire:0,
                typedoc:"",
                typeaction:"add",
                listedupersonnel:[],
                Item: {pid:'',personnel:null,motif:'',montant:0,tp:'',  datedebutdupret:currentDate(),datedupret:null,dureedupret:0,mensualitedupret:0 },
            };
        },
        methods: {

            formatMontant(value){
                return currencyFormatDE(value)
            },

            checkZero(i)
            {
                if (i < 10)
                {
                    i = "0" + i
                };  // add zero in front of numbers < 10

                return i;
            },

            handleElementSelectionne(elementSelectionne) {
                // Gérer l'élément sélectionné reçu de ModalComponent

                //  console.log("elementSelectionne ********** ",elementSelectionne)

                /* if (elementSelectionne.typ==="service"){
                     this.Items.jsonv.service=elementSelectionne.value
                 }

                 if (elementSelectionne.typ==="fonction"){
                     this.Items.jsonv.fonction=elementSelectionne.value
                 }

                 if (elementSelectionne.typ==="qualification"){
                     this.Items.jsonv.qualification=elementSelectionne.value
                 }*/

                this.Item.personnel=elementSelectionne.value


            },

            btnmodallistedupersonnel(typ){

                let listedatable=[],
                    titlemodal="",
                    typedoc=typ;


                if (typ==="permanent"){

                    listedatable=this.listedupersonnel
                    titlemodal="Liste du personnel permanent"
                }

                if (typ==="occasionnel"){

                    listedatable=this.listedupersonnel
                    titlemodal="Liste du personnel occasionnel"
                }

                this.$refs.modallistedupersonnel.show(titlemodal,listedatable,typedoc);

            },



            close(){

                this.isdisplay=false;

                this.$emit('closed');

            },

            show(titlemodal,typeaction,typedoc,listedupersonnel,Item){
                // console.log("listedupersonnel 11---------- ",listedupersonnel)
                this.titlemodal=titlemodal
                this.typeaction=typeaction
                this.typedoc=typedoc
                this.listedupersonnel=listedupersonnel
                this.Item=Item

                // console.log("this.listedupersonnel 11---------- ",this.listedupersonnel)


                this.isdisplay=true;

            },


            async  btnmiseajour(){

                //this.$store.dispatch('lancerchargement',true)



                let Item={}


                Item.__tp=randomString(185);

              /*  if (this.typepersonnel==='personnel'){

                    Item.datas=this.$store.state.dataspersonnel;

                }else{*/
                    Item.datas=this.$store.state.datasoccasionel;
                //}





                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74dkjskdj5874ppaie771714dfe/paie/'+this.$store.state.appsociete+'/majfichedepaie/'+randomString();

                let response =   axios  .post(url,Item,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res= await response
                    .then(function (res){
                        // t.$store.dispatch('beartoken',res.data.beartoken)
                        //t.$store.dispatch('lancerchargement',false)


                        if(res.data.success){

                           /* t.messagesucces="";
                            t.messageerror="";

                            t.messagesucces=res.data.message;*/

                           // toastr['success']( t.messagesucces,"<b></b>");


                          //  t.close()

                        }
                        else{

                            let errormessage="";

                            t.messageerror="";
                            t.messagesucces="";
                            if (res.data.datamessage.length>0){

                                for (let message of res.data.datamessage){

                                    if (errormessage===""){

                                        errormessage=message;

                                    }else{

                                        errormessage+=" , "+message;

                                    }


                                }


                                t.messageerror=errormessage;

                            }
                            else{
                                t.messageerror=res.data.message
                            }


                            toastr['error'](t.messageerror,"<b></b>");
                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                            "<b>Erreur</b>")
                    )
                    .finally(() => this.$store.dispatch('lancerchargement',false))







            },





            async saveItem(){
                this.$store.dispatch('lancerchargement',true)

                if (this.Item.personnel===null){

                    toastr['error']( 'Veuillez sélectionner un personnel',"<b></b>");
                    this.$store.dispatch('lancerchargement',false)
                    return

                }

                if (this.Item.motif===""){

                    toastr['error']( 'Veuillez saisir un motif.',"<b></b>");
                    this.$store.dispatch('lancerchargement',false)
                    return

                }




                    if (this.Item.montant===0){

                        toastr['error']( "Veuillez saisir le montant.","<b></b>");
                        this.$store.dispatch('lancerchargement',false)
                        return

                    }

                        if (this.Item.montant>=this.mmontantsalaire){

                            toastr['error']( 'Le montant de l\opération ne doit pas être supérieur ou égale au net à payer.',"<b></b>");
                            this.$store.dispatch('lancerchargement',false)
                            return

                        }








                this.Item.tp='avance_sur_salaire';
                this.Item.datas=this.$store.state.datasoccasionel;

                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74dkjskdj5874ppaie771714dfe/paie/'+this.$store.state.appsociete+'/soperation/'+this.routertype+'/'+randomString();
                let response =   axios  .post(url,this.Item,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })
                let res= await response
                    .then(function (res){
                        t.$store.dispatch('beartoken',res.data.beartoken)
                        t.$store.dispatch('lancerchargement',false)
                        if(res.data.success){


                            toastr['success']( res.data.message,"<b></b>");
                            // t.$store.dispatch('listecatalogue',res.data.lst)

                            // t.isdisplay=false

                            t.btnmiseajour()

                            t.close();

                        }else{


                            toastr['error']( res.data.message,"<b>Enregistrement</b>");
                            // t.$store.dispatch('lancerchargement',false)
                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                            "<b>Erreur</b>")
                    )
                    .finally(() => this.$store.dispatch('lancerchargement',false))





            },





        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>
    .modalstyle{
        max-width: 90%!important;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


    }





</style>