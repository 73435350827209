import moment from 'moment';
import * as xlsx from 'xlsx/xlsx.mjs';




export const ExportExcel=(type,name, fn, dl,elt) =>{

    //  let name="SheetJSTableExport"
    //var elt = this.$refs.exportable_table;
    var wb = xlsx.utils.table_to_book(elt, {sheet:"Sheet JS"});
    return dl ?
        xlsx.write(wb, {bookType:type, bookSST:true, type: 'base64'}) :
        xlsx.writeFile(wb, fn || ((name + '.') + (type || 'xlsx')));



};

export const ExportExcelMULTISheet=(type,name, fn, dl,elt) =>{

    //  let name="SheetJSTableExport"
    //var elt = this.$refs.exportable_table;
    // var wb
    // var wb = {SheetNames:[], Sheets:{}};
    // var wb = {SheetNames:[], Sheets:{}};


    // var ws = xlsx.utils.table_to_sheet(elt);
    // var ws2 = xlsx.utils.table_to_sheet(elt);
    var wb = xlsx.utils.book_new();

    // xlsx.utils.book_append_sheet(wb, ws, "Sheet 1");
    // xlsx.utils.book_append_sheet(wb, ws2, "Sheet 2");

    for (let i = 0; i < elt.length; i++) {
        xlsx.utils.book_append_sheet(wb, xlsx.utils.table_to_sheet(elt[i].melt), elt[i].name);

    }

    return dl ?
        xlsx.write(wb, {bookType:type, bookSST:true, type: 'base64'}) :
        xlsx.writeFile(wb, fn || ((name + '.') + (type || 'xlsx')));




};


export const randomString = (length=180) => {


    var chars = "0123456789abcdefghijklmnopqrstuvwxyz0123456789ABCDEFG755lkjjhjHIJKLMNOPQRSTUVWXYZ1234567890";

    var output = "";
    var x = 0;
    var i = 0;
    for(x = 0; x < length; x++) {
        i = Math.floor(Math.random() * 62);
        output += chars.charAt(i);
    }



    return output;
};


export const currentDate = () => {
    let now = moment();
    let mdate=now.format("YYYY-MM-DD");
    //let mheure=now.format("HH:mm:ss");

    // console.log(mdate_heure);
    return mdate
};

export const currencyFormatDE = num => {
    var number=Number(num);
    return (
        number
            //.toFixed(2) // always two decimal digits
            .toFixed(0) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1'+' ') + ' FCFA'
    ) // use . as a separator
};

export const FormatNumber = num => {
    var number=Number(num);
    return (
        number
            .toFixed(0) // always two decimal digits
            .replace('.', ',') // replace decimal point character with ,
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ''
    ) // use . as a separator
};




 export  const getYear1955 = () => {
   // let now = moment();
    var ladate=new Date()
    let arraydate=[];
    let str = 0;
    const startappYear = 1955;
    const currentYear = ladate.getFullYear();
    const goBackYears = currentYear-startappYear;

    for (let i = 0; i <= goBackYears; i++) {
        str = currentYear - i;

        arraydate.push(str)
    }

    return arraydate;

};

 export  const addBusinessDays=(originalDate, numDaysToAdd,isokSunday,isokSaturday) =>{
    const Sunday = 0;
    const Saturday = 6;
    let daysRemaining = numDaysToAdd;
    let clonedate=new Date(originalDate)

    //const myDate = moment(originalDate, 'yyyy-mm-dd').toDate();
    //const myDate = moment(clonedate).format('YYYY-MM-DD');
    const myDate = moment(clonedate);
    // const newDate = originalDate.clone();
    //const newDate = myDate;
    //const newDate = new Date(originalDate);

    while (daysRemaining > 0) {
        //newDate.add(1, 'days');

        myDate.add(1, 'days');

        if (myDate.day() === Sunday || myDate.day() === Saturday) {

            if (isokSunday===1){

                daysRemaining--;

            }

            if (isokSaturday===1){

                daysRemaining--;

            }

        }
        else{
            daysRemaining--;

        }




    }

    //return newDate;
    return myDate.format('YYYY-MM-DD');
};

  export const currentDateTime = () => {
    let now = moment();
    let mdate=now.format("YYYY-MM-DD");
    let mheure=now.format("HH:mm:ss");
    let mdate_heure=mdate+' à '+mheure;
    // console.log(mdate_heure);
    return mdate_heure
};


  export const formatDate_yyyy_mm_dd = (mdate) => {
    return moment(mdate).format('YYYY-MM-DD');
};

  export const formattime_hh_mm_ss = (mtime) => {
    return moment(mtime,'HHmmss').format("HH:mm:ss");
};

  export const add_day_to_date = (mdate,nbreday) => {
    return moment(mdate).add(parseInt(nbreday), 'days').format("YYYY-MM-DD");
};

 export const checkDateValidity = (mdate)  =>{

    return moment(mdate, "YYYY-MM-DD", true).isValid();

}

 export const checkTimeValidity = (mtime)  =>{

    return  moment(mtime, "HH:mm:ss", true).isValid();

}

 export  const returndifftotwodate = (mdatetimearrive,mdatetimesortie) => {

    const dateC = moment(mdatetimearrive);
    const dateB = moment(mdatetimesortie);


    let res=dateB.diff(dateC);


    return res;
};


  export const getYear2020 = () => {
    let now = moment();
    let arraydate=[];
    let str = 0;
    const startappYear = 2020;
    const currentYear = now.year();
    const goBackYears = currentYear-startappYear;

    for (let i = 0; i <= goBackYears; i++) {
        str = currentYear - i;

        arraydate.push(str)
    }

    return arraydate;

};


 export  const currentYear = () => {
    let now = moment();
    let mdate=now.format("YYYY");
    /// let mheure=now.format("HH:mm:ss");

    // console.log(mdate_heure);
    return mdate
};


 export  const currentMonth = () => {
    let now = moment();
    let mdate=now.format("MM");
    /// let mheure=now.format("HH:mm:ss");

    // console.log(mdate_heure);
    return mdate
};

 export const returnMonthbynumber=val =>{
    var answer = "";




// Only change code below this line
    switch (val) {

        case "01": answer=("Janvier");
            break;
        case "02": answer=("Février");
            break;
        case "03": answer=("Mars");
            break;
        case "04": answer=("Avril");
            break;
        case "05": answer=("Mai");
            break;
        case "06": answer=("Juin");
            break;
        case "07": answer=("Juillet");
            break;
        case "08": answer=("Août");
            break;
        case "09": answer=("Septembre");
            break;
        case "10": answer=("Octobre");
            break;
        case "11": answer=("Novembre");
            break;
        case "12": answer=("Décembre");
            break;}

// Only change code above this line
    return answer;
}


export const cloneobjet = myobjet => {

    return JSON.parse(JSON.stringify(myobjet));
}

export const isNumeric = num => {
    return !isNaN(parseFloat(num)) && isFinite(num);
};


export const getYear = () => {
    let now = moment();
    let arraydate=[];
    let str = 0;
    const startappYear = 2020;
    const currentYear = now.year();
    const goBackYears = currentYear-startappYear;

    for (let i = 0; i <= goBackYears; i++) {
        str = currentYear - i;

        arraydate.push(str)
    }

    return arraydate;

};



/*
module.exports = {

    randomString,
    currentDate
    returnMonthbynumber,
     addBusinessDays,
    currentDateTime,
    formatDate_yyyy_mm_dd,
   formattime_hh_mm_ss,
    add_day_to_date,
    checkDateValidity,
    checkTimeValidity,
    returndifftotwodate,
    getYear2020,
    getYear1955,
    currentYear,


}
*/
