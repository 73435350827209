<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>

            <!-- Start::menu -->
            <appmenu></appmenu>
            <!-- end::menu -->

            <div class="content" style="margin-top: 0">
                <!-- Start::main-content -->
                <div class="main-content">

                    <!--div class="block justify-between page-header md:flex">
                        <div>
                            <h3 class="!text-defaulttextcolor dark:!text-defaulttextcolor/70
                            dark:text-white dark:hover:text-white text-[1.125rem] font-semibold"> </h3>
                        </div>

                    </div-->





                    <!-- Start::row-1 -->
                    <div class="w-full grid grid-cols-12">
                        <div class="xl:col-span-12 col-span-12">
                            <div class="box  overflow-hidden">
                                <div class="box-header justify-between">
                                    <div class="box-title">
                                        Liste des occasionnels
                                    </div>
                                    <div class="flex flex-wrap gap-2">


                                        <div class="mt-4 mb-4">


                                            <button @click="btnajouter" class="ti-btn ti-btn-primary-full !py-1 !px-2 !text-[0.75rem]">
                                                <i class="ri-add-line  align-middle"></i>Ajouter
                                            </button>


                                        </div>


                                    </div>
                                    <div class="w-full  grid grid-cols-2">


                                        <div class="mt-4 mb-4">

                                            <select v-model="DefaultperPage" style=" display: inline-block; margin: 0 5px; padding: 2px 25px;
                                            border: 1px solid #aaa; border-radius: 3px;" class="tabulator-page-size" aria-label="Page Size" title="Affichage">
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="150">150</option>
                                                <option value="200">200</option>
                                                <option value="250">250</option>
                                                <option value="300">300</option>
                                            </select>
                                        </div>
                                        <div class="mt-4 mb-4">
                                            <input v-model="searchword" class="form-control form-control-lg" type="text"
                                                   placeholder="Rechercher"
                                                   aria-label=".form-control-lg example">
                                        </div>

                                    </div>


                                    <div class="w-full grid grid-cols-1">

                                        <div class="mt-4 mb-4">

                                            <select v-model="searchtrierletableau"   style=" display: inline-block; margin: 0 5px; padding: 2px 25px;
                                            border: 1px solid #aaa; border-radius: 3px;" class="tabulator-page-size" aria-label="Page Size" title="Affichage">
                                                <option value="">Trier le tableau suivant / Annuler le trie</option>
                                                <option value="1">Contrat en cours</option>
                                                <option value="0">Suspension de contrat</option>
                                            </select>
                                        </div>
                                    </div>



                                </div>



                                <div class="box-body !p-0">
                                    <div class="table-responsive">
                                        <!-- component -->
                                        <div class="flex flex-col h-screen">
                                            <div class="flex-grow overflow-auto">

                                        <table class="table table-hover whitespace-nowrap min-w-full">
                                            <thead>
                                            <tr>
                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">N°</th>
                                                <th scope="col"  class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">MATRICULE</th>
                                                <th scope="col"  class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">NOM ET PRENOM(S)</th>
                                                <th scope="col"  class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">SEXE</th>
                                                <th scope="col"  class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">SERVICE</th>
                                                <th scope="col"  class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">TELEPHONE</th>
                                                <th scope="col"  class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">ADRESSE</th>
                                                <th scope="col"  class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">TYPE OCCASIONNEL</th>
                                                <th scope="col"  class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">ETAT</th>
                                                <th scope="col"  class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">CREER LE</th>
                                                <th scope="col"  class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">ACTION</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            <template v-if="filterdatatable.length">


                                                <tr class="border-t hover:bg-gray-200 dark:hover:bg-light" v-for="(datavalue,index) in filterdatatable" :key="index">

                                                    <td data-label="N°  : ">{{index+1}}</td>
                                                    <td  data-label="MATRICULE  : "   >{{ datavalue.matricul }}</td>
                                                    <td  data-label="NOM ET PRENOM(S)  : "   >{{ datavalue.name }}</td>
                                                    <td  data-label="SEXE  : "   >{{ datavalue.jsonv.sexe }}</td>
                                                    <td  data-label="SERVICE  : " >{{ datavalue.service }}</td>
                                                    <td data-label="TELEPHONE  : " >{{ datavalue.telephone}}</td>
                                                    <td data-label="ADRESSE  : " >{{  datavalue.adresse }}</td>
                                                    <td data-label="TYPE OCCASIONNEL  : " >{{  datavalue.typecollaborateur }}</td>
                                                    <td data-label="ETAT  : " >

                                                        <span v-if="datavalue.etatcontrat===1 || datavalue.etatcontrat==='1'" class="badge !rounded-full bg-primary/10 text-primary">Contrat en cours</span>
                                                        <span v-if="datavalue.etatcontrat===0 || datavalue.etatcontrat==='0'" class="badge !rounded-full bg-danger/10 text-danger">Suspension de contrat</span>

                                                    </td>

                                                    <td data-label="CREE LE"><span class="badge bg-primary/10 text-primary"><i class="bi bi-clock me-1"></i>{{datavalue.creerler}}</span></td>
                                                    <td data-label="ACTION">





                                                        <div class="ti-btn-group">
                                                            <div class="hs-dropdown ti-dropdown">
                                                                <button class="ti-btn ti-btn-info-full ti-dropdown-toggle" type="button"
                                                                        id="dropdownMenuButton1"
                                                                        aria-expanded="false">
                                                                    Action<i
                                                                        class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                                                                </button>
                                                                <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                                                    aria-labelledby="dropdownMenuButton1">

                                                                    <li>

                                                                        <button v-if="datavalue.etatcontrat===1 || datavalue.etatcontrat==='1'"  style="width: 100%!important;" @click="btndebauembau('debau',datavalue)" aria-label="anchor" class=" ti-btn
                                                                    ti-btn-sm ti-btn-danger">
                                                                            Débauchage
                                                                        </button>

                                                                        <button v-else style="width: 100%!important;" @click="btndebauembau('embau',datavalue)" aria-label="anchor" class=" ti-btn
                                                                    ti-btn-sm ti-btn-success">
                                                                            Réembauchage
                                                                        </button>

                                                                    </li>
                                                                    <li>
                                                                        <hr class="dropdown-divider">
                                                                    </li>
                                                                    <li>

                                                                        <button style="width: 100%!important;" @click="btnmodifier(datavalue)" aria-label="anchor" class=" ti-btn
                                                                    ti-btn-sm ti-btn-info">
                                                                            <i class="ri-edit-line"></i> Modifier
                                                                        </button>

                                                                    </li>
                                                                    <li>
                                                                        <hr class="dropdown-divider">
                                                                    </li>
                                                                    <li>
                                                                        <button style="width: 100%!important;" @click="btnsuppression(datavalue)" aria-label="anchor"  class=" ti-btn
                                                               ti-btn-sm ti-btn-danger">
                                                                            <i class="ri-delete-bin-line"></i> Supprimer
                                                                        </button>
                                                                    </li>


                                                                </ul>
                                                            </div>
                                                        </div>





                                                    </td>
                                                </tr>



                                            </template>



                                            <template v-else>
                                                <tr >
                                                    <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                </tr>

                                            </template>



                                            </tbody>
                                        </table>



                                            </div>
                                        </div>


                                    </div>
                                </div>





                                <div class="box-footer border-t-0">
                                    <div class="flex items-center flex-wrap overflow-auto">
                                        <div class="mb-2 sm:mb-0">

                                        </div>
                                        <div class="ms-auto">


                                            <nav aria-label="Page navigation">
                                                <ul class="ti-pagination mb-0">
                                                    <li class="page-item">
                                                        <button :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]" @click="page--">Previous</button>
                                                    </li>
                                                    <li class="page-item">
                                                        <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                                :key="key" v-for="(pageNumber, key) in displayedPages" @click="page = pageNumber">
                                                            {{ pageNumber }}
                                                        </button>
                                                    </li>
                                                    <li class="page-item">
                                                        <button :class="{ 'disabled': page >= pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++">Next</button>
                                                    </li>
                                                </ul>
                                            </nav>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--End::row-1 -->








                </div>
            </div>
        </template>

    </templatesite>



    <modalform @closed="load"  ref="modalform"></modalform>
    <modalformsuppression @closed="load"  ref="modalformsuppression"></modalformsuppression>
    <modalformdebauembau @closed="load"  ref="modalformdebauembau"></modalformdebauembau>


</template>

<script>

    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/definition/menu";

    import modalform from "@/components/views/pages/definition/occasionnel/form";
    import modalformsuppression from "@/components/views/pages/definition/personnel/formsuppression";
    import modalformdebauembau from "@/components/views/pages/definition/personnel/formdebauembau";
    import {currentYear, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');


    export default {
        /* eslint-disable */
        name: "indexliste",
        components: {
            templatesite,
            appheader,
            appsidebar,
            appmenu,
            modalform,
            modalformsuppression,
            modalformdebauembau,

        },
        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

                //console.log("  oldvalue          **************** ",oldvalue)
                //console.log("  newvalue          **************** ",newvalue)

            },
            searchtrierletableau: function(newvalue,oldvalue){

                this.page=1

            }

        },
        data() {
            return {

                datatable:[],
                otherdatas:[],
                searchword:"",
                searchtrierletableau:"",



                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 50,
                Defaultpage: 1,
                DefaultperPage: 50,
                pages: [],
            };
        },
        computed:{

            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                let mvalue=this.datatable;
                //let mvalue=[];

                //this.page=this.Defaultpage;
                this.perPage=this.DefaultperPage;

                if (this.searchtrierletableau!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {

                        return   post.etatcontrat===parseFloat(t.searchtrierletableau)

                    })

                }


                if (this.searchword!==''){
                    let t=this;



                    mvalue = mvalue.filter( function (post) {
                        return    post.name.toLowerCase().includes(t.searchword.toLowerCase())
                         ||
                        post.matricul.toLowerCase().includes(t.searchword.toLowerCase())
                            /*||
                        post.numbon.toLowerCase().includes(t.searchword.toLowerCase())*/
                    })

                }



                return this.paginate(mvalue) ;
            },
        },
        mounted() {

            this.load();
        },
        methods: {


            async load(){

                //*********************** formatge title page *********************************//
                this.titlepage="";


                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874pdefinition771714dfe/tiers/occasionnel/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res= await response
                    .then(function (res){

                        t.datatable=[];
                        t.otherdatas=[];

                        if(res.data.success){

                            t.datatable=res.data.m;
                            t.otherdatas=res.data.mother;

                        }
                        else{
                            t.datatable=[];
                            t.otherdatas=[];
                        }


                    })
                    .catch((error)=>{


                        }
                    )
                    .finally(() =>{




                    })


            },







            btnajouter(){


                let  Items=  {
                    pid:'',matricul:'',name:'',nele:'1951-01-01',adresse:'',
                    lieudenaissance:'',telephone:'',nbredenfant:0,persuse:0,
                    jsonv:{
                        sexe:'Masculin',
                        situationmatrimonial:'Celibataire',
                        emploie:null,
                        qualification:null,
                        modedepaiement:null,
                        service:null,
                        fonction:null,
                        banque:{
                            namebanque:null,
                            comptebanquaire:''
                        },
                        typedesuiviepresence:'regledegestion',
                        referencescnss:'',
                        referencescni:'',
                        referencesifu:'',
                        dernierdiplome:'',
                        typedepersonnel:'Permanent',
                        typedecontrat:'CDD',
                        medecinexecutant:'non',
                        aprisleservicele:currentYear()+"-01-01",
                        datefin:currentYear()+"-12-31",
                        photo:'',


                    },
                    jsondata:{
                        comptetva:null,
                        typecollaborateur:'OUVRIER',
                        general:{adresse:'',telephone:'',ifu:''},
                        adresses:{info:'',info1:'',info2:'',info3:''},
                        contacts:{info:'',info1:'',info2:'',info3:''},
                        autreinfo:{
                            referencescnss:'',
                            referencescni:'',
                            referencesifu:'',
                            dernierdiplome:'',
                            deductiondesretard:'1',
                            congedue:0,
                        },
                        paramdindication:{param:null,param1:null},

                    }
                }




                let titlemodal="Enregistrer un occasionnel"
                this.$refs.modalform.show(titlemodal,"add",this.otherdatas,Items);

            },

            btnmodifier(datavalue){

                let titlemodal="Modifier le personnel "+datavalue.name

                this.$refs.modalform.show(titlemodal,"update",this.otherdatas,datavalue);

            },

            btnsuppression(datavalue){

                if (datavalue.persuse===1 || datavalue.persuse==='1'){

                    toastr['error']( "Impossible de supprimer cette ligne. Veuillez contacter votre administrateur. ","<b></b>");
                    return
                }


                let titlemodal="VOULLEZ-VOUS VRAIMENT SUPPRIMER "+datavalue.name+" ?";


                this.$refs.modalformsuppression.show(titlemodal,datavalue);

            },

            btndebauembau(typaction,datavalue){

                let titlemodal

                if (typaction==="debau"){

                    titlemodal="VOULLEZ-VOUS VRAIMENT DEBAUCHER "+datavalue.name+" ?";

                }

                if (typaction==="embau"){

                    titlemodal="VOULLEZ-VOUS VRAIMENT REEMBAUCHER "+datavalue.name+" ?";

                }

                this.$refs.modalformdebauembau.show(titlemodal,datavalue,typaction);

            },











            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },








    }
</script>

<style scoped>

    .divmenuheader{
        margin-top: 60px;
    }
    .divmenuheader header{
        padding: 15px;
    }


</style>