<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>

            <!-- Start::menu -->
            <appmenu></appmenu>
            <!-- end::menu -->

            <div class="content" style="margin-top: 0">
                <!-- Start::main-content -->
                <div class="main-content">

                    <!--div class="block justify-between page-header md:flex">
                        <div>
                            <h3 class="!text-defaulttextcolor dark:!text-defaulttextcolor/70
                            dark:text-white dark:hover:text-white text-[1.125rem] font-semibold"> </h3>
                        </div>

                    </div-->

                    <!-- Start::row-1 -->
                    <div class="w-full grid grid-cols-12">
                        <div class="xl:col-span-12 col-span-12">
                            <div class="box  overflow-hidden">
                                <div class="box-header justify-between">
                                    <div class="box-title">
                                        {{titlepage}}
                                    </div>
                                    <div class="flex flex-wrap gap-2">


                                        <button @click="btnajouter" class="ti-btn ti-btn-primary-full !py-1 !px-2 !text-[0.75rem]">
                                            <i class="ri-add-line  align-middle"></i>Ajouter
                                        </button>



                                        <!--div class="hs-dropdown ti-dropdown">
                                            <a href="javascript:void(0);" class="ti-btn ti-btn-primary-full !py-1 !px-2 !text-[0.75rem]"
                                               aria-expanded="false">
                                                Trier par <i class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                                            </a>
                                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden" role="menu">
                                                <li><a class="ti-dropdown-item" href="javascript:void(0);">Posted Date</a></li>
                                                <li><a class="ti-dropdown-item" href="javascript:void(0);">Status</a></li>
                                            </ul>
                                        </div-->


                                    </div>
                                    <div class="w-full  grid grid-cols-2">


                                        <div class="mb-2 sm:mb-0">

                                            <select v-model="DefaultperPage" style=" display: inline-block; margin: 0 5px; padding: 2px 25px;
                                            border: 1px solid #aaa; border-radius: 3px;" class="tabulator-page-size" aria-label="Page Size" title="Affichage">
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="150">150</option>
                                                <option value="200">200</option>
                                                <option value="250">250</option>
                                                <option value="300">300</option>
                                            </select>
                                        </div>


                                        <div>
                                            <input v-model="searchword" class="form-control form-control-lg" type="text"
                                                   placeholder="Rechercher"
                                                   aria-label=".form-control-lg example">
                                        </div>

                                    </div>
                                </div>


                                <div class="box-body !p-0">
                                    <div class="table-responsive">
                                        <table class="table table-hover whitespace-nowrap min-w-full">
                                            <thead>

                                            <tr>
                                                <th scope="col" class="text-start">N°</th>
                                                <th scope="col" class="text-start">NOM ET PRENOM(S)</th>
                                                <th scope="col" class="text-start">TÂCHE</th>
                                                <th scope="col" class="text-start">HONORAIRE</th>
                                                <th scope="col" class="text-start">QUANTITE</th>
                                                <th scope="col" class="text-start">MOTIF</th>
                                                <th scope="col" class="text-start">DATE</th>
                                                <th scope="col" class="text-start">MONTANT</th>
                                                <th scope="col" class="text-start">SAISIR PAR</th>
                                                <th scope="col" class="text-start">CREER LE</th>
                                                <th scope="col" class="text-start">ACTION</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            <template v-if="filterdatatable.length">

                                            <tr class="border-t hover:bg-gray-200 dark:hover:bg-light" v-for="(datavalue,index) in filterdatatable" :key="index">
                                                <td data-label="N°">{{index+1}}</td>
                                                <td data-label="NOM ET PRENOM(S)">{{datavalue.namepers}}</td>
                                                <td data-label="TÂCHE">{{datavalue.nametache}}</td>
                                                <td data-label="HONORAIRE">{{datavalue.tauxtache}}</td>
                                                <td data-label="QUANTITE">{{datavalue.qte}}</td>
                                                <td data-label="MOTIF">{{datavalue.__motif.substring(0, 10)+' ...'}}  </td>
                                                <td data-label="DATE">{{datavalue.datetache}}</td>
                                                <td data-label="MONTANT">{{datavalue.montanthonoraire}}</td>
                                                <td data-label="SAISIR PAR">{{datavalue.saisiepar}}</td>
                                                <td data-label="CREE LE"><span class="badge bg-primary/10 text-primary"><i class="bi bi-clock me-1"></i>{{datavalue.mdate}}</span></td>
                                                <td data-label="ACTION">

                                                    <div class="ti-btn-group">
                                                        <div class="hs-dropdown ti-dropdown">
                                                            <button class="ti-btn ti-btn-info-full ti-dropdown-toggle" type="button"
                                                                    id="dropdownMenuButton1"
                                                                    aria-expanded="false">
                                                                Action<i
                                                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                                                            </button>
                                                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                                                aria-labelledby="dropdownMenuButton1">


                                                                <li>

                                                                    <button style="width: 100%!important;" @click="btnmodifier(datavalue)" aria-label="anchor" class=" ti-btn
                                                                    ti-btn-sm ti-btn-info">
                                                                        <i class="ri-edit-line"></i> Modifier
                                                                    </button>

                                                                </li>

                                                                <li>
                                                                    <button style="width: 100%!important;" @click="btnsuppression(datavalue)" aria-label="anchor"  class=" ti-btn
                                                               ti-btn-sm ti-btn-danger">
                                                                        <i class="ri-delete-bin-line"></i> Supprimer
                                                                    </button>
                                                                </li>


                                                            </ul>
                                                        </div>
                                                    </div>



                                                </td>
                                            </tr>



                                            </template>



                                            <template v-else>
                                                <tr >
                                                    <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                </tr>

                                            </template>



                                            </tbody>
                                        </table>
                                    </div>
                                </div>





                                <div class="box-footer border-t-0">
                                    <div class="flex items-center flex-wrap overflow-auto">
                                        <div class="mb-2 sm:mb-0">

                                        </div>
                                        <div class="ms-auto">


                                            <nav aria-label="Page navigation">
                                                <ul class="ti-pagination mb-0">
                                                    <li class="page-item">
                                                        <button :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]" @click="page--">Previous</button>
                                                    </li>
                                                    <li class="page-item">
                                                        <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                                :key="key" v-for="(pageNumber, key) in displayedPages" @click="page = pageNumber">
                                                            {{ pageNumber }}
                                                        </button>
                                                    </li>
                                                    <li class="page-item">
                                                        <button :class="{ 'disabled': page >= pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++">Next</button>
                                                    </li>
                                                </ul>
                                            </nav>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--End::row-1 -->








                </div>
            </div>
        </template>

    </templatesite>

    <modalform  ref="modalform"></modalform>
    <modalformsuppression @closed="load" ref="modalformsuppression"></modalformsuppression>

</template>

<script>

    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/paie/occasionnel/menu";

    import modalform from "@/components/views/pages/paie/occasionnel/honoraireao/form";
    import modalformsuppression from "@/components/views/pages/paie/occasionnel/honoraireao/formsuppression";
    import {currencyFormatDE, currentDate, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');



    export default {
        /* eslint-disable */
        name: "indexliste",
        components: {
            templatesite,
            appheader,
            appsidebar,
            appmenu,
            modalform,
            modalformsuppression,

        },
        watch: {
          /*  $route(to, from) {

                if(this.routertype!==to.params.typ){
                    this.routertype=to.params.typ
                    this.load();
                }

            }*/

        },
        data() {
            return {

                listedupersonnel:[],
                datatable:[],
                searchword:"",
                titlepage:"",
                typedoc:"",
                routertype:'honoraire_ao',



                posts : [''],
                page: 1,
                perPage: 50,
                Defaultpage: 1,
                DefaultperPage: 50,
                pages: [],
            };
        },
        computed:{

            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                let mvalue=this.datatable;

                //this.page=this.Defaultpage;
                this.perPage=this.DefaultperPage;

                if (this.searchword!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {
                        return    post.namepers.toLowerCase().includes(t.searchword.toLowerCase())

                    })

                }



                return this.paginate(mvalue) ;
            },


        },
        mounted() {

            if(this.$store.state.moisencourIsokoccasionnel===0 || this.$store.state.moisencourIsokoccasionnel!==1){

                toastr['error']( "Veuillez ouvrir un mois pour le traitement du salaire.")

                this.$router.push({ name: 'tbpaie' });
            }
            else{
                this.load();
            }
        },
        methods: {

            formatMontant(value){
                return currencyFormatDE(value)
            },

            async load(){
               // this.routertype=this.$route.params.typ
               // let typ=this.$route.params.typ
                //*********************** formatge title page *********************************//
                this.titlepage="Liste des tâches d'occasionnel";



                this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74dkjskdj5874ppaie771714dfe/paie/'+this.$store.state.appsociete+'/operation/'+this.routertype+'/'+randomString();
                let response =   axios  .post(url,{__p:'occasionnel',__d:t.$store.state.datasoccasionel,__t:this.routertype,__tt:'occasionnel'},{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)
                        t.datatable=[];

                        if(res.data.success){

                            t.datatable=res.data.m;
                            t.listedupersonnel=res.data.lp;

                        }
                        else{
                            t.datatable=[];
                            t.listedupersonnel=res.data.lp;
                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })

            },



            btnajouter(){



                let  magasinItem= {
                    pid:'',intitule:'',typ:this.routertype,taux:0,code:'',categorie:''
                };
                let mname="Enregistrer une tâche"

                let  Item={tache:null,pid:'',personnel:null,motif:'',montant:0,tp:'',
                    qte:0,datetache:currentDate() };


                this.$refs.modalform.show(mname,"add",this.routertype,this.listedupersonnel,Item);


            },

            btnmodifier(value){


                let  Item= {
                    tache:value.tache,
                    pid:value.pid,
                    personnel:value.personnel,
                    montant:value.montanthonoraire,
                    qte:value.qte,
                    datetache:value.datetache,
                    motif:value.__motif,
                    societe:"",
                    typ:this.routertype
                };

                let titlemodal="Modifier une tâche"

                this.$refs.modalform.show(titlemodal,"update",this.routertype,this.listedupersonnel,Item);


            },

            btnsuppression(value){

                let titlemodal="SUPPRESSION DE "

                this.$refs.modalformsuppression.show(value);

            },










            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {
                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);
                return  posts.slice(from, to);
            }



        },







    }
</script>

<style scoped>

    .divmenuheader{
        margin-top: 60px;
    }
    .divmenuheader header{
        padding: 15px;
    }


</style>