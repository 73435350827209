<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>

            <!-- Start::menu -->
            <appmenu></appmenu>
            <!-- end::menu -->

            <div class="content" style="margin-top: 0">
                <!-- Start::main-content -->
                <div class="main-content">

                    <!--div class="block justify-between page-header md:flex">
                        <div>
                            <h3 class="!text-defaulttextcolor dark:!text-defaulttextcolor/70
                            dark:text-white dark:hover:text-white text-[1.125rem] font-semibold"> </h3>
                        </div>

                    </div-->





                    <!-- Start::row-1 -->
                    <div class="w-full grid grid-cols-12">
                        <div class="xl:col-span-12 col-span-12">
                            <div class="box  overflow-hidden">
                                <div class="box-header justify-between">
                                    <div class="box-title">
                                        {{titlepage}}
                                    </div>
                                    <div class="flex flex-wrap gap-2">

                                        <div class="mt-4 mb-4">

                                        <button @click="btnajouter" class="ti-btn ti-btn-primary-full !py-1 !px-2 !text-[0.75rem]">
                                            <i class="ri-add-line  align-middle"></i>Ajouter
                                        </button>

                                        </div>

                                    </div>
                                    <div class="w-full  grid grid-cols-2">


                                        <div class="mt-4 mb-4">

                                            <select v-model="DefaultperPage" style=" display: inline-block; margin: 0 5px; padding: 2px 25px;
                                            border: 1px solid #aaa; border-radius: 3px;" class="tabulator-page-size" aria-label="Page Size" title="Affichage">

                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="150">150</option>
                                                <option value="200">200</option>
                                                <option value="300">300</option>

                                            </select>
                                        </div>


                                        <div class="mt-4 mb-4">
                                            <input v-model="searchword" class="form-control form-control-lg" type="text"
                                                   placeholder="Rechercher"
                                                   aria-label=".form-control-lg example">
                                        </div>

                                    </div>
                                </div>


                                <div class="box-body !p-0">
                                    <div class="table-responsive">


                                        <table class="mytable table table-hover whitespace-nowrap min-w-full">
                                            <thead>
                                            <tr>
                                                <th scope="col" class="text-start">N°</th>
                                                <th scope="col" class="text-start">INTITULE</th>
                                                <th scope="col" class="text-start" v-if="routertype==='tache'">CODE</th>
                                                <th scope="col" class="text-start" v-if="routertype==='tache'">CATEGORIE</th>
                                                <th scope="col" class="text-start" v-if="routertype==='tache'">HONORAIRE</th>
                                                <th scope="col" class="text-start">CREER LE</th>
                                                <th scope="col" class="text-start">ACTION</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            <template v-if="filterdatatable.length">

                                            <tr class="border-t hover:bg-gray-200 dark:hover:bg-light" v-for="(datavalue,index) in filterdatatable" :key="index">

                                                <td data-label="N°  : ">{{index+1}}</td>
                                                <td data-label="INTITULE  : ">{{datavalue.name}} </td>
                                                <td v-if="routertype==='tache'" data-label="CODE  : "> {{datavalue.code}} </td>
                                                <td v-if="routertype==='tache'" data-label="CATEGORIE  : "> {{datavalue.categorie}} </td>
                                                <td v-if="routertype==='tache'" data-label="HONORAIRE  : "> {{datavalue.taux}} </td>

                                                <td data-label="CREE LE"><span class="badge bg-primary/10 text-primary"><i class="bi bi-clock me-1"></i>{{datavalue.creerler}}</span></td>
                                                <td data-label="ACTION">





                                                    <div class="ti-btn-group">
                                                        <div class="hs-dropdown ti-dropdown">
                                                            <button class="ti-btn ti-btn-info-full ti-dropdown-toggle" type="button"
                                                                    id="dropdownMenuButton1"
                                                                    aria-expanded="false">
                                                                Action<i
                                                                    class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                                                            </button>
                                                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden"
                                                                aria-labelledby="dropdownMenuButton1">
                                                                <li>

                                                                    <button style="width: 100%!important;" @click="btnmodifier(datavalue)" aria-label="anchor" class="ti-btn
                                                                    ti-btn-sm ti-btn-info">
                                                                        <i class="ri-edit-line"></i> Modifier
                                                                    </button>

                                                                </li>

                                                                <li>
                                                               <button style="width: 100%!important;" @click="btnsuppression(datavalue)" aria-label="anchor"  class="ti-btn
                                                               ti-btn-sm ti-btn-danger">
                                                                        <i class="ri-delete-bin-line"></i> Supprimer
                                                                    </button>
                                                                </li>


                                                            </ul>
                                                        </div>
                                                    </div>





                                                </td>
                                            </tr>



                                            </template>



                                            <template v-else>
                                                <tr >
                                                    <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                </tr>

                                            </template>



                                            </tbody>
                                        </table>


                                    </div>
                                </div>





                                <div class="box-footer border-t-0">
                                    <div class="flex items-center flex-wrap overflow-auto">
                                        <div class="mb-2 sm:mb-0">

                                        </div>


                                        <div class="ms-auto">

                                            <nav aria-label="Page navigation">
                                                <ul class="ti-pagination mb-0">
                                                    <li class="page-item">
                                                        <button :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]" @click="page--">Previous</button>
                                                    </li>
                                                    <li class="page-item">
                                                        <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                                :key="key" v-for="(pageNumber, key) in displayedPages" @click="page = pageNumber">
                                                            {{ pageNumber }}
                                                        </button>
                                                    </li>
                                                    <li class="page-item">
                                                        <button :class="{ 'disabled': page >= pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++">Next</button>
                                                    </li>
                                                </ul>
                                            </nav>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--End::row-1 -->








                </div>
            </div>
        </template>

    </templatesite>

    <modalform @closed="load" ref="modalform"></modalform>
    <modalformsuppression @closed="load"  ref="modalformsuppression"></modalformsuppression>

</template>

<script>

    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/definition/menu";

    import modalform from "@/components/views/pages/definition/tiers/fichiersdebase/form";
    import modalformsuppression from "@/components/views/pages/definition/tiers/fichiersdebase/formsuppression";
    import {randomString} from "@/allfunction";
    //import allfunction from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');


    export default {
        /* eslint-disable */
        name: "indexliste",
        components: {
            templatesite,
            appheader,
            appsidebar,
            appmenu,
            modalform,
            modalformsuppression,

        },
        watch: {
            $route(to, from) {

                if(this.routertype!==to.params.typ){
                    this.routertype=to.params.typ
                    this.load();
                }

            },

            searchword: function(newvalue,oldvalue){

                this.page=1

                  }

        },
        data() {
            return {

                datatable:[],
                searchword:"",
                titlepage:"",
                typedoc:"",
                routertype:'',



                posts : [''],
                page: 1,
               // perPage: 100,
                perPage: 50,
                Defaultpage: 1,
                DefaultperPage: 50,
                pages: [],
            };
        },
        computed:{

            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                let mvalue=this.datatable;

                //this.page=this.Defaultpage;
                this.perPage=this.DefaultperPage;

                if (this.searchword!==''){
                    let t=this;
                    //this.page=this.Defaultpage;
                   // this.perPage=this.DefaultperPage;

                    mvalue = mvalue.filter( function (post) {
                        return    post.name.toLowerCase().includes(t.searchword.toLowerCase())
                            /*||
                            post.nameclient.toLowerCase().includes(t.searchword.toLowerCase()) ||
                            post.numbon.toLowerCase().includes(t.searchword.toLowerCase())*/
                    })

                }



                return this.paginate(mvalue) ;
            },

        },
        mounted() {

             this.load();
        },
        methods: {


            async load(){
                this.routertype=this.$route.params.typ
               // let typ=this.$route.params.typ
                //*********************** formatge title page *********************************//
                this.titlepage="";

                if (this.routertype==='fonction'){
                    this.titlepage="Liste des fonctions"
                }

                if (this.routertype==='categorie'){
                    this.titlepage="Liste des catégories"
                }

                if (this.routertype==='banque'){
                    this.titlepage="Liste des banques"
                }

                if (this.routertype==='mdreglement'){
                    this.titlepage="Liste des mode de règlements"
                }

                if (this.routertype==='situationmatrimonial'){
                    this.titlepage="Liste des situations matrimoniales"
                }

                if (this.routertype==='qualification'){
                    this.titlepage="Liste des qualifications et diplômes"

                }


                if (this.routertype==='service'){
                    this.titlepage="Liste des services"

                }

                if (this.routertype==='tache'){
                    this.titlepage="Liste des tâches"

                }



                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874pdefinition771714dfe/tier/'+this.routertype+'/'+this.$store.state.appsociete+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res= await response
                    .then(function (res){

                        t.datatable=[];

                        if(res.data.success){

                            t.datatable=res.data.m;

                        }
                        else{
                            t.datatable=[];
                        }


                    })
                    .catch((error)=>{


                        }
                    )
                    .finally(() =>{




                    })


            },



            btnajouter(){



                let  magasinItem= {
                    pid:'',intitule:'',typ:this.routertype,taux:0,code:'',categorie:''
                };
                let mname=''




                if (this.routertype==='categorie'){
                    mname='Enregistrer une catégorie';

                }

                if (this.routertype==='banque'){
                    mname='Enregistrer une banque';

                }


                if (this.routertype==='mdreglement'){
                    mname='Enregistrer un mode de réglement';

                }


                if (this.routertype==='situationmatrimonial'){
                    mname='Enregistrer une situation matrimoniale';

                }


                if (this.routertype==='fonction'){
                    mname='Enregistrer une Fonction';

                }



                if (this.routertype==='qualification'){
                    mname='Enregistrer une qualification';
                }

                if (this.routertype==='emploie'){
                    mname='Enregistrer un emploie';
                }

                if (this.routertype==='service'){
                    mname='Enregistrer un service';
                }

                if (this.routertype==='tache'){
                    mname='Enregistrer une tâche';
                }



                this.$refs.modalform.show(mname,"add",this.routertype,magasinItem);

            },

            btnmodifier(value){


                let titlemodal="Modifier l'enregistrement "+value.name

                let magasinItem={
                    pid:value.pid,intitule:value.name,typ:value.typetbfichier,taux:value.taux,code:value.code,
                    categorie:value.categorie
                };

                this.$refs.modalform.show(titlemodal,"update",this.routertype,magasinItem);

            },

            btnsuppression(value){

                if (value.useline===1 || value.useline==='1'){

                    toastr['error']( "Impossible de supprimer cette ligne. Veuillez contacter votra administrateur. ","<b></b>");
                    return
                }


                let titlemodal="VOULLEZ-VOUS VRAIMENT SUPPRIMER "+value.name+" ?";

                this.$refs.modalformsuppression.show(titlemodal,value,this.routertype);

            },


            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

               let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

               // return  posts.slice(from, to);
            }



        },







    }
</script>

<style scoped>

    .divmenuheader{
        margin-top: 60px;
    }
    .divmenuheader header{
        padding: 15px;
    }


</style>