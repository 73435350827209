<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>

            <!-- Start::menu -->
            <appmenu></appmenu>
            <!-- end::menu -->

            <div class="content" style="margin-top: 0">
                <!-- Start::main-content -->
                <div class="main-content">
                    <!-- Page Header -->
                    <div class="block justify-between page-header md:flex">
                        <!--div>
                            <h3 class="!text-defaulttextcolor dark:!text-defaulttextcolor/70
                            dark:text-white dark:hover:text-white text-[1.125rem] font-semibold"> Empty</h3>
                        </div-->

                    </div>
                    <!-- Page Header Close -->


                    <div class="grid grid-cols-12 gap-6">


                        <div class="xl:col-span-6 col-span-12">
                            <div class="box custom-box">
                                <div class="box-header justify-between">
                                    <div class="box-title">
                                        Liste du personnel permanent en fin de contrat dans 6 mois
                                    </div>

                                </div>
                                <div class="box-body">
                                    <div class="table-responsive">
                                        <div class="flex flex-col h-screen">
                                            <div class="flex-grow overflow-auto">


                                            <table class="table whitespace-nowrap min-w-full">
                                            <thead>





                                            <tr class="border-b border-defaultborder">
                                                <th scope="col" class="text-start">NOM ET PRÉNOM(S)</th>
                                                <th scope="col" class="text-start">SERVICE</th>
                                                <th scope="col" class="text-start">DEBUT DE CONTRAT</th>
                                                <th scope="col" class="text-start">FIN DE CONTRAT</th>
                                                <th scope="col" class="text-start">NOMBRE DE <br> JOUR(S) RESTANT(S)</th>
                                                <th scope="col" class="text-start">ACTION</th>
                                            </tr>
                                            </thead>
                                            <tbody>


                                            <template v-if="datatablefindecontratpermannent.length">

                                                <tr class="border-t hover:bg-gray-200 dark:hover:bg-light" v-for="(datavalue,index) in datatablefindecontratpermannent" :key="index">



                                                    <td  data-label="NOM ET PRENOM(S)  : "   >{{ datavalue.name }}</td>
                                                    <td  data-label="SERVICE  : "   >{{ datavalue.service }}</td>
                                                    <td  data-label="DATE DE PRISE DE SERVICE  : "   >{{ datavalue.debutcontrat }}</td>
                                                    <td  data-label="DATE FIN DE CONTRAT  : " >{{ datavalue.fincontrat }}</td>
                                                    <td  data-label="NOMBRE DE JOUR(S) RESTANT(S)  : " >{{ datavalue.jours_restants }}</td>
                                                    <td data-label="ACTION">

                                                    </td>
                                                </tr>



                                            </template>



                                            <template v-else>
                                                <tr >
                                                    <td colspan="10"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                </tr>

                                            </template>



                                            </tbody>
                                        </table>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-footer border-t-0 hidden">

                                </div>
                            </div>
                        </div>



                        <div class="xl:col-span-6 col-span-12">
                            <div class="box custom-box">
                                <div class="box-header justify-between">
                                    <div class="box-title">
                                        Liste du personnel occasionnel en fin de contrat dans 7 Jours
                                    </div>

                                </div>
                                <div class="box-body">
                                    <div class="table-responsive">
                                        <table class="table whitespace-nowrap min-w-full">
                                            <thead>
                                            <tr class="border-b border-defaultborder">
                                                <th scope="col" class="text-start">NOM ET PRÉNOM(S)</th>
                                                <th scope="col" class="text-start">DATE DE PRISE DE SERVICE</th>
                                                <th scope="col" class="text-start">DATE FIN DE CONTRAT</th>
                                                <th scope="col" class="text-start">ACTION</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr >
                                                <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="box-footer border-t-0 hidden">

                                </div>
                            </div>
                        </div>


                    </div>






                </div>
            </div>
        </template>

    </templatesite>



</template>

<script>

    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/definition/menu";


    import * as JwtService from "@/common/jwt.service";
    import {randomString} from "@/allfunction";
    const axios =require('axios');


    export default {
        /* eslint-disable */
        name: "ewallet",
        components: {
            templatesite,
            appheader,
            appsidebar,
            appmenu,

        },

        data() {
            return {
                datatablefindecontratpermannent:[]

            };
        },
        methods: {


            async loadfincontratdans6mois(){



                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874pdefinition771714dfe/tiers/personnel/'+this.$store.state.appsociete+'/findecontrat/personnel/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res= await response
                    .then(function (res){

                        t.datatablefindecontratpermannent=[];


                        if(res.data.success){

                            t.datatablefindecontratpermannent=res.data.m;

                        }
                        else{
                            t.datatablefindecontratpermannent=[];

                        }


                    })
                    .catch((error)=>{


                        }
                    )
                    .finally(() =>{




                    })


            },





        },
        mounted() {

           this.loadfincontratdans6mois();
        }






    }
</script>

<style scoped>

    .divmenuheader{
        margin-top: 60px;
    }
    .divmenuheader header{
        padding: 15px;
    }


</style>