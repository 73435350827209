<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>

            <!-- Start::menu -->
            <appmenu></appmenu>
            <!-- end::menu -->
            <div class="content" >
                <!-- Start::main-content -->
                <div class="main-content">
                    <!-- Page Header -->
                    <div class="block justify-between page-header md:flex">
                        <div>
                            <h3 class="!text-defaulttextcolor dark:!text-defaulttextcolor/70
                            dark:text-white dark:hover:text-white text-[1.125rem] font-semibold">
                                Traitement de la paie du mois de/d' {{$store.state.datasoccasionel.moisp}}
                            </h3>
                        </div>

                    </div>
                    <!-- Page Header Close -->

                    <div class="grid sm:grid-cols-3 gap-6">

                        <div class="space-y-2">
                            <button @click="btnmiseajournombredejourabsencemanuel('occasionnel')" type="button" class="w-full ti-btn ti-btn-danger-gradient ti-btn-wave">

                                Mise à jour du nombre de jour de travail <br> pour le mois de/d' {{$store.state.datasoccasionel.moisp}}

                            </button>
                        </div>

                        <div class="space-y-2">
                            <button @click="btnmoadalaskmiseajourdesdonnes($store.state.datasoccasionel.moisp,'occasionnel')" type="button" class="w-full ti-btn ti-btn-secondary-gradient ti-btn-wave">

                                MISE A JOUR DES DONNEES DU MOIS DE / D' {{$store.state.datasoccasionel.moisp}}

                            </button>
                        </div>

                        <div class="space-y-2">
                            <button type="button"  @click="btnmoadalaskcloturedumois($store.state.datasoccasionel.moisp,'occasionnel')" class="w-full ti-btn ti-btn-danger-full ti-btn-wave">CLÔTURE DU MOIS DE / D' {{$store.state.datasoccasionel.moisp}} </button>
                        </div>


                    </div>










                </div>
            </div>
        </template>

    </templatesite>

    <moadalaskcloturedumois @closed="closemois" ref="moadalaskcloturedumois"></moadalaskcloturedumois>
    <modalmiseajournombredejourabsencemanuel ref="modalmiseajournombredejourabsencemanuel"></modalmiseajournombredejourabsencemanuel>
    <moadalaskmiseajourdesdonnes ref="moadalaskmiseajourdesdonnes"></moadalaskmiseajourdesdonnes>
</template>

<script>

    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/paie/occasionnel/menu";
    import modalmiseajournombredejourabsencemanuel
        from "@/components/views/pages/paie/modal_miseajournombredejourabsencemanuel";
    import moadalaskmiseajourdesdonnes from "@/components/views/pages/paie/moadalask_miseajourdesdonnes";
    import moadalaskcloturedumois from "@/components/views/pages/paie/moadalask_cloturedumois";

   /* import allfunction from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');*/


    export default {
        /* eslint-disable */
        name: "fff",
        components: {
            templatesite,
            appheader,
            appsidebar,
            appmenu,
            modalmiseajournombredejourabsencemanuel,
            moadalaskmiseajourdesdonnes,
            moadalaskcloturedumois,

        },
        props: {
            datas: {},


        },

        data() {
            return {

            };
        },
        methods: {


            closemois(){

                this.$router.push({
                    name: 'tbpaie',
                    params: {

                    }

                })

            },

            btnmoadalaskcloturedumois(value,typepersonnel){

                this.$refs.moadalaskcloturedumois.show(value,typepersonnel);


            },


            btnmiseajournombredejourabsencemanuel(value){

                this.$refs.modalmiseajournombredejourabsencemanuel.show(value);

            },

            btnmoadalaskmiseajourdesdonnes(value,typepersonnel){

                this.$refs.moadalaskmiseajourdesdonnes.show(value,typepersonnel);


            },





        },
        mounted() {

           // this.loaddata();

          /*  if (this.$store.state.dataspaie.typ==="occasionnel"){

                if(this.$store.state.moisencourIsok===0 || this.$store.state.moisencourIsok!==1){

                    toastr['error']( "Veuillez ouvrir un mois pour le traitement du salaire.")

                    this.$router.push({ name: 'tbpaie' });
                }
                else{
                    // this.init();
                }


            }
            else{

                toastr['error']( "Veuillez ouvrir un mois pour le traitement du salaire.")

                this.$router.push({ name: 'tpaie' });
            }
*/

            if(this.$store.state.moisencourIsokoccasionnel===0 || this.$store.state.moisencourIsokoccasionnel!==1){

                toastr['error']( "Veuillez ouvrir un mois pour le traitement du salaire.")

                this.$router.push({ name: 'tbpaie' });
            }
            else{
                // this.init();
            }




        }






    }
</script>

<style scoped>

    .badge {
        padding: 0.6em 0.75em;
        font-size: 100%;
    }

    .divmenuheader{
        margin-top: 60px;
    }
    .divmenuheader header{
        padding: 15px;
    }


</style>