<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>

            <!-- Start::menu -->
            <!--appmenu></appmenu-->
            <!-- end::menu -->

            <div class="content" >
                <!-- Start::main-content -->
                <div class="main-content">
                    <!-- Page Header -->
                    <div class="block justify-between page-header md:flex">
                        <div>
                            <h3 class="!text-defaulttextcolor dark:!text-defaulttextcolor/70
                            dark:text-white dark:hover:text-white text-[1.125rem] font-semibold"> -</h3>
                        </div>

                    </div>
                    <!-- Page Header Close -->


                    <div class="grid grid-cols-1 gap-6">




                        <div class="box custom-box">
                                <div class="box-header justify-between">
                                    <div class="box-title">
                                        Ouvrir un mois
                                    </div>

                                </div>
                                <div class="box-body">


                                    <div class="ti-list-group">
                                        <ul class="list-none">


                                            <li style="cursor: pointer;" class="ti-list-group-item ti-list-group-item-action">


                                                <a v-if="$store.state.moisencourIsok===1" @click="selectMois('personnel')"
                                                   aria-current="true">
                                                    <div class="grid sm:grid-cols-3 gap-6">
                                                         <div class="space-y-2 text-center">
                                                             <i class="bi bi-clock me-1"></i>  {{moisdouverture_permanent}}
                                                         </div>
                                                        <div class="space-y-2 text-center">
                                                            <span class="badge  bg-info-gradient text-white"> Traitement de paie des permanents</span>
                                                        </div>
                                                        <div class="space-y-2 text-center">
                                                            <span class="badge bg-success text-white">Ouvert</span>
                                                        </div>
                                                    </div>
                                                </a>



                                                <a v-else @click="ouvrirlemois($store.state.dataspersonnel.moisp,'personnel')"
                                                   aria-current="true">
                                                    <div class="grid sm:grid-cols-3 gap-6">
                                                         <div class="space-y-2 text-center">
                                                             <i class="bi bi-clock me-1"></i>  {{moisdouverture_permanent}}
                                                         </div>
                                                        <div class="space-y-2 text-center">
                                                            <span class="badge  bg-info-gradient text-white"> Traitement de paie des permanents</span>
                                                        </div>
                                                        <div class="space-y-2 text-center">
                                                            <span class="badge bg-danger text-white">Fermer</span>
                                                        </div>
                                                    </div>
                                                </a>




                                            </li>

                                            <template v-if="$store.state.sysmodule.occasionnel===1">


                                            <li style="cursor: pointer;" class="ti-list-group-item ti-list-group-item-action hover:bg-gray-200 dark:hover:bg-light">
                                                <a  v-if="$store.state.moisencourIsokoccasionnel===1" @click="selectMois('occasionnel')"
                                                   aria-current="true">
                                                    <div class="grid sm:grid-cols-3 gap-6">
                                                        <div class="space-y-2 text-center">
                                                            <i class="bi bi-clock me-1"></i>  {{moisdouverture_occasionnel}}
                                                        </div>
                                                        <div class="space-y-2 text-center">
                                                            <span class="badge  bg-info-gradient text-white"> Traitement de paie des occasionnels</span>
                                                        </div>
                                                        <div class="space-y-2 text-center">
                                                            <span class="badge bg-success text-white">Ouvert</span>
                                                        </div>
                                                    </div>
                                                </a>

                                             <a v-else @click="ouvrirlemois($store.state.datasoccasionel.moisp,'occasionnel')"
                                                   aria-current="true">
                                                    <div class="grid sm:grid-cols-3 gap-6">
                                                        <div class="space-y-2 text-center">
                                                            <i class="bi bi-clock me-1"></i>  {{moisdouverture_occasionnel}}
                                                        </div>
                                                        <div class="space-y-2 text-center">
                                                            <span class="badge  bg-info-gradient text-white"> Traitement de paie des occasionnels</span>
                                                        </div>
                                                        <div class="space-y-2 text-center">
                                                            <span class="badge bg-danger text-white">Fermer</span>
                                                        </div>
                                                    </div>
                                                </a>





                                            </li>


                                            </template>


                                        </ul>
                                    </div>



                                </div>
                                <div class="box-footer border-t-0 hidden">

                                </div>
                            </div>



                        </div>











                </div>
            </div>


            <moadalaskouverturedumois @closed="init" ref="moadalaskouverturedumois"></moadalaskouverturedumois>

        </template>

    </templatesite>


</template>

<script>

    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/definition/menu";


    import {randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    import moadalaskouverturedumois from "@/components/views/pages/paie/moadalask_ouverturedumois";

    const axios =require('axios');

    var ladate=new Date()
    export default {
        /* eslint-disable */
        name: "opoo",
        components: {
            templatesite,
            appheader,
            appsidebar,
            appmenu,
            moadalaskouverturedumois,

        },

        data() {
            return {
                searchtransaction:'',
                listecatalogue:[],
                listedocday:[],
                listedesbesoins:[],
                datedujour:ladate.getFullYear()+"-"+(ladate.getMonth()+1)+"-"+ladate.getDate(),
                listeallcaisses:[],
                listedesoccasionnels:[],
                listedespersonnels:[],
                moisdouverture_permanent:'',
                moisdouverture_occasionnel:'',
                typeouverture:'',
                mshow:false,









            };
        },
        methods: {


            selectMois(value){

                if (value==='personnel'){


                   /* var mdata=[]
                    if(this.listedespersonnels.length>0){
                        mdata=this.listedespersonnels;
                    }
                    console.log("mdata   ************  ",mdata)*/
                  let datas=this.$store.state.dataspersonnel;
                  /*  datas.listedespersonnels=mdata;*/



                    this.$store.commit('dataspaie',{value:{datas:datas,typ:value}});
                  this.$router.push({
                        name: 'indexpaiepermanentencour',
                        params: {
                            mois: this.$store.state.dataspersonnel.moislettre,
                            anne: this.$store.state.dataspersonnel.mannee,
                            datas: datas,

                        },
                      props: {
                          datas: datas,
                      },
                    })


                }


                if (value==='occasionnel'){

                  /*  var mdata=[]
                    if(this.listedesoccasionnels.length>0){
                        mdata=this.listedesoccasionnels;
                    }*/

                    let datas=this.$store.state.datasoccasionel;
                    //datas.listedespersonnels=mdata;

/*
                    this.$store.commit('dataspaie',{value:{datas:datas,typ:value}});
                    this.$router.push({
                        name: 'indexpaiepermanentencour',
                        params: {
                            mois: this.$store.state.dataspersonnel.moislettre,
                            anne: this.$store.state.dataspersonnel.mannee,
                            datas: datas,

                        },
                        props: {
                            datas: datas,
                        },
                    })
*/


                    this.$store.commit('dataspaie',{value:{datas:datas,typ:value}});
                    this.$router.push({
                        name: 'indexpaieencouroccasionnel',
                        params: {

                            mois: this.$store.state.datasoccasionel.moislettre,
                            anne: this.$store.state.datasoccasionel.mannee,
                            datas: datas,


                        },
                        props: {
                            datas: datas,
                        },

                    })


                }


            },


            async init(){


                this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74dkjskdj5874ppaie771714dfe/paie/'+this.$store.state.appsociete+'/init/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res= await response
                    .then(response => {



                        //t.$store.dispatch('moisencourIsok',response.data.m.__isok)
                        t.$store.dispatch('moisencourIsok',response.data.m.datas.moisencour)
                        //t.$store.dispatch('moisencourIsokoccasionnel',response.data.m.___isok)
                        t.$store.dispatch('moisencourIsokoccasionnel',response.data.m._datas.moisencour)
                        t.$store.dispatch('dataspersonnel',response.data.m.datas)
                        t.$store.dispatch('datasoccasionel',response.data.m._datas)
                        t.$store.dispatch('storelistepersonnels',response.data.m.___lp)
                        t.$store.dispatch('storelisteoccasionels',response.data.m.___lo)
                       // t.moisdouverture_permanent=response.data.m.__mois;
                        t.moisdouverture_permanent=response.data.m.datas.moisp;
                       // t.moisdouverture_occasionnel=response.data.m.___mois;
                        t.moisdouverture_occasionnel=response.data.m._datas.moisp;
                      //  t.listedesoccasionnels=response.data.m.__lo;
                       // t.listedespersonnels=response.data.m.__lp;
                        t.$store.dispatch('lancerchargement',false)
                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() => this.$store.dispatch('lancerchargement',false))




            },


            ouvrirlemois(value,typepersonnel){
              /*  this.typeouverture=value;
                this.mshow=true;*/

                this.$refs.moadalaskouverturedumois.show(value,typepersonnel);


               //

            },



        },
        mounted() {

           this.init();
        }






    }
</script>

<style scoped>

    .badge {
        padding: 0.6em 0.75em;
        font-size: 100%;
    }

    .divmenuheader{
        margin-top: 60px;
    }
    .divmenuheader header{
        padding: 15px;
    }


</style>