<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">



                    <div class="box  overflow-hidden">
                        <div class="box-header justify-between">
                            <div class="box-title">
                                {{titlemodal}}
                            </div>






                        </div>


                        <div class="box-body !p-0">

                            <div class="grid lg:grid-cols-2 gap-6">


                                <div class="space-y-2">
                                    <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Impression en</label>
                                    <select v-model="taillemodel" class="form-control mb-3">
                                        <option value="A4">A4</option>
                                        <option value="A4landscape">A4 landscape</option>
                                        <option value="A5">A5</option>
                                        <option value="A5landscape">A5 landscape</option>
                                    </select>
                                </div>
                                <div class="space-y-2">
                                    <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Rechercher</label>
                                    <input v-model="searchword" class="form-control form-control-lg" type="text"
                                           placeholder="Rechercher"
                                           aria-label=".form-control-lg example">
                                </div>


                                <!--div class="space-y-2">
                                    <button v-if="!viewall" @click="print4" class="ti-btn ti-btn-success-full  w-full"> Imprimer</button>

                                </div-->



                            </div>


                            <div class="grid lg:grid-cols-3 gap-6 mt-4">

                                <div class="space-y-2">



                                    <button id="dropdownHelperButton" @click="btnviewdrop" data-dropdown-toggle="dropdownHelper"
                                            class="ti-btn ti-btn-primary-full ti-dropdown-toggle !py-2 w-full"
                                            type="button">Columns <i class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                                    </button>

                                    <!-- Dropdown menu -->
                                    <div id="dropdownHelper" v-show="viewdrop" class="z-10 w-full bg-white divide-y divide-gray-100 rounded-lg shadow w-60 dark:bg-gray-700
                                    dark:divide-gray-600">

                                        <div id="scrollspy-scrollable-parent-2" class="max-h-[250px] overflow-y-auto">

                                            <ul class="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownHelperButton">

                                                <li>
                                                    <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                        <div class="flex items-center h-5">
                                                            <input v-model="activecolumsligne1" type="checkbox" class="custom-control-input" id="activecolumsligne1">  </div>
                                                        <div class="ms-2 text-sm">
                                                            <label class="custom-control-label" for="activecolumsligne1">N°CNSS</label>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                        <div class="flex items-center h-5">
                                                            <input v-model="activecolumsligne2" type="checkbox" class="custom-control-input" id="activecolumsligne2">  </div>
                                                        <div class="ms-2 text-sm">
                                                            <label class="custom-control-label" for="activecolumsligne2">Employé</label>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                        <div class="flex items-center h-5">
                                                            <input v-model="activecolumsligne3" type="checkbox" class="custom-control-input" id="activecolumsligne3">  </div>
                                                        <div class="ms-2 text-sm">
                                                            <label class="custom-control-label" for="activecolumsligne3">Salaire de base</label>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                        <div class="flex items-center h-5">
                                                            <input v-model="activecolumsligne4" type="checkbox" class="custom-control-input" id="activecolumsligne4">  </div>
                                                        <div class="ms-2 text-sm">
                                                            <label class="custom-control-label" for="activecolumsligne4">Salaire brut</label>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                        <div class="flex items-center h-5">
                                                            <input v-model="activecolumsligne5" type="checkbox" class="custom-control-input" id="activecolumsligne5">  </div>
                                                        <div class="ms-2 text-sm">
                                                            <label class="custom-control-label" for="activecolumsligne5">Mode de réglement</label>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                        <div class="flex items-center h-5">
                                                            <input v-model="activecolumsligne6" type="checkbox" class="custom-control-input" id="activecolumsligne6">  </div>
                                                        <div class="ms-2 text-sm">
                                                            <label class="custom-control-label" for="activecolumsligne6">Taux CNSS</label>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                        <div class="flex items-center h-5">
                                                            <input v-model="activecolumsligne7" type="checkbox" class="custom-control-input" id="activecolumsligne7">  </div>
                                                        <div class="ms-2 text-sm">
                                                            <label class="custom-control-label" for="activecolumsligne7">Jour ouvrés</label>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                        <div class="flex items-center h-5">
                                                            <input v-model="activecolumsligne8" type="checkbox" class="custom-control-input" id="activecolumsligne8">  </div>
                                                        <div class="ms-2 text-sm">
                                                            <label class="custom-control-label" for="activecolumsligne8">Heure supplémentaire</label>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                        <div class="flex items-center h-5">
                                                            <input v-model="activecolumsligne9" type="checkbox" class="custom-control-input" id="activecolumsligne9">  </div>
                                                        <div class="ms-2 text-sm">
                                                            <label class="custom-control-label" for="activecolumsligne9">Montant cnss</label>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                        <div class="flex items-center h-5">
                                                            <input v-model="activecolumsligne14" type="checkbox" class="custom-control-input" id="activecolumsligne14">  </div>
                                                        <div class="ms-2 text-sm">
                                                            <label class="custom-control-label" for="activecolumsligne14">Montant ITS</label>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                        <div class="flex items-center h-5">
                                                            <input v-model="activecolumsligne10" type="checkbox" class="custom-control-input" id="activecolumsligne10">  </div>
                                                        <div class="ms-2 text-sm">
                                                            <label class="custom-control-label" for="activecolumsligne10">Mensualité prêt</label>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                        <div class="flex items-center h-5">
                                                            <input v-model="activecolumsligne11" type="checkbox" class="custom-control-input" id="activecolumsligne11">  </div>
                                                        <div class="ms-2 text-sm">
                                                            <label class="custom-control-label" for="activecolumsligne11">Avance sur salaire</label>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                        <div class="flex items-center h-5">
                                                            <input v-model="activecolumsligne12" type="checkbox" class="custom-control-input" id="activecolumsligne12">  </div>
                                                        <div class="ms-2 text-sm">
                                                            <label class="custom-control-label" for="activecolumsligne12">Net à payer</label>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                        <div class="flex items-center h-5">
                                                            <input v-model="activecolumsligne13" type="checkbox" class="custom-control-input" id="activecolumsligne13">  </div>
                                                        <div class="ms-2 text-sm">
                                                            <label class="custom-control-label" for="activecolumsligne13">Service</label>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                        <div class="flex items-center h-5">
                                                            <input v-model="activecolumsligne15" type="checkbox" class="custom-control-input" id="activecolumsligne15">  </div>
                                                        <div class="ms-2 text-sm">
                                                            <label class="custom-control-label" for="activecolumsligne15">Montant cnss/Patronale</label>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
                                                        <div class="flex items-center h-5">
                                                            <input v-model="activecolumsligne16" type="checkbox" class="custom-control-input" id="activecolumsligne16">  </div>
                                                        <div class="ms-2 text-sm">
                                                            <label class="custom-control-label" for="activecolumsligne16">Montant VPS</label>
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>

                                        </div>



                                    </div>


                                </div>

                                <div class="space-y-2">
                                    <button v-if="!viewall"    @click="ExportExcel('xlsx','ETAT DE PAIEMENT')"  class="ti-btn ti-btn-danger-full  w-full"> Excel</button>

                                </div>

                                <div class="space-y-2">
                                    <button v-if="!viewall" @click="print4" class="ti-btn ti-btn-success-full  w-full"> Imprimer</button>

                                </div>

                            </div>





                            <div v-if="viewall" class="grid lg:grid-cols-2 gap-6">


                                <div class="space-y-2">
                                    <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Mois</label>
                                    <select v-model="mmois" id="inputState123" class="form-select !py-[0.59rem]">
                                        <option disabled value="">Veuillez sélectionner un mois</option>
                                        <option value="01">Janvier</option>
                                        <option value="02">Février</option>
                                        <option value="03">Mars</option>
                                        <option value="04">Avril</option>
                                        <option value="05">Mai</option>
                                        <option value="06">Juin</option>
                                        <option value="07">Juillet</option>
                                        <option value="08">Août</option>
                                        <option value="09">Septembre</option>
                                        <option value="10">Octobre</option>
                                        <option value="11">Novembre</option>
                                        <option value="12">Décembre</option>
                                    </select>
                                    <span class="BanqueError text-red-500 text-xs hidden">error</span>
                                </div>

                                <div class="space-y-2">
                                    <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Année</label>
                                    <select v-model="manne" id="inputState1254" class="form-select !py-[0.59rem]">
                                        <option disabled value="">Veuillez sélectionner une année</option>
                                        <option v-for="(year,index) in mgetyear" :key="index" :value="year">{{year}}</option>

                                    </select>
                                    <span class="BanqueError text-red-500 text-xs hidden">error</span>
                                </div>


                                    <button @click="refresh" class="ti-btn ti-btn-primary-full"> Actualiser</button>
                                    <button @click="print4" class="ti-btn ti-btn-success-full"> Imprimer</button>



                            </div>

                            <div class="table-responsive mybodyheight200px">


                                            <div  class="my-5 page pagebreak"  >


                                                <div class="p-5">

                                                    <section class="top-content mb-5 bb d-flex justify-content-between">
                                                        <div class="logo">
                                                            <img v-if="$store.state.etatlogo" :src="$store.state.etatlogo" alt="" class="img-fluid">
                                                        </div>
                                                        <div class="top-left">
                                                            <div class="logo">
                                                                <img :src="'https://chart.googleapis.com/chart?cht=qr&chl=hpaie&chs=100x100&chld=L|0'" alt="" class="img-fluid">
                                                            </div>
                                                        </div>
                                                    </section>

                                                    <section class="mt-5">
                                                        <div class="grid lg:grid-cols-2 gap-6">

                                                            <div class="col-6">
                                                                <table class="table table-bordered">
                                                                    <tbody >

                                                                    <tr>
                                                                        <td >Raison Social : {{$store.state.etatraisonsocial}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td >Ifu : {{$store.state.etatifu}} /  RC N° RCCM: {{$store.state.etatrccm}} </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td >Téléphone : {{$store.state.etattelephone}} / {{$store.state.etatadresse}}</td>
                                                                    </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="col-6 ">


                                                                <table class="table table-bordered w-full">
                                                                    <tbody >
                                                                    <tr>
                                                                        <td style="text-align: center" colspan="3" >LIVRE DE DETAILLE</td>

                                                                    </tr>
                                                                    <tr>
                                                                        <td style="text-align: center">NUMERO</td>
                                                                        <td style="text-align: center">DATE D'EDITION</td>
                                                                        <td style="text-align: center">TIRE PAR</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style="text-align: center">---</td>
                                                                        <td style="text-align: center">{{datenow}}</td>
                                                                        <td style="text-align: center">{{$store.state.appusername}}</td>
                                                                    </tr>




                                                                    </tbody>
                                                                </table>


                                                            </div>

                                                        </div>






                                                    </section>

                                                    <section   class="product-area mt-3">



                                                        <table ref="exportable_table" class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline mytable">
                                                            <thead>
                                                            <tr>
                                                                <th  >Mois et Année</th>
                                                                <th v-if="activecolumsligne1">N°CNSS</th>
                                                                <th v-if="activecolumsligne2">Employé</th>
                                                                <th v-if="activecolumsligne3">Salaire de base</th>
                                                                <th v-if="activecolumsligne4">Salaire brut</th>
                                                                <th v-if="activecolumsligne5">Mode de réglement</th>
                                                                <th v-if="activecolumsligne6">Taux CNSS</th>
                                                                <th v-if="activecolumsligne7">Jour ouvrés</th>
                                                                <th v-if="activecolumsligne8">Heure supplémentaire</th>
                                                                <th v-if="activecolumsligne9">Montant cnss</th>
                                                                <th v-if="activecolumsligne14">Montant ITS</th>
                                                                <th v-if="activecolumsligne15">Montant cnss/Patronale</th>
                                                                <th v-if="activecolumsligne16">Montant VPS</th>
                                                                <th v-if="activecolumsligne10">Mensualité prêt</th>
                                                                <th v-if="activecolumsligne11">Avance sur salaire</th>
                                                                <th v-if="activecolumsligne12">Net à payer</th>
                                                                <th v-if="activecolumsligne13">Service</th>

                                                            </tr>
                                                            </thead>


                                                            <tbody>
                                                            <template v-if="filterdatatable.length">

                                                                <tr style="font-weight: 600;/*background: khaki;*/"
                                                                    v-for="(datavalue,index) in filterdatatable" :key="index">

                                                                    <td  data-label="Mois et Année  : ">{{ datavalue.moisanne }}</td>
                                                                    <td v-if="activecolumsligne1" data-label="N°CNSS  : ">{{ datavalue.cnss }}</td>
                                                                    <td v-if="activecolumsligne2" data-label="Employé  : ">{{ datavalue.nomprenom }}</td>
                                                                    <td v-if="activecolumsligne3" data-label="Salaire de base  : ">{{ formatMoney(datavalue.salairedebase) }}</td>
                                                                    <td v-if="activecolumsligne4" data-label="Salaire brut  : ">{{ formatMoney(datavalue.montantsalairebrut) }}</td>
                                                                    <td v-if="activecolumsligne5" data-label="Mode de réglement  : ">{{ datavalue.modereglement }}</td>
                                                                    <td v-if="activecolumsligne6" data-label="Taux CNSS  : ">{{ datavalue.tauxcnss }}</td>
                                                                    <td v-if="activecolumsligne7" data-label="Jour ouvrés : ">{{ datavalue.jourouvre }}</td>
                                                                    <td v-if="activecolumsligne8" data-label="Heure supplémentaire  : ">{{ formatMoney(datavalue.heuresupplementaire) }}</td>
                                                                    <td v-if="activecolumsligne9" data-label="Montant cnss  : ">{{ formatMoney(datavalue.montantcnss) }}</td>
                                                                    <td v-if="activecolumsligne14" data-label="Montant ITS  : ">{{ formatMoney(datavalue.montantits) }}</td>
                                                                    <td v-if="activecolumsligne15" data-label="Montant cnss/Patronale : ">{{ formatMoney(datavalue.montantcnsspatronale) }}</td>
                                                                    <td v-if="activecolumsligne16" data-label="Montant VPS  : ">{{ formatMoney(datavalue.montantvps) }}</td>
                                                                    <td v-if="activecolumsligne10" data-label="Mensualité prêt  : ">{{ formatMoney(datavalue.mensualitepret) }}</td>
                                                                    <td v-if="activecolumsligne11" data-label="Avance sur salaire  : ">{{ formatMoney(datavalue.avancesursalaire) }}</td>
                                                                    <td v-if="activecolumsligne12" data-label="Net à payer  : ">{{ formatMoney(datavalue.netapayer) }}</td>
                                                                    <td v-if="activecolumsligne13" data-label="Service  : ">{{ datavalue.service }}</td>

                                                                </tr>


                                                            </template>



                                                            <template v-else>
                                                                <tr >
                                                                    <td colspan="18"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                                                </tr>

                                                            </template>

                                                            <tr >

                                                                <td data-label="Mois et Année  : "></td>
                                                                <td v-if="activecolumsligne1" data-label="N°CNSS  : "></td>
                                                                <td v-if="activecolumsligne2" data-label="Employé  : "></td>
                                                                <td v-if="activecolumsligne3" data-label="Total Salaire de base  : ">{{formatMoney(calculsalairedebase)}}</td>
                                                                <td v-if="activecolumsligne4" data-label="Total Salaire brut  : ">{{formatMoney(calculmontantsalairebrut)}}</td>
                                                                <td v-if="activecolumsligne5" data-label="Mode de réglement  : "></td>
                                                                <td v-if="activecolumsligne6" data-label="Taux CNSS  : "></td>
                                                                <td v-if="activecolumsligne7" data-label="Jour ouvrés : "></td>
                                                                <td v-if="activecolumsligne8" data-label="Total Heure supplémentaire  : ">{{formatMoney(calculheuresupplementaire)}}</td>
                                                                <td v-if="activecolumsligne9" data-label="Total Montant cnss  : ">{{formatMoney(calculmontantcnss)}}</td>
                                                                <td v-if="activecolumsligne14" data-label="Total Montant its  : ">{{formatMoney(calculmontantits)}}</td>
                                                                <td v-if="activecolumsligne15" data-label="Total Montant cnss/Patronale  : ">{{formatMoney(calculmontantcnsspatronale)}}</td>
                                                                <td v-if="activecolumsligne16" data-label="Total Montant vps  : ">{{formatMoney(calculmontantvps)}}</td>
                                                                <td v-if="activecolumsligne10" data-label="Total Mensualité prêt  : ">{{formatMoney(calculmensualitepret)}}</td>
                                                                <td v-if="activecolumsligne11" data-label="Total Avance sur salaire  : ">{{formatMoney(calculavancesursalaire)}}</td>
                                                                <td v-if="activecolumsligne12" data-label="Total Net à payer  : ">{{formatMoney(calculnetapayer)}}</td>
                                                                <td v-if="activecolumsligne13" data-label="Service  : "></td>

                                                            </tr>


                                                            </tbody>

                                                        </table>



                                                    </section>

                                                    <section class="balance-info">
                                                        <div class="row">

                                                        </div>
                                                    </section>
                                                    <section class="balance-info mt-3">
                                                        <div class="row">

                                                        </div>
                                                    </section>


                                                    <!--footer>
                                                        <hr>
                                                        <p class="m-0 text-center">
                                                            RC N° RCCM:RB/COT/13-A18181 IFU N°:1201301296109 Tél: (229) XX XX XX XX / XX XX XX XX Cotonou Rép du BENIN
                                                        </p>

                                                    </footer-->










                                                </div>




                                            </div>




                            </div>


                        </div>





                        <div class="flex flex-col box-footer border-t-0 ">
                            <div class="flex items-center flex-wrap overflow-auto">
                                <div class="mb-2 sm:mb-0">

                                </div>
                                <div class="ms-auto">


                                    <nav aria-label="Page navigation">
                                        <ul class="ti-pagination mb-0">
                                            <li class="page-item">
                                                <button :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]" @click="page--">Previous</button>
                                            </li>
                                            <li class="page-item">
                                                <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                        :key="key" v-for="(pageNumber, key) in displayedPages" @click="page = pageNumber">
                                                    {{ pageNumber }}
                                                </button>
                                            </li>
                                            <li class="page-item">
                                                <button :class="{ 'disabled': page >= pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++">Next</button>
                                            </li>
                                        </ul>
                                    </nav>





                                </div>
                            </div>
                        </div>
                    </div>






                </div>
                <div class="ti-modal-footer">

                    <button @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" style="background: red" >
                        Fermer
                    </button>


                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



    <div class="row" v-show="false">

        <div id="invoice">
            <page id="printOrder-data1"  class="my-5 page printOrder-data " :size="taillemodel">


                    <div   class="my-5 page printOrder-data pagebreak"  >


                        <div class="p-5">

                            <section class="top-content mb-5 bb d-flex justify-content-between">
                                <div class="logo">
                                    <img v-if="$store.state.etatlogo" :src="$store.state.etatlogo" alt="" class="img-fluid">
                                </div>
                                <div class="top-left">
                                    <div class="logo">
                                        <img :src="'https://chart.googleapis.com/chart?cht=qr&chl=hpaie&chs=100x100&chld=L|0'" alt="" class="img-fluid">
                                    </div>
                                </div>
                            </section>

                            <section class="mt-5">
                                <div class="row">

                                    <div class="col-6">
                                        <table class="table table-bordered">
                                            <tbody >

                                            <tr>
                                                <td >Raison Social : {{$store.state.etatraisonsocial}}</td>
                                            </tr>
                                            <tr>
                                                <td >Ifu : {{$store.state.etatifu}} /  RC N° RCCM: {{$store.state.etatrccm}} </td>
                                            </tr>
                                            <tr>
                                                <td >Téléphone : {{$store.state.etattelephone}} / {{$store.state.etatadresse}}</td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-6 ">


                                        <table class="table table-bordered">
                                            <tbody >
                                            <tr>
                                                <td style="text-align: center" colspan="3" >LIVRE DE DETAILLE</td>

                                            </tr>
                                            <tr>
                                                <td style="text-align: center">NUMERO</td>
                                                <td style="text-align: center">DATE D'EDITION</td>
                                                <td style="text-align: center">TIRE PAR</td>
                                            </tr>
                                            <tr>
                                                <td style="text-align: center">---</td>
                                                <td style="text-align: center">{{datenow}}</td>
                                                <td style="text-align: center">{{$store.state.appusername}}</td>
                                            </tr>




                                            </tbody>
                                        </table>


                                    </div>

                                </div>






                            </section>

                            <section   class="product-area mt-3">



                                <table ref="exportable_table" class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline mytable">
                                    <thead>
                                    <tr>
                                        <th  >Mois et Année</th>
                                        <th v-if="activecolumsligne1">N°CNSS</th>
                                        <th v-if="activecolumsligne2">Employé</th>
                                        <th v-if="activecolumsligne3">Salaire de base</th>
                                        <th v-if="activecolumsligne4">Salaire brut</th>
                                        <th v-if="activecolumsligne5">Mode de réglement</th>
                                        <th v-if="activecolumsligne6">Taux CNSS</th>
                                        <th v-if="activecolumsligne7">Jour ouvrés</th>
                                        <th v-if="activecolumsligne8">Heure supplémentaire</th>
                                        <th v-if="activecolumsligne9">Montant cnss</th>
                                        <th v-if="activecolumsligne14">Montant ITS</th>
                                        <th v-if="activecolumsligne15">Montant cnss/Patronale</th>
                                        <th v-if="activecolumsligne16">Montant VPS</th>
                                        <th v-if="activecolumsligne10">Mensualité prêt</th>
                                        <th v-if="activecolumsligne11">Avance sur salaire</th>
                                        <th v-if="activecolumsligne12">Net à payer</th>
                                        <th v-if="activecolumsligne13">Service</th>

                                    </tr>
                                    </thead>


                                    <tbody>
                                    <template v-if="filterdatatable.length">

                                        <tr style="font-weight: 600;/*background: khaki;*/"
                                            v-for="(datavalue,index) in filterdatatable" :key="index">

                                            <td  data-label="Mois et Année  : ">{{ datavalue.moisanne }}</td>
                                            <td v-if="activecolumsligne1" data-label="N°CNSS  : ">{{ datavalue.cnss }}</td>
                                            <td v-if="activecolumsligne2" data-label="Employé  : ">{{ datavalue.nomprenom }}</td>
                                            <td v-if="activecolumsligne3" data-label="Salaire de base  : ">{{ formatMoney(datavalue.salairedebase) }}</td>
                                            <td v-if="activecolumsligne4" data-label="Salaire brut  : ">{{ formatMoney(datavalue.montantsalairebrut) }}</td>
                                            <td v-if="activecolumsligne5" data-label="Mode de réglement  : ">{{ datavalue.modereglement }}</td>
                                            <td v-if="activecolumsligne6" data-label="Taux CNSS  : ">{{ datavalue.tauxcnss }}</td>
                                            <td v-if="activecolumsligne7" data-label="Jour ouvrés : ">{{ datavalue.jourouvre }}</td>
                                            <td v-if="activecolumsligne8" data-label="Heure supplémentaire  : ">{{ formatMoney(datavalue.heuresupplementaire) }}</td>
                                            <td v-if="activecolumsligne9" data-label="Montant cnss  : ">{{ formatMoney(datavalue.montantcnss) }}</td>
                                            <td v-if="activecolumsligne14" data-label="Montant ITS  : ">{{ formatMoney(datavalue.montantits) }}</td>
                                            <td v-if="activecolumsligne15" data-label="Montant cnss/Patronale : ">{{ formatMoney(datavalue.montantcnsspatronale) }}</td>
                                            <td v-if="activecolumsligne16" data-label="Montant VPS  : ">{{ formatMoney(datavalue.montantvps) }}</td>
                                            <td v-if="activecolumsligne10" data-label="Mensualité prêt  : ">{{ formatMoney(datavalue.mensualitepret) }}</td>
                                            <td v-if="activecolumsligne11" data-label="Avance sur salaire  : ">{{ formatMoney(datavalue.avancesursalaire) }}</td>
                                            <td v-if="activecolumsligne12" data-label="Net à payer  : ">{{ formatMoney(datavalue.netapayer) }}</td>
                                            <td v-if="activecolumsligne13" data-label="Service  : ">{{ datavalue.service }}</td>

                                        </tr>


                                    </template>



                                    <template v-else>
                                        <tr >
                                            <td colspan="18"><div class="alert alert-danger">Aucune donnée trouvée</div></td>
                                        </tr>

                                    </template>

                                    <tr >

                                        <td data-label="Mois et Année  : "></td>
                                        <td v-if="activecolumsligne1" data-label="N°CNSS  : "></td>
                                        <td v-if="activecolumsligne2" data-label="Employé  : "></td>
                                        <td v-if="activecolumsligne3" data-label="Total Salaire de base  : ">{{formatMoney(calculsalairedebase)}}</td>
                                        <td v-if="activecolumsligne4" data-label="Total Salaire brut  : ">{{formatMoney(calculmontantsalairebrut)}}</td>
                                        <td v-if="activecolumsligne5" data-label="Mode de réglement  : "></td>
                                        <td v-if="activecolumsligne6" data-label="Taux CNSS  : "></td>
                                        <td v-if="activecolumsligne7" data-label="Jour ouvrés : "></td>
                                        <td v-if="activecolumsligne8" data-label="Total Heure supplémentaire  : ">{{formatMoney(calculheuresupplementaire)}}</td>
                                        <td v-if="activecolumsligne9" data-label="Total Montant cnss  : ">{{formatMoney(calculmontantcnss)}}</td>
                                        <td v-if="activecolumsligne14" data-label="Total Montant its  : ">{{formatMoney(calculmontantits)}}</td>
                                        <td v-if="activecolumsligne15" data-label="Total Montant cnss/Patronale  : ">{{formatMoney(calculmontantcnsspatronale)}}</td>
                                        <td v-if="activecolumsligne16" data-label="Total Montant vps  : ">{{formatMoney(calculmontantvps)}}</td>
                                        <td v-if="activecolumsligne10" data-label="Total Mensualité prêt  : ">{{formatMoney(calculmensualitepret)}}</td>
                                        <td v-if="activecolumsligne11" data-label="Total Avance sur salaire  : ">{{formatMoney(calculavancesursalaire)}}</td>
                                        <td v-if="activecolumsligne12" data-label="Total Net à payer  : ">{{formatMoney(calculnetapayer)}}</td>
                                        <td v-if="activecolumsligne13" data-label="Service  : "></td>

                                    </tr>


                                    </tbody>

                                </table>



                            </section>

                            <section class="balance-info">
                                <div class="row">

                                </div>
                            </section>
                            <section class="balance-info mt-3">
                                <div class="row">

                                </div>
                            </section>


                            <!--footer>
                                <hr>
                                <p class="m-0 text-center">
                                    RC N° RCCM:RB/COT/13-A18181 IFU N°:1201301296109 Tél: (229) XX XX XX XX / XX XX XX XX Cotonou Rép du BENIN
                                </p>

                            </footer-->










                        </div>



                    </div>


            </page>

        </div>

    </div>






</template>

<script>
    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import {currencyFormatDE, currentDate, currentDateTime, ExportExcel, getYear, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');

    export default {
        /* eslint-disable */
        name: "formsupp",

        watch: {
            searchword: function(newvalue,oldvalue){

                this.page=1

            },
            searchtrierletableau: function(newvalue,oldvalue){

                this.page=1

            }

        },
        computed:{

            viewall(){
                if (this.mmois==="" || this.manne===""){

                    return true

                }else{

                    return false

                }


            },


            displayedPages() {
                const start = Math.max(1, this.page - 5);
                const end = Math.min(this.pages.length, start + 9);

                let result = [];

                if (start > 1) {
                    result.push(1);
                    if (start > 2) {
                        result.push('...');
                    }
                }

                for (let i = start; i <= end; i++) {
                    result.push(i);
                }

                if (end < this.pages.length) {
                    if (end < this.pages.length - 1) {
                        result.push('...');
                    }
                    result.push(this.pages.length);
                }

                return result;
            },
            filterdatatable(){

                let mvalue=this.datatable;

                //this.page=this.Defaultpage;
                this.perPage=this.DefaultperPage;

               /* if (this.searchtrierletableau!==''){
                    let t=this;

                    mvalue = mvalue.filter( function (post) {

                        return   post.etatcontrat===parseFloat(t.searchtrierletableau)

                    })

                }*/


                if (this.searchword!==''){
                    let t=this;


                    mvalue = mvalue.filter( function (post) {
                        return    post.nomprenom.toLowerCase().includes(t.searchword.toLowerCase())
                        /*||
                        post.nameclient.toLowerCase().includes(t.searchword.toLowerCase()) ||
                        post.numbon.toLowerCase().includes(t.searchword.toLowerCase())*/
                    })

                }

                let  t=this
                t.calculsalairedebase=0
                t.calculmontantsalairebrut=0
                t.calculheuresupplementaire=0
                t.calculmontantcnss=0
                t.calculmontantits=0
                t.calculmensualitepret=0
                t.calculavancesursalaire=0
                t.calculmontantcnsspatronale=0
                t.calculmontantvps=0
                t.calculnetapayer=0

                mvalue.forEach(function (value) {

                    t.calculsalairedebase=t.calculsalairedebase+value.salairedebase
                    t.calculmontantsalairebrut=parseInt(t.calculmontantsalairebrut)+parseInt(value.montantsalairebrut)
                    t.calculheuresupplementaire=t.calculheuresupplementaire+value.heuresupplementaire
                    t.calculmontantcnss=t.calculmontantcnss+value.montantcnss
                    t.calculmontantits=t.calculmontantits+value.montantits
                    t.calculmensualitepret=t.calculmensualitepret+value.mensualitepret
                    t.calculavancesursalaire=t.calculavancesursalaire+value.avancesursalaire
                    t.calculmontantcnsspatronale=t.calculmontantcnsspatronale+parseInt(value.montantcnsspatronale)
                    t.calculmontantvps=t.calculmontantvps+parseInt(value.montantvps)
                    t.calculnetapayer=t.calculnetapayer+value.netapayer


                })





                // return this.paginate(mvalue) ;
                return mvalue ;
            },
        },
        components: {
            templatesite,
            appheader,
            appsidebar,
            /*appmenu,*/

        },

        data() {
            return {
                mmois:"",
                manne:"",
                mfontsize:10,
                taillemodel:'A4',
                mgetyear:getYear(),
                datenow:currentDate(),
                isdisplay:false,
                titlemodal:"",
                datatable:[],
                typbulletin:"",
                messageerror:"",
                messagesucces:"",
                viewdrop:false,



                activecolumsligne1:true,
                activecolumsligne2:true,
                activecolumsligne3:true,
                activecolumsligne4:false,
                activecolumsligne5:false,
                activecolumsligne6:true,
                activecolumsligne7:false,
                activecolumsligne8:false,
                activecolumsligne9:false,
                activecolumsligne10:false,
                activecolumsligne11:false,
                activecolumsligne12:true,
                activecolumsligne13:true,
                activecolumsligne14:true,
                activecolumsligne15:true,
                activecolumsligne16:true,


                calculsalairedebase:0,
                calculmontantsalairebrut:0,
                calculheuresupplementaire:0,
                calculmontantcnss:0,
                calculmontantits:0,
                calculmensualitepret:0,
                calculavancesursalaire:0,
                calculnetapayer:0,
                calculmontantcnsspatronale:0,
                calculmontantvps:0,





                searchword:"",
                searchtrierletableau:"",

                posts : [''],
                page: 1,
                // perPage: 100,
                perPage: 1,
                Defaultpage: 1,
                DefaultperPage: 1,
                pages: [],
            };
        },
        methods: {

            btnviewdrop(){

                if(this.viewdrop===true){

                    this.viewdrop=false;

                }else{

                    this.viewdrop=true;

                }



            },


            ExportExcel(type,name, fn, dl) {
               name=name+"_"+currentDateTime()
                var elt = this.$refs.exportable_table;
                ExportExcel(type,name, fn, dl,elt);

            },



            formatMoney(value){
                return currencyFormatDE(value)

            },


            formatnumber(value){
                return FormatNumber(value)

            },



            async print4 () {

                let t=this

                let options = {  name: '_blank', }


                if (this.taillemodel==="A4landscape" ){

                    options = {
                        name: '_blank',
                        specs: [
                            'fullscreen=yes',
                            'titlebar=yes',
                            'scrollbars=yes'
                        ],
                        styles: [
                            process.env.VUE_APP_URL+'print/bootstrap.min.css',
                            process.env.VUE_APP_URL+'print/stylelandscape1.css',
                            process.env.VUE_APP_URL+'print/stylelandscape.css'
                        ],


                        replace: true,
                        // timeout: 1000, // default timeout before the print window appears
                        autoClose: false, // if false, the window will not close after printing
                        windowTitle: window.document.title, // override the window title




                    }
                }

                if (this.taillemodel==="A4" ){

                    options = {
                        name: '_blank',
                        specs: [
                            'fullscreen=yes',
                            'titlebar=yes',
                            'scrollbars=yes'
                        ],
                        styles: [
                            process.env.VUE_APP_URL+'print/bootstrap.min.css',
                            process.env.VUE_APP_URL+'print/styleportrait.css',
                            process.env.VUE_APP_URL+'print/styleportraisizebulletin.css'
                        ],


                        replace: true,
                        // timeout: 1000, // default timeout before the print window appears
                        autoClose: false, // if false, the window will not close after printing
                        windowTitle: window.document.title, // override the window title




                    }
                }


                 this.$htmlToPaper('printOrder-data1', options, () => {
                    console.log('Printing completed or was cancelled!');
                });

            },




            returnFloatconvert(value){

                //console.log('value ',value)

                let mval= parseFloat(value)

                return mval.toFixed(4)
            },

            close(){

                this.isdisplay=false;

                //this.$emit('closed');

            },

            selected(datavalue){


                // Suppose que vous avez un objet représentant l'élément sélectionné
                const elementSelectionne = { typ: this.typedoc, value: datavalue};

                // Émettre un événement personnalisé avec l'élément sélectionné
                this.$emit('elementSelectionne', elementSelectionne);

                // Fermer le modal ou effectuer d'autres actions nécessaires
                this.isdisplay=false;
            },


            checkZero(i)
            {
                if (i < 10)
                {
                    i = "0" + i
                };  // add zero in front of numbers < 10

                return i;
            },

           async show(typbulletin,mmois,manne){

                this.typbulletin=typbulletin
                this.mmois=this.checkZero(mmois)
                this.manne=manne

                this.titlemodal="LIVRE DE PAIE DETAILLE"


                this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74dkjskdj5874edition771714dfe/livredepaiedetaille/'+this.$store.state.appsociete+'/'+this.mmois+'/'+manne+'/'+typbulletin+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })
               /* let response =   axios  .get(url,{__p:typbulletin,__d:Itemdatas,__tt:typbulletin},{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })*/

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)
                        t.datatable=[];

                        if(res.data.success){

                            t.datatable=res.data.m;

                            t.isdisplay=true;

                        }
                        else{
                            t.datatable=[];
                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })













            },




           async refresh(){

                /*this.typbulletin=typbulletin
                this.mmois=this.checkZero(mmois)
                this.manne=manne*/

              //  this.titlemodal="Bulletin de paie"


                this.$store.dispatch('lancerchargement',true)
                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74dkjskdj5874edition771714dfe/paie/'+this.$store.state.appsociete+'/'+this.mmois+'/'+this.manne+'/'+this.typbulletin+'/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res= await response
                    .then(function (res){
                        t.$store.dispatch('lancerchargement',false)
                        t.datatable=[];

                        if(res.data.success){

                            t.datatable=res.data.m;

                            t.isdisplay=true;

                        }
                        else{
                            t.datatable=[];
                        }


                    })
                    .catch((error)=>{

                            t.$store.dispatch('lancerchargement',false)
                        }
                    )
                    .finally(() =>{

                        t.$store.dispatch('lancerchargement',false)


                    })













            },






            //****************************************************************//

            setPages (posts) {
                this.pages=[];
                // let numberOfPages = Math.ceil(this.datatable.length / this.perPage);
                let numberOfPages = Math.ceil(posts.length / this.perPage);
                for (let index = 1; index <= numberOfPages; index++) {

                    this.pages.push(index);
                }
            },
            paginate (posts) {

                this.setPages(posts)
                let page = this.page;
                let perPage = this.perPage;
                let from = (page * perPage) - perPage;
                let to = (page * perPage);

                let $res=posts.slice(from, to)

                if ($res.length>0){
                    return $res;
                }else{

                    return posts;
                }

                // return  posts.slice(from, to);
            }



        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>

   .table td {
        cursor: pointer;
    }

    .modalstyle{
        max-width: 90%!important;
    }

    .h-screen{

        height: 300px!important;

    }

    .mybodyheight200px{
        height: 200px;
    }

    .mybodyheight300px{
        height: 300px;
    }

    .mybodyheight400px{
        height: 400px;
    }

    .mybodyheight500px{
        height: 500px;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


        .h-screen,.mybodyheight200px,.mybodyheight300px, .mybodyheight400px,.mybodyheight500px{
            height: 100%!important;
        }


    }

</style>