<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>

            <div class="content">
                <div class="main-content">
                    <!-- Page Header -->
                    <div class="block justify-between page-header md:flex">
                        <div>
                            <h3 class="!text-defaulttextcolor dark:!text-defaulttextcolor/70 dark:text-white dark:hover:text-white text-[1.125rem] font-semibold">TABLEAU DE BORD</h3>
                        </div>

                    </div>
                    <!-- Page Header Close -->

                    <div class="grid grid-cols-12 gap-x-6">
                        <div class="xxl:col-span-12 xl:col-span-12 col-span-12">
                            <div class="grid grid-cols-12 gap-x-6">
                                <div class="xxl:col-span-3 xl:col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-12 col-span-12">
                                    <div class="box hrm-main-card primary">
                                        <div class="box-body">
                                            <div class="flex items-start">
                                                <div class="me-4">
                                                <span class="avatar bg-primary !text-white">
                                                    <i class="ri-team-line text-[1.125rem]"></i>
                                                </span>
                                                </div>
                                                <div class="flex-grow">
                                                <span class="font-semibold text-[#8c9097] dark:text-white/50 block mb-1">Total employées</span>
                                                    <h5 class="font-semibold mb-1 text-[1.25rem]">{{nbretotalemploye}}</h5>
                                                    <!--p class="mb-0">
                                                        <span class="badge bg-primary/10 text-primary">This Month</span>
                                                    </p-->
                                                </div>
                                                <!--div>
                                                    <span class="text-[0.875rem] font-semibold text-success">+1.03%</span>
                                                </div-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="xxl:col-span-3 xl:col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-12 col-span-12">
                                    <div class="box hrm-main-card secondary">
                                        <div class="box-body">
                                            <div class="flex items-start">
                                                <div class="me-4">
                                                <span class="avatar bg-secondary !text-white">
                                                    <i class="ri-team-line text-[1.125rem]"></i>
                                                </span>
                                                </div>
                                                <div class="flex-grow">
                                                <span class="font-semibold text-[#8c9097] dark:text-white/50 block mb-1">Total permanents</span>
                                                    <h5 class="font-semibold mb-1 text-[1.25rem]">{{nbrepersonnel}}</h5>
                                                    <!--p class="mb-0">
                                                        <span class="badge bg-secondary/10 text-secondary">This Month</span>
                                                    </p-->
                                                </div>
                                                <!---div>
                                                    <span class="text-[0.875rem] font-semibold text-success">+0.36%</span>
                                                </div-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="xxl:col-span-3 xl:col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-12 col-span-12">
                                    <div class="box  hrm-main-card warning">
                                        <div class="box-body">
                                            <div class="flex items-start">
                                                <div class="me-4">
                                                <span class="avatar bg-warning !text-white">
                                                    <i class="ri-team-line text-[1.125rem]"></i>
                                                </span>
                                                </div>
                                                <div class="flex-grow">
                                                <span class="font-semibold text-[#8c9097] dark:text-white/50 block mb-1">Total
                                                    occasionnels</span>
                                                    <h5 class="font-semibold mb-1 text-[1.25rem]">{{nbreoccasionnel}}</h5>
                                                    <!--p class="mb-0">
                                                        <span class="badge bg-warning/10 text-warning">This Month</span>
                                                    </p-->
                                                </div>
                                                <!--div>
                                                    <span class="text-[0.875rem] font-semibold text-danger">-1.28%</span>
                                                </div-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="xxl:col-span-3 xl:col-span-3 lg:col-span-3 md:col-span-3 sm:col-span-12 col-span-12">
                                    <div class="box hrm-main-card danger">
                                        <div class="box-body">
                                            <div class="flex items-start">
                                                <div class="me-4">
                                                <span class="avatar bg-danger !text-white">
                                                    <i class="ri-user-unfollow-line text-[1.125rem]"></i>
                                                </span>
                                                </div>
                                                <div class="flex-grow">
                                                    <span class="font-semibold text-[#8c9097] dark:text-white/50 block mb-1">Permanent débaucher</span>
                                                    <h5 class="font-semibold mb-1 text-[1.25rem]">{{nbrepermanentdebauche}}</h5>
                                                   <span class="font-semibold text-[#8c9097] dark:text-white/50 block mb-1">Occasionnel débaucher</span>
                                                    <h5 class="font-semibold mb-1 text-[1.25rem]">{{nbreoccasionneldebaucher}}</h5>




                                                    <!--p class="mb-0">
                                                        <span class="badge bg-danger/10 text-danger">This Month</span>
                                                    </p-->
                                                </div>
                                                <!--div>
                                                    <span class="text-[0.875rem] font-semibold text-success">+4.25%</span>
                                                </div-->
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div class="xxl:col-span-6 xl:col-span-6 col-span-12">
                                    <div class="box">
                                        <div class="box-header">
                                            <div class="box-title">Evolution de la masse salariale net à payer des permanents sur
                                                les 6 derniers mois</div>
                                        </div>
                                        <div class="box-body !p-0">
                                            <div class="table-responsive">



                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="xxl:col-span-6 xl:col-span-6 col-span-12">
                                    <div class="box">
                                        <div class="box-header">
                                            <div class="box-title">Evolution de la masse salariale net à payer des occasionnels sur
                                                les 6 derniers mois</div>
                                        </div>
                                        <div class="box-body !p-0">
                                            <div class="table-responsive">




                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="xxl:col-span-6 xl:col-span-6 col-span-12">
                                    <div class="box">
                                        <div class="box-header">
                                            <div class="box-title">Demandes de congés non traitée</div>
                                        </div>
                                        <div class="box-body !p-0">
                                            <div class="table-responsive">
                                                <table class="table whitespace-nowrap min-w-full">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" class="text-start">Nom et prénom(s)</th>
                                                        <th scope="col" class="text-start">Nombre de Jour</th>
                                                        <th scope="col" class="text-start">Date début</th>
                                                        <th scope="col" class="text-start">Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                    <template v-if="datademandedecongenontraite.length">

                                                    <tr class="border-t border-defaultborder dark:border-defaultborder/10">



                                                        <td>diana.1116@demo.com</td>
                                                        <td>diana.1116@demo.com</td>
                                                        <td>diana.1116@demo.com</td>
                                                        <td>
                                                            <div class="flex flex-row items-center !gap-2 text-[0.9375rem]">
                                                                <button aria-label="anchor"
                                                                   class="ti-btn ti-btn-wave !gap-0 !m-0 !h-[1.75rem] !w-[1.75rem]
                                                                   text-[0.8rem] bg-primary/10 text-primary hover:bg-primary hover:text-white hover:border-primary"><i
                                                                        class="ri-eye-line"></i></button>

                                                            </div>
                                                        </td>

                                                    </tr>



                                                    </template>



                                                    <template v-else>
                                                        <tr >
                                                            <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                        </tr>

                                                    </template>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>



                                </div>


                                <div class="xxl:col-span-6 xl:col-span-6 col-span-12">
                                    <div class="box">
                                        <div class="box-header">
                                            <div class="box-title">Demandes d'absences non traitée</div>
                                        </div>
                                        <div class="box-body !p-0">
                                            <div class="table-responsive">
                                                <table class="table whitespace-nowrap min-w-full">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" class="text-start">Nom et prénom(s)</th>
                                                        <th scope="col" class="text-start">Nombre de Jour</th>
                                                        <th scope="col" class="text-start">Date début</th>
                                                        <th scope="col" class="text-start">Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <template v-if="datademandedeabsencenontraite.length">

                                                        <tr class="border-t border-defaultborder dark:border-defaultborder/10">



                                                            <td>diana.1116@demo.com</td>
                                                            <td>diana.1116@demo.com</td>
                                                            <td>diana.1116@demo.com</td>
                                                            <td>
                                                                <div class="flex flex-row items-center !gap-2 text-[0.9375rem]">
                                                                    <button aria-label="anchor"
                                                                            class="ti-btn ti-btn-wave !gap-0 !m-0 !h-[1.75rem] !w-[1.75rem]
                                                                   text-[0.8rem] bg-primary/10 text-primary hover:bg-primary hover:text-white hover:border-primary"><i
                                                                            class="ri-eye-line"></i></button>

                                                                </div>
                                                            </td>

                                                        </tr>



                                                    </template>

                                                    <template v-else>
                                                        <tr >
                                                            <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                        </tr>

                                                    </template>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="xxl:col-span-6 xl:col-span-6 lg:col-span-6 md:col-span-12 col-span-12">
                                    <div class="box">
                                        <div class="box-header">
                                            <div class="box-title">TO DO LIST</div>
                                        </div>
                                        <div class="box-body">


                                            <ul class="list-none timeline-widget mb-0 my-5">

                                                <template v-if="datatodolist.length>0">

                                                <li class="timeline-widget-list">
                                                    <div class="flex items-start">
                                                        <div class="me-[3rem] text-center">
                                                            <span class="block text-[1.25rem] font-semibold text-primary">02</span>
                                                            <span class="block text-[0.75rem] text-[#8c9097] dark:text-white/50">Mon</span>
                                                        </div>
                                                        <div class="flex flex-wrap flex-grow items-start justify-between">
                                                            <div>
                                                                <p class="mb-1 sm:text-truncate timeline-widget-content text-wrap">You have an announcement - Ipsum Est Diam Eirmod</p>
                                                                <p class="mb-0 text-[0.75rem] leading-none text-[#8c9097] dark:text-white/50">10:00AM<span class="badge bg-primary/10 text-primary ms-2">Announcement</span></p>
                                                            </div>
                                                            <div>
                                                                <div class="hs-dropdown ti-dropdown">
                                                                    <a aria-label="anchor" href="javascript:void(0);" class="text-[1rem] text-[#8c9097] dark:text-white/50">
                                                                        <i class="fe fe-more-vertical"></i>
                                                                    </a>
                                                                    <ul class="hs-dropdown-menu ti-dropdown-menu hidden">
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Week</a></li>
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Month</a></li>
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Year</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="timeline-widget-list">
                                                    <div class="flex items-start">
                                                        <div class="me-[3rem] text-center">
                                                            <span class="block text-[1.25rem] font-semibold text-primary">15</span>
                                                            <span class="block text-[0.75rem] text-[#8c9097] dark:text-white/50">Sun</span>
                                                        </div>
                                                        <div class="flex flex-wrap flex-grow items-start justify-between">
                                                            <div>
                                                                <p class="mb-1 sm:text-truncate timeline-widget-content text-wrap">National holiday - Vero Jayanti</p>
                                                                <p class="mb-0 text-[0.75rem] leading-none text-[#8c9097] dark:text-white/50"><span class="badge text-warning bg-warning/10">Holiday</span></p>
                                                            </div>
                                                            <div>
                                                                <div class="hs-dropdown ti-dropdown">
                                                                    <a aria-label="anchor" href="javascript:void(0);" class="text-[1rem] text-[#8c9097] dark:text-white/50">
                                                                        <i class="fe fe-more-vertical"></i>
                                                                    </a>
                                                                    <ul class="hs-dropdown-menu ti-dropdown-menu hidden">
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Week</a></li>
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Month</a></li>
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Year</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="timeline-widget-list">
                                                    <div class="flex items-start">
                                                        <div class="me-[3rem] text-center">
                                                            <span class="block text-[1.25rem] font-semibold text-primary">23</span>
                                                            <span class="block text-[0.75rem] text-[#8c9097] dark:text-white/50">Mon</span>
                                                        </div>
                                                        <div class="flex flex-wrap flex-grow items-start justify-between">
                                                            <div>
                                                                <p class="mb-1 sm:text-truncate timeline-widget-content text-wrap">John pup birthday - Team Member</p>
                                                                <p class="mb-4 text-[0.75rem] leading-none text-[#8c9097] dark:text-white/50">09:00AM<span class="badge text-success bg-success/10 ms-2">Birthday</span></p>
                                                                <p class="mb-1 sm:text-truncate timeline-widget-content text-wrap">Amet sed no dolor kasd - Et Dolores Tempor Erat</p>
                                                                <p class="mb-0 text-[0.75rem] leading-none text-[#8c9097] dark:text-white/50">04:00PM<span class="badge text-primary bg-primary/10 ms-2">Announcement</span></p>
                                                            </div>
                                                            <div>
                                                                <div class="hs-dropdown ti-dropdown">
                                                                    <a aria-label="anchor" href="javascript:void(0);" class="text-[1rem] text-[#8c9097] dark:text-white/50">
                                                                        <i class="fe fe-more-vertical"></i>
                                                                    </a>
                                                                    <ul class="hs-dropdown-menu ti-dropdown-menu hidden">
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Week</a></li>
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Month</a></li>
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Year</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="timeline-widget-list">
                                                    <div class="flex items-start">
                                                        <div class="me-[3rem] text-center">
                                                            <span class="block text-[1.25rem] font-semibold text-primary">31</span>
                                                            <span class="block text-[0.75rem] text-[#8c9097] dark:text-white/50">Tue</span>
                                                        </div>
                                                        <div class="flex flex-wrap flex-grow items-start justify-between">
                                                            <div>
                                                                <p class="mb-1 sm:text-truncate timeline-widget-content text-wrap">National Holiday - Dolore Ipsum</p>
                                                                <p class="mb-0 text-[0.75rem] leading-none text-[#8c9097] dark:text-white/50"><span class="badge text-warning bg-warning/10">Holiday</span></p>
                                                            </div>
                                                            <div>
                                                                <div class="hs-dropdown ti-dropdown">
                                                                    <a aria-label="anchor" href="javascript:void(0);" class="text-[1rem] text-[#8c9097] dark:text-white/50">
                                                                        <i class="fe fe-more-vertical"></i>
                                                                    </a>
                                                                    <ul class="hs-dropdown-menu ti-dropdown-menu hidden">
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Week</a></li>
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Month</a></li>
                                                                        <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                                               href="javascript:void(0);">Year</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                </template>

                                                <template v-else>

                                                    <li class="timeline-widget-list">
                                                        <div class="flex items-start">
                                                            <div class="me-[3rem] text-center">
                                                                <span class="block text-[1.25rem] font-semibold text-primary">-</span>
                                                                <span class="block text-[0.75rem] text-[#8c9097] dark:text-white/50">-</span>
                                                            </div>
                                                            <div class="flex flex-wrap flex-grow items-start justify-between">
                                                                <div>
                                                                    <p class="mb-1 sm:text-truncate timeline-widget-content text-wrap"><span style="color: red">Vous n'avez aucune en cours</span></p>

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </li>

                                                </template>

                                            </ul>



                                        </div>
                                    </div>
                                </div>


                                <div class="xxl:col-span-6 xl:col-span-6 col-span-12">
                                    <div class="box">
                                        <div class="box-header">
                                            <div class="box-title">Suivi des congés</div>
                                        </div>
                                        <div class="box-body !p-0">
                                            <div class="table-responsive">
                                                <table class="table whitespace-nowrap min-w-full">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" class="text-start">Nom et prénom(s)</th>
                                                        <th scope="col" class="text-start">Nombre de Jour de congé restant</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>

                                                    <template v-if="datasuiviconge.length">

                                                        <tr class="border-t border-defaultborder dark:border-defaultborder/10">

                                                            <td>diana.1116@demo.com</td>
                                                            <td>10</td>

                                                        </tr>


                                                    </template>

                                                    <template v-else>
                                                        <tr >
                                                            <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                        </tr>

                                                    </template>



                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div class="xxl:col-span-12 xl:col-span-12 col-span-12">
                                    <div class="box">
                                        <div class="box-header">
                                            <div class="box-title">Planning</div>



                                            <div class="inline-flex rounded-md shadow-sm" role="group" aria-label="Basic example">

                                                <button @click="btnpreviousplanning" type="button" class="ti-btn-group !border-0 !text-xs !py-2 !px-3 ti-btn-primary"><i class="ri-arrow-left-line"></i></button>
                                                <button @click="btnnextplanning" type="button" class="ti-btn-group !border-0 !text-xs !py-2 !px-3 ti-btn-primary"><i class="ri-arrow-right-line"></i></button>
                                            </div>



                                        </div>
                                        <div class="box-body !p-0">
                                            <div class="table-responsive">


                                                <template v-if="Object.keys(datacalendar).length">
                                                    <template v-if="Object.keys(datacalendar.moisjour).length">

                                                        <div class="table-responsive calendar-team calendar-div">
                                                            <table class="table table-sm w-full">
                                                                <thead>
                                                                <tr>
                                                                    <th rowspan="3" class=""></th>
                                                                    <th  :colspan="datacalendar.nbreday" class="header-month">
                                                                        {{datacalendar.month}} {{datacalendar.years}}
                                                                    </th>
                                                                </tr>

                                                                <tr>
                                                                    <th v-for="(lettremoisjours,ml) in datacalendar.lettremoisjours" class="header-day   " :class="ml===datacalendar.todaydate?'todayBorder':''" :key="ml">
                                                                        {{lettremoisjours}}
                                                                    </th>
                                                                </tr>

                                                                <tr>
                                                                    <th v-for="(moisjour,mi) in datacalendar.moisjour" class="header-day  "  :class="mi===datacalendar.todaydate?'todayBorder':''" :key="mi">
                                                                        {{moisjour}}
                                                                    </th>
                                                                </tr>


                                                                </thead>
                                                                <tbody>



                                                                <template v-for="(valuepersonnel,mj) in filtercalendar" :key="mj">

                                                                    <tr>
                                                                        <td style="text-align: left;font-size: 13px!important;padding: 9px;
                                                                                    color: black;text-decoration: underline;" colspan="100" class="service">
                                                                            <strong data-toggle="tooltip" title="" data-placement="right" data-original-title="hgroupesarl@gmail.com   ">
                                                                                Service / Direction :   {{valuepersonnel.service}}
                                                                            </strong>
                                                                        </td>
                                                                    </tr>

                                                                    <tr v-for="(infopersonnel,mz) in valuepersonnel.infoper" :key="mz">
                                                                        <td style="font-weight: 900;" class="text-left user fixedColumn">
                                                                            {{infopersonnel.name}}
                                                                            <span class="text-warning">
													</span>
                                                                        </td>



                                                                        <td v-for="(detailinfo,minf) in infopersonnel.detail" :key="minf" class="day table-bordered text-center rangePicker firstSelected selected"
                                                                            :data-userid="detailinfo.ussid" :data-date="detailinfo.title"
                                                                            data-toggle="popover" data-placement="auto" :data-title="detailinfo.title"
                                                                            data-content="<i class='fa fa-minus'></i>" data-original-title="" :title="detailinfo.title">
                                                                            <div class="cell-block">
                                                                                <div :class="'float-left w-100 h-100 cell-block '+detailinfo.color"></div>
                                                                            </div>
                                                                        </td>



                                                                    </tr>

                                                                </template>




                                                                </tbody>
                                                            </table>
                                                        </div>

                                                    </template>
                                                </template>


                                            </div>
                                        </div>

                                        <div class="box-footer">

                                            <div class="card-body">
                                                <div class="legend text-right">
                                                    <div class="mb-2">


                                                            <span class="badge text-white ml-1" style="background:#2ecc71!important;">
                                                                Présence
                                                          </span>

                                                            <span class="badge text-white ml-1" style="background:#d9edf7!important;color: black;">
                                                                Weekend
                                                          </span>


                                                        <span class="badge text-white ml-1" style="background:darkorange !important;">
                                                          Absences légales (Congés, absence justifié,...)
                                                      </span>
                                                        <span class="badge text-white ml-1" style="background:tomato !important;">
                                                          Absences non légales (absence non justifié,...)
                                                      </span>

                                                    </div>

                                                    <span class="badge label_unworked ml-1">Non travaillé</span>
                                                    <span class="badge label_holiday ml-1">Férié</span>
                                                    <span class="badge label-default legend label_pending ml-1">
                                    <i class="fa fa-pause-circle fa-fw text-warning"></i>En attente
                                     </span>
                                                    <span class="badge label_unworked ml-1"><i class="fa fa-circle fa-fw text-body"></i>Autre évènement</span>
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </div>



                                <div class="xxl:col-span-6 xl:col-span-6 col-span-12">
                                    <div class="box custom-box">
                                        <div class="box-header justify-between">
                                            <div class="box-title">
                                                Liste du personnel permanent en fin de contrat dans 6 mois
                                            </div>

                                        </div>
                                        <div class="box-body">
                                            <div class="table-responsive">
                                                <div class="flex flex-col h-screen">
                                                    <div class="flex-grow overflow-auto">


                                                        <table class="table whitespace-nowrap min-w-full">
                                                            <thead>





                                                            <tr class="border-b border-defaultborder">
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">NOM ET PRÉNOM(S)</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">SERVICE</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">DEBUT DE CONTRAT</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">FIN DE CONTRAT</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">NOMBRE DE <br> JOUR(S) RESTANT(S)</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">ACTION</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>


                                                            <template v-if="datatablefindecontratpermannent.length">

                                                                <tr class="border-t hover:bg-gray-200 dark:hover:bg-light" v-for="(datavalue,index) in datatablefindecontratpermannent" :key="index">



                                                                    <td  data-label="NOM ET PRENOM(S)  : "   >{{ datavalue.name }}</td>
                                                                    <td  data-label="SERVICE  : "   >{{ datavalue.service }}</td>
                                                                    <td  data-label="DATE DE PRISE DE SERVICE  : "   >{{ datavalue.debutcontrat }}</td>
                                                                    <td  data-label="DATE FIN DE CONTRAT  : " >{{ datavalue.fincontrat }}</td>
                                                                    <td  data-label="NOMBRE DE JOUR(S) RESTANT(S)  : " >{{ datavalue.jours_restants }}</td>
                                                                    <td data-label="ACTION">

                                                                    </td>
                                                                </tr>



                                                            </template>



                                                            <template v-else>
                                                                <tr >
                                                                    <td colspan="10"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                                </tr>

                                                            </template>



                                                            </tbody>
                                                        </table>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="box-footer border-t-0 hidden">

                                        </div>
                                    </div>
                                </div>




                            </div>
                        </div>

                    </div>
                    <div class="grid grid-cols-12 gap-x-6 col-span-12">

                    </div>
                    <!--div class="grid grid-cols-12 gap-x-6">
                        <div class="xl:col-span-12 col-span-12">
                            <div class="box">
                                <div class="box-header justify-between flex-wrap">
                                    <div class="box-title">
                                        Bills Summary
                                    </div>
                                    <div class="flex flex-wrap gap-2">
                                        <div class="me-3">
                                            <input class="ti-form-control form-control-sm" type="text" placeholder="Search Here" aria-label=".form-control-sm example">
                                        </div>
                                        <div class="hs-dropdown ti-dropdown">
                                            <a href="javascript:void(0);"
                                               class="ti-btn ti-btn-primary !bg-primary !text-white !py-1 !px-2 !text-[0.75rem] !m-0 !gap-0 !font-medium"
                                               aria-expanded="false">
                                                Sort By<i class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                                            </a>
                                            <ul class="hs-dropdown-menu ti-dropdown-menu hidden" role="menu">
                                                <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                       href="javascript:void(0);">New</a></li>
                                                <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                       href="javascript:void(0);">Popular</a></li>
                                                <li><a class="ti-dropdown-item !py-2 !px-[0.9375rem] !text-[0.8125rem] !font-medium block"
                                                       href="javascript:void(0);">Relevant</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="box-body">
                                    <div class="table-responsive">
                                        <table class="table table-hover whitespace-nowrap table-bordered min-w-full">
                                            <thead>
                                            <tr>
                                                <th scope="col" class="text-start">S.No</th>
                                                <th scope="col" class="text-start">Invoice ID</th>
                                                <th scope="col" class="text-start">Client</th>
                                                <th scope="col" class="text-start">Due Date</th>
                                                <th scope="col" class="text-start">Status</th>
                                                <th scope="col" class="text-start">Amount</th>
                                                <th scope="col" class="text-start">Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr class="border border-inherit border-solid hover:bg-gray-100 dark:border-defaultborder/10 dark:hover:bg-light">
                                                <td>1</td>
                                                <td>#INV111611</td>
                                                <td>
                                                    <div class="flex items-center leading-none">
                                                        <div class="me-2">
                                                            <span class="avatar avatar-md avatar-rounded">
                                                                <img src="assets/images/faces/1.jpg" alt="">
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span class="block font-semibold mb-1">Rena Mark</span>
                                                            <span class="text-[#8c9097] dark:text-white/50 text-[0.75rem]">renamark123@demo</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    14-05-2023
                                                </td>
                                                <td>
                                                    <span class="badge bg-success text-white">Paid</span>
                                                </td>
                                                <td>
                                                    $9,523
                                                </td>
                                                <td>
                                                    <div class="flex flex-row items-center !gap-2 text-[0.9375rem]">
                                                        <a aria-label="anchor" href="javascript:void(0);"
                                                           class="ti-btn ti-btn-icon ti-btn-wave !gap-0 !m-0 !h-[1.75rem] !w-[1.75rem] text-[0.8rem] bg-info/10 text-info hover:bg-info hover:text-white hover:border-info"><i
                                                                class="ri-pencil-line"></i></a>
                                                        <a aria-label="anchor" href="javascript:void(0);"
                                                           class="ti-btn ti-btn-icon ti-btn-wave !gap-0 !m-0 !h-[1.75rem] !w-[1.75rem] text-[0.8rem] bg-danger/10 text-danger hover:bg-danger hover:text-white hover:border-danger"><i
                                                                class="ri-delete-bin-line"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="border border-inherit border-solid hover:bg-gray-100 dark:border-defaultborder/10 dark:hover:bg-light">
                                                <td>2</td>
                                                <td>#INV111611</td>
                                                <td>
                                                    <div class="flex items-center leading-none">
                                                        <div class="me-2">
                                                            <span class="avatar avatar-md avatar-rounded">
                                                                <img src="assets/images/faces/4.jpg" alt="">
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span class="block font-semibold mb-1">Xong Yun</span>
                                                            <span class="text-[#8c9097] dark:text-white/50 text-[0.75rem]">xongyun2134@gmail.com</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    14-05-2023
                                                </td>
                                                <td>
                                                    <span class="badge bg-warning text-white">Pending</span>
                                                </td>
                                                <td>
                                                    $8,243
                                                </td>
                                                <td>
                                                    <div class="flex flex-row items-center !gap-2 text-[0.9375rem]">
                                                        <a aria-label="anchor" href="javascript:void(0);"
                                                           class="ti-btn ti-btn-icon ti-btn-wave !gap-0 !m-0 !h-[1.75rem] !w-[1.75rem] text-[0.8rem] bg-info/10 text-info hover:bg-info hover:text-white hover:border-info"><i
                                                                class="ri-pencil-line"></i></a>
                                                        <a aria-label="anchor" href="javascript:void(0);"
                                                           class="ti-btn ti-btn-icon ti-btn-wave !gap-0 !m-0 !h-[1.75rem] !w-[1.75rem] text-[0.8rem] bg-danger/10 text-danger hover:bg-danger hover:text-white hover:border-danger"><i
                                                                class="ri-delete-bin-line"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="border border-inherit border-solid hover:bg-gray-100 dark:border-defaultborder/10 dark:hover:bg-light">
                                                <td>3</td>
                                                <td>#INV147852</td>
                                                <td>
                                                    <div class="flex items-center leading-none">
                                                        <div class="me-2">
                                                            <span class="avatar avatar-md avatar-rounded">
                                                                <img src="assets/images/faces/6.jpg" alt="">
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span class="block font-semibold mb-1">Kane William</span>
                                                            <span class="text-[#8c9097] dark:text-white/50 text-[0.75rem]">william154@demo</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    16-05-2023
                                                </td>
                                                <td>
                                                    <span class="badge bg-primary text-white">Overdue</span>
                                                </td>
                                                <td>
                                                    $5,234
                                                </td>
                                                <td>
                                                    <div class="flex flex-row items-center !gap-2 text-[0.9375rem]">
                                                        <a aria-label="anchor" href="javascript:void(0);"
                                                           class="ti-btn ti-btn-icon ti-btn-wave !gap-0 !m-0 !h-[1.75rem] !w-[1.75rem] text-[0.8rem] bg-info/10 text-info hover:bg-info hover:text-white hover:border-info"><i
                                                                class="ri-pencil-line"></i></a>
                                                        <a aria-label="anchor" href="javascript:void(0);"
                                                           class="ti-btn ti-btn-icon ti-btn-wave !gap-0 !m-0 !h-[1.75rem] !w-[1.75rem] text-[0.8rem] bg-danger/10 text-danger hover:bg-danger hover:text-white hover:border-danger"><i
                                                                class="ri-delete-bin-line"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="border border-inherit border-solid hover:bg-gray-100 dark:border-defaultborder/10 dark:hover:bg-light">
                                                <td>4</td>
                                                <td>#INV753159</td>
                                                <td>
                                                    <div class="flex items-center leading-none">
                                                        <div class="me-2">
                                                            <span class="avatar avatar-md avatar-rounded">
                                                                <img src="assets/images/faces/12.jpg" alt="">
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span class="block font-semibold mb-1">Stewart Rem</span>
                                                            <span class="text-[#8c9097] dark:text-white/50 text-[0.75rem]">remstew092@demo</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    21-05-2023
                                                </td>
                                                <td>
                                                    <span class="badge bg-success text-white">Paid</span>
                                                </td>
                                                <td>
                                                    $7,546
                                                </td>
                                                <td>
                                                    <div class="flex flex-row items-center !gap-2 text-[0.9375rem]">
                                                        <a aria-label="anchor" href="javascript:void(0);"
                                                           class="ti-btn ti-btn-icon ti-btn-wave !gap-0 !m-0 !h-[1.75rem] !w-[1.75rem] text-[0.8rem] bg-info/10 text-info hover:bg-info hover:text-white hover:border-info"><i
                                                                class="ri-pencil-line"></i></a>
                                                        <a aria-label="anchor" href="javascript:void(0);"
                                                           class="ti-btn ti-btn-icon ti-btn-wave !gap-0 !m-0 !h-[1.75rem] !w-[1.75rem] text-[0.8rem] bg-danger/10 text-danger hover:bg-danger hover:text-white hover:border-danger"><i
                                                                class="ri-delete-bin-line"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="border border-inherit border-solid hover:bg-gray-100 dark:border-defaultborder/10 dark:hover:bg-light">
                                                <td>5</td>
                                                <td>#INV369874</td>
                                                <td>
                                                    <div class="flex items-center leading-none">
                                                        <div class="me-2">
                                                            <span class="avatar avatar-md avatar-rounded">
                                                                <img src="assets/images/faces/3.jpg" alt="">
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span class="block font-semibold mb-1">Elliot Yun</span>
                                                            <span class="text-[#8c9097] dark:text-white/50 text-[0.75rem]">eiiliot000@demo</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    18-05-2023
                                                </td>
                                                <td>
                                                    <span class="badge bg-primary text-white">Overdue</span>
                                                </td>
                                                <td>
                                                    $7,541
                                                </td>
                                                <td>
                                                    <div class="flex flex-row items-center !gap-2 text-[0.9375rem]">
                                                        <a aria-label="anchor" href="javascript:void(0);"
                                                           class="ti-btn ti-btn-icon ti-btn-wave !gap-0 !m-0 !h-[1.75rem] !w-[1.75rem] text-[0.8rem] bg-info/10 text-info hover:bg-info hover:text-white hover:border-info"><i
                                                                class="ri-pencil-line"></i></a>
                                                        <a aria-label="anchor" href="javascript:void(0);"
                                                           class="ti-btn ti-btn-icon ti-btn-wave !gap-0 !m-0 !h-[1.75rem] !w-[1.75rem] text-[0.8rem] bg-danger/10 text-danger hover:bg-danger hover:text-white hover:border-danger"><i
                                                                class="ri-delete-bin-line"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="border border-inherit border-solid hover:bg-gray-100 dark:border-defaultborder/10 dark:hover:bg-light">
                                                <td>6</td>
                                                <td>#INV123456</td>
                                                <td>
                                                    <div class="flex items-center leading-none">
                                                        <div class="me-2">
                                                            <span class="avatar avatar-md avatar-rounded">
                                                                <img src="assets/images/faces/5.jpg" alt="">
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span class="block font-semibold mb-1">Gretchen Xio</span>
                                                            <span class="text-[#8c9097] dark:text-white/50 text-[0.75rem]">gretchenxio999@demo</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    20-05-2023
                                                </td>
                                                <td>
                                                    <span class="badge bg-warning text-white">Pending</span>
                                                </td>
                                                <td>
                                                    $2,541
                                                </td>
                                                <td>
                                                    <div class="flex flex-row items-center !gap-2 text-[0.9375rem]">
                                                        <a aria-label="anchor" href="javascript:void(0);"
                                                           class="ti-btn ti-btn-icon ti-btn-wave !gap-0 !m-0 !h-[1.75rem] !w-[1.75rem] text-[0.8rem] bg-info/10 text-info hover:bg-info hover:text-white hover:border-info"><i
                                                                class="ri-pencil-line"></i></a>
                                                        <a aria-label="anchor" href="javascript:void(0);"
                                                           class="ti-btn ti-btn-icon ti-btn-wave !gap-0 !m-0 !h-[1.75rem] !w-[1.75rem] text-[0.8rem] bg-danger/10 text-danger hover:bg-danger hover:text-white hover:border-danger"><i
                                                                class="ri-delete-bin-line"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="box-footer">
                                    <div class="sm:flex items-center">
                                        <div class="text-defaulttextcolor/70">
                                            Showing 5 Entries <i class="bi bi-arrow-right ms-2 font-semibold"></i>
                                        </div>
                                        <div class="ms-auto">
                                            <nav aria-label="Page navigation" class="pagination-style-4">
                                                <ul class="ti-pagination mb-0">
                                                    <li class="page-item disabled">
                                                        <a class="page-link" href="javascript:void(0);">
                                                            Prev
                                                        </a>
                                                    </li>
                                                    <li class="page-item"><a class="page-link active" href="javascript:void(0);">1</a></li>
                                                    <li class="page-item"><a class="page-link" href="javascript:void(0);">2</a></li>
                                                    <li class="page-item">
                                                        <a class="page-link !text-primary" href="javascript:void(0);">
                                                            next
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div-->
                </div>
            </div>



        </template>

    </templatesite>



</template>

<script>

    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";


    import {currentMonth, currentYear, getYear, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');


    export default {
        /* eslint-disable */
        name: "index",
        components: {
            templatesite,
            appheader,
            appsidebar,

        },

        computed:{

            filtercalendar(){
                let mvalue=this.datafiltercalendar;

                if (this.searchwordcalendar!==''){

                    // ref
                    let t=this
                    //mvalue = this.datatablesearch.filter( post => post.magasin.toLowerCase().includes(this.searchword))
                    mvalue = mvalue.filter( function (post) {
                        //return    post.name.toLowerCase().includes(t.searchwordcalendar.toLowerCase())
                        return    post.service.toLowerCase().includes(t.searchwordcalendar.toLowerCase())
                    })
                    //return this.postList.filter( post => post.intitule.toLowerCase().includes(this.searchword))
                }

                return mvalue ;
            },


        },

        data() {
            return {

                datatablefindecontratpermannent:[],

                countp:0,
                allchurch:[],
                datamessageerror:[],
                messagesuccess:"",
                btnnextmonth:{mm:currentMonth(),manne:currentYear()},
                btnprevmonth:{mm:currentMonth(),manne:currentYear()},




                searchwordcalendar:'',
                searchtransaction:'',
                searchwordconge:'',
                searchwordabsence:'',
                nbrepersonnel:0,
                nbreoccasionnel:0,
                nbretotalemploye:0,
                nbrepermanentdebauche:0,
                nbreoccasionneldebaucher:0,
                listemassesalarialpermanent:[],

                mgetyear:getYear(),
                mmois:"",
                manne:"",
                dataPointObjects: [],
                datatodolist: [],
                datasuiviconge: [],
                datademandedecongenontraite: [],
                datademandedeabsencenontraite: [],
                datacalendar: {},
                datafiltercalendar: [],
                dataPointObjectsoccas: [],
                chartWidth: 600,
                chartHeight: 200,
               // url:this.$store.state.baseurl+'api/api_hpaie_v1.0.3.EXE',



                posts : [''],
                page: 1,
                perPage: 2,
                Defaultpage: 1,
                DefaultperPage: 2,
                pages: [],


                mposts : [''],
                mpage: 1,
                mperPage: 2,
                mDefaultpage: 1,
                mDefaultperPage: 2,
                mpages: [],












            };
        },
        methods: {

            btnpreviousplanning(){

                this.loadplanning(this.btnprevmonth.mm,this.btnprevmonth.manne);

            },
            btnnextplanning(){


                this.loadplanning(this.btnnextmonth.mm,this.btnnextmonth.manne);


            },

            async loaddata(){

                const t = this;

                //let response =   axios  .get(process.env.VUE_APP_API_URL+'/egl745874dsfd5771714dfe/'+allfunction.randomString())
                let response =   axios  .get(process.env.VUE_APP_API_URL+'/')
                let res= await response
                    .then(function (res){

                        console.log("res  ********* ",res)

                       /* if(res.data.res){
                            t.allchurch=res.data.m;
                        }else{
                            t.allchurch=[];
                        }

                        */


                    })
                    .catch((error)=>{
                            this.allchurch=[];


                        }
                    )
                    .finally(() =>{




                    })



            },

            async loaddatacountpersonnel(){


                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874tb771714dfe/countpers/'+this.$store.state.appsociete+'/'+randomString();
                let response = axios.get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })
                let res= await response
                    .then(function (res){


                        t.nbrepersonnel=res.data.m.allpermanents
                        t.nbreoccasionnel=res.data.m.alloccasionnels
                        t.nbretotalemploye=res.data.m.allemploye
                        t.nbrepermanentdebauche=res.data.m.alldebaucherpermanents
                        t.nbreoccasionneldebaucher=res.data.m.alldebaucheroccasionnels



                    })
                    .catch((error)=>{


                        }
                    )
                    .finally(() =>{




                    })



            },

            async loadplanning($month,$year){



                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874tb771714dfe/planning/'+this.$store.state.appsociete+'/'+$month+'/'+$year+'/'+randomString();
                let response = axios.get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })
                let res= await response
                    .then(function (res){

                        if(res.data[0].success){

                            t.datacalendar=res.data[0];

                            t.btnnextmonth=t.datacalendar.next
                            t.btnprevmonth=t.datacalendar.prev

                            t.datafiltercalendar=res.data[0].infoper

                        }
                        else{


                            t.datacalendar={}
                            t.datafiltercalendar=[]

                            t.btnnextmonth={mm:currentMonth(),manne:currentYear()}
                            t.btnprevmonth={mm:currentMonth(),manne:currentYear()}

                        }



                    })
                    .catch((error)=>{


                        }
                    )
                    .finally(() =>{




                    })



            },



            async loadfincontratdans6mois(){



                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl745874pdefinition771714dfe/tiers/personnel/'+this.$store.state.appsociete+'/findecontrat/personnel/'+randomString();
                let response =   axios  .get(url,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })

                let res= await response
                    .then(function (res){

                        t.datatablefindecontratpermannent=[];


                        if(res.data.success){

                            t.datatablefindecontratpermannent=res.data.m;

                        }
                        else{
                            t.datatablefindecontratpermannent=[];

                        }


                    })
                    .catch((error)=>{


                        }
                    )
                    .finally(() =>{




                    })


            },




        },
        mounted() {

            this.loadfincontratdans6mois();
            this.loaddatacountpersonnel();
            this.loadplanning(currentMonth(),currentYear());

        }






    }
</script>

<style scoped>

    /*********************  CALENDA  ********************/

    .calendar-team tbody tr td.service {
        background: #f7f7f7;
        color: #858585;
        border-top-color: #d0d0d0;
        border-right-color: #d0d0d0;
        border-left-color: #d0d0d0;
        padding-top: 1.5rem;
        font-size: 1.00625rem;
    }

    .out {
        display: block;
        width: 100%;
        height: 100%;
        padding-top: 4px;
        background: #eae9e9 !important;
        color: #ccc;
    }

    .calendar-team thead tr th.header-day {
        width: 30px;
        min-width: 30px;
        max-width: 30px;
        font-size: 12px;
        background: #f7f7f7;
        font-weight: normal;
        padding: 1px 5px;
        text-align: center;
        border: 1px solid #adadad;
    }
    .cell-block {
        width: 30px;
        height: 30px;
        overflow: hidden;
    }


    .calendar-team thead tr th.header-month {
        overflow: hidden;
        border: 1px solid #adadad;
    }
    .calendar-team>table>thead>tr {
        width: 30px;
        min-width: 30px;
        max-width: 30px;
    }
    .calendar-team>table {
        padding: 0rem !important;
    }

    .calendar-team .table-sm th, .table-sm td {
        padding: 0rem;
        /*font-size: 12px!important;*/
        font-size: 11px;
    }

    .calendar-planningok{
        background-color: #00B666;
    }

    .calendar-planningnonlegal{
        background-color: tomato;
    }


    .calendar-planninglegal{
        background-color: darkorange;
    }





    .calendar-planningnok{
        background-color: #ffffff;
    }


    .calendar-planningnweekend{
       /* background-color: pink;*/
        background-color: #d9edf7;

    }

    .todayBorder {
        border-left: #a3cbdf 2px solid !important;
        border-left-color: #a3cbdf !important;
        border-right: #a3cbdf 2px solid !important;
        border-right-color: #a3cbdf !important;
        background-color: red!important;
    }

    .calendar-div{
        height: 350px;
        overflow: auto;
    }


    .header-month{
        font-size: 16px!important;
        text-align: center;
        font-weight: bold;

    }


</style>