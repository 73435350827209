<template>
    <div class="col-span-12">
        <div class="box">
            <div class="box-header">
                <h5 class="box-title">---</h5>
            </div>
            <div class="box-body">
                <header class="flex flex-wrap sm:justify-start sm:flex-nowrap z-40 w-full bg-white text-sm dark:bg-bodybg">
                    <nav class="w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between" aria-label="Global">
                        <div class="flex items-center justify-between">
                            <router-link :to="{name: 'tbdefinition'}" class="header-logo">
                                Menu
                            </router-link>
                            <div class="sm:hidden">
                                <button type="button" class="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-2 rounded-sm border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-offset-white focus:ring-primary transition-all text-sm dark:bg-bodybg dark:hover:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-white dark:focus:ring-offset-white/10"
                                        data-hs-collapse="#navbar-with-collapse" aria-controls="navbar-with-collapse" aria-label="Toggle navigation">
                                    <svg class="hs-collapse-open:hidden w-4 h-4" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
                                    </svg>
                                    <svg class="hs-collapse-open:block hidden w-4 h-4" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div id="navbar-with-collapse" class="hidden basis-full grow sm:block">
                            <div class="flex flex-col gap-5 mt-5 sm:flex-row sm:items-center sm:justify-end sm:mt-0 sm:ps-5">
                                <router-link class="font-medium text-primary"  :to="{name: 'tbdefinition'}" aria-current="page">Menu</router-link>

                                    <div class="hs-dropdown ti-dropdown">
                                        <a class="ti-btn ti-dropdown-toggle font-medium text-primary"
                                           href="javascript:void(0);"
                                           aria-expanded="false">
                                            Tiers<i class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                                        </a>
                                        <ul class="hs-dropdown-menu ti-dropdown-menu hidden" aria-labelledby="dropdownMenuLink">
                                            <li><router-link class="ti-dropdown-item" to="#">Grille salariale <span style="color: red">(Bientôt disponible)</span></router-link></li>
                                            <li><hr class="dropdown-divider"> </li>
                                            <li><router-link class="ti-dropdown-item" :to="{name:'indexdefinitiontiers', params:{typ:'fonction'} }">Fonction</router-link></li>
                                            <li><hr class="dropdown-divider"> </li>
                                            <li><router-link class="ti-dropdown-item" :to="{name:'indexdefinitiontiers', params:{typ:'categorie'} }">Catégorie</router-link></li>
                                            <li><hr class="dropdown-divider"> </li>
                                            <li><router-link class="ti-dropdown-item" :to="{name:'indexdefinitiontiers', params:{typ:'banque'} }">Banque</router-link></li>
                                            <li><hr class="dropdown-divider"> </li>
                                            <li><router-link class="ti-dropdown-item" :to="{name:'indexdefinitiontiers', params:{typ:'mdreglement'} }">Mode de paiement</router-link></li>
                                            <li><hr class="dropdown-divider"> </li>
                                            <li><router-link class="ti-dropdown-item" :to="{name:'indexdefinitiontiers', params:{typ:'service'} }">Service</router-link></li>
                                            <li><hr class="dropdown-divider"> </li>
                                            <li><router-link class="ti-dropdown-item" :to="{name:'indexdefinitiontiers', params:{typ:'qualification'} }">Qualification et diplôme</router-link></li>
                                            <li><hr class="dropdown-divider"> </li>
                                            <li><router-link class="ti-dropdown-item" :to="{name:'indexdefinitiontiers', params:{typ:'tache'} }">Tâche</router-link></li>
                                        </ul>
                                    </div>


                                <router-link class="font-medium text-primary" title="Enregistrement du personnel" :to="{name: 'tbdefinitionpersonnel'}"  aria-current="page">Personnel</router-link>
                                <router-link class="font-medium text-primary" title="Enregistrement d'occasionnel" :to="{name:'tbdefinitionoccasionnel'}" aria-current="page">Occasionnel</router-link>



                            </div>
                        </div>
                    </nav>
                </header>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        /* eslint-disable */
        name: "mymenu"
    }
</script>

<style scoped>

</style>