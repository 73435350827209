<template>

    <templatesite>

        <template v-slot:appheader>  <appheader></appheader>  </template>
        <template v-slot:appsidebar>  <appsidebar></appsidebar> </template>
        <template v-slot:appcontent>

            <!-- Start::menu -->
            <appmenu></appmenu>
            <!-- end::menu -->

            <div class="content" >
                <!-- Start::main-content -->
                <div class="main-content">
                    <!-- Page Header -->
                    <div class="block justify-between page-header md:flex">
                        <div>
                            <h3 class="!text-defaulttextcolor dark:!text-defaulttextcolor/70
                            dark:text-white dark:hover:text-white text-[1.125rem] font-semibold">
                                Bulletin de paie des permanents
                                </h3>
                        </div>

                    </div>
                    <!-- Page Header Close -->

                    <div class="grid grid-cols-12 gap-x-6">


                            <div class="xl:col-span-4 col-span-12">
                                <div class="box">
                                    <div class="box-header">
                                        <h5 class="box-title">Liste du personnel</h5>
                                        <div class="w-full mt-3 ">


                                            <input  class="form-control form-control-lg" type="text"
                                                    placeholder="Rechercher" v-model="searchword"
                                                    aria-label=".form-control-lg example">


                                        </div>
                                    </div>
                                    <div id="scrollspy-scrollable-parent-2" class="max-h-[400px] overflow-y-auto">
                                        <div class="box-body">
                                            <div class="grid grid-cols-5">
                                                <div class="col-span-12 md:col-span-12">
                                                    <div id="scrollspy-12" class="space-y-4">
                                                        <div class="table-responsive">

                                                            <!-- component -->
                                                            <div class="flex flex-col h-screen">
                                                                <div class="flex-grow overflow-auto">


                                                                <table class="table mytable whitespace-nowrap ti-striped-table min-w-full">
                                                                <thead>
                                                                <tr class="border-b border-defaultborder" >
                                                                    <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Nom et prénom(s)</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>


                                                                <template v-if="filterItyemspersonnel.length">


                                                                <template v-for="(datavalue,index) in filterItyemspersonnel"  :key="index">

                                                                    <tr style="cursor: pointer;"
                                                                        :title="'Double clic pour sélectionner '+datavalue.name"
                                                                        @click="selectionner(datavalue.l,datavalue.isl,datavalue.name,index)"
                                                                        class="border-t hover:bg-gray-200 dark:hover:bg-light btncopie"
                                                                        :id="`btn${index}`"
                                                                        :ref="`btn${index}`"
                                                                    >

                                                                        <td data-label="Nom et prénom(s) : ">{{datavalue.name}}</td>


                                                                    </tr>



                                                                </template>



                                                                </template>



                                                                <template v-else>
                                                                    <tr >
                                                                        <td colspan="3"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                                    </tr>

                                                                </template>




                                                                </tbody>
                                                            </table>



                                                                </div>

                                                            </div>



                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div class="col-span-12 xl:col-span-8">
                                <div class="box">
                                    <div class="box-header">
                                        <h5 class="box-title">Bulletin de paie : {{nompersonnel}}</h5>




                                    </div>
                                    <div id="scrollspy-scrollable-parent-3" class="max-h-[600px] overflow-y-auto">
                                        <div class="box-body boxbodyheigth">
                                            <div class="grid grid-cols-5">

                                                <div class="col-span-12 sm:col-span-12">
                                                    <div id="scrollspy-2" class="space-y-4">

                                                        <!-- component -->
                                                        <div class="flex flex-col h-screen">
                                                            <div class="flex-grow overflow-auto">


                                                            <table class="table table-hover whitespace-nowrap
                                                        table-bordered min-w-full">
                                                            <thead>
                                                            <tr>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Rubrique</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Base</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">Déduction</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start" title="Salarié Taux">ST (%) </th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start" title="Salarié  Montant">SM </th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start" title="Employeur  Taux">ET (%) </th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start" title="Employeur Montant">EM </th>

                                                            </tr>
                                                            </thead>


                                                            <tbody>
                                                            <tr>
                                                                <td data-label="Rubrique  : "><strong>Base statuaire</strong></td>
                                                                <td colspan="6" data-label="Base  : ">{{jourtravaille_basestatutaire}}</td>


                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : "><strong>Jour d'absence non payé</strong></td>
                                                                <td colspan="6" data-label="Base  : ">{{jourtravaille_nbrejrsnbrenonpaye}}</td>

                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : "><strong>Jour payé</strong></td>
                                                                <td colspan="6" data-label="Base  : ">{{jourtravaille_nbrejrspayee}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : "><strong>Taux</strong></td>
                                                                <td colspan="6" data-label="Base  : ">{{jourtravaille_taux}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : "><strong>Base catégorielle</strong></td>
                                                                <td colspan="6" data-label="Base  : "><input  v-model="montant_salaire_de_baise"  class="form-control form-control-sm mb-3" type="number"  min="0"></td>

                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : "><strong>SALAIRE DE BASE</strong></td>
                                                                <td colspan="5" data-label="Base  : ">{{montant_salaire_de_baiseok}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : ">Primes d'habillement</td>
                                                                <td colspan="6" data-label="Base  : "><input  class="form-control form-control-sm mb-3" type="number"  min="0"></td>

                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : ">Primes de logement</td>
                                                                <td colspan="6" data-label="Base  : "><input v-model="montant_prime_de_logement"  class="form-control form-control-sm mb-3" type="number"  min="0"></td>

                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : ">Primes de déplacement</td>
                                                                <td colspan="6" data-label="Base  : "><input v-model="montant_prime_de_deplacement"  class="form-control form-control-sm mb-3" type="number"  min="0"></td>

                                                            </tr>

                                                            <tr>
                                                                <td data-label="Rubrique  : ">Primes de communication</td>
                                                                <td colspan="6" data-label="Base  : "><input  v-model="montant_prime_de_communication" class="form-control form-control-sm mb-3"  type="number"  min="0"></td>

                                                            </tr>

                                                            <tr>
                                                                <td data-label="Rubrique  : ">Primes de risque</td>
                                                                <td colspan="6" data-label="Base  : "><input v-model="montant_prime_de_risque" class="form-control form-control-sm mb-3"  type="number"   min="0"></td>

                                                            </tr>

                                                            <tr>
                                                                <td data-label="Rubrique  : ">Primes de salissure</td>
                                                                <td colspan="6" data-label="Base  : "><input v-model="montant_prime_de_salissure" class="form-control form-control-sm mb-3"  type="number"   min="0"></td>

                                                            </tr>

                                                            <tr>
                                                                <td data-label="Rubrique  : ">Primes de responsabilité</td>
                                                                <td colspan="6" data-label="Base  : "><input v-model="montant_prime_de_responsabilite"  class="form-control form-control-sm mb-3" type="number" min="0"></td>

                                                            </tr>


                                                            <tr>
                                                                <td data-label="Rubrique  : ">Congés payés</td>
                                                                <td colspan="6" data-label="Base  : "><input v-model="montant_conges_paye" class="form-control form-control-sm mb-3"  type="number" min="0"></td>

                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : ">Rappel</td>
                                                                <td colspan="6" data-label="Base  : ">{{montant_rappel}}</td>

                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : ">Autres Primes et gratifications</td>
                                                                <td colspan="6" data-label="Base  : "><input v-model="montant_autre_prime_gratification" class="form-control form-control-sm mb-3" type="number" min="0"></td>

                                                            </tr>
                                                            <tr>
                                                                <td colspan="7" data-label=" ">-----------</td>

                                                            </tr>


                                                            <tr>
                                                                <td data-label="Rubrique  : ">Taxe radiophonique</td>
                                                                <td colspan="6" data-label="Base  : "><input  v-model="montant_taxe_radiophonique" class="form-control form-control-sm mb-3" type="number" min="0"></td>

                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : ">Taxe télévisuelle</td>
                                                                <td colspan="6" data-label="Base  : "><input v-model="montant_taxe_televisuelle" class="form-control form-control-sm mb-3" type="number" min="0"></td>

                                                            </tr>


                                                            <tr>
                                                                <td colspan="7" data-label=" ">-----------</td>

                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : ">Heure supplémentaires à 12%</td>
                                                                <td colspan="6" data-label="Base  : ">{{montant_hs_12}}</td>

                                                            </tr>


                                                            <tr>
                                                                <td data-label="Rubrique  : ">Heure supplémentaires à 35%</td>
                                                                <td colspan="6" data-label="Base  : ">{{montant_hs_35}}</td>

                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : ">Heure supplémentaires à 50%</td>
                                                                <td colspan="6" data-label="Base  : ">{{montant_hs_50}}</td>

                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : ">Heure supplémentaires à 100%</td>
                                                                <td colspan="6" data-label="Base  : ">{{montant_hs_100}}</td>

                                                            </tr>

                                                            <tr>
                                                                <td colspan="7" data-label=" ">-----------</td>

                                                            </tr>


                                                            <tr>
                                                                <td data-label="Rubrique  : "><strong>Heure supplémentaire</strong></td>
                                                                <td colspan="6" data-label="Base  : ">{{montant_heure_supplementaire}}</td>

                                                            </tr>

                                                            <tr>
                                                                <td colspan="7" data-label=" ">-----------</td>

                                                            </tr>


                                                            <tr>
                                                                <td data-label="Rubrique  : "><strong>SALAIRE BRUT</strong></td>
                                                                <td colspan="6" data-label="Base  : ">{{salairebrut}}</td>

                                                            </tr>
                                                            <tr>
                                                                <td colspan="7" data-label=" "></td>

                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : ">BASE ITS</td>
                                                                <td colspan="6" data-label="Base  : ">{{montant_base_tps}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : ">ITS BRUT</td>
                                                                <td colspan="6" data-label="Base  : ">{{montantiptsbrute}}</td>

                                                            </tr>
                                                            <tr>
                                                                <td colspan="7" data-label=" "></td>

                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : ">CNSS</td>
                                                                <td colspan="2" data-label="Base  : ">-</td>
                                                                <td data-label="Salarié Taux (%)  : ">{{$store.state.stauxcnss_salarie}}</td>
                                                                <td data-label="Salarié Montant : "> {{montanttauxcnsssalarie}}</td>
                                                                <td data-label="Employeur Taux (%)  : ">{{$store.state.stauxcnss_employeur}}</td>
                                                                <td data-label="Employeur Montant  : "> {{montanttauxcnssemployeur}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : ">ITS</td>
                                                                <td colspan="2" data-label="Base  : ">-</td>
                                                                <td data-label="Salarié Taux (%)  : ">Variable</td>
                                                                <td colspan="3" data-label="Salarié Montant : ">{{montantipts}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : ">VPS</td>
                                                                <td data-label="Base  : ">-</td>
                                                                <td colspan="4" data-label="Employeur Taux (%)  : ">{{$store.state.stauxvps}}</td>
                                                                <td data-label="Employeur Montant  : ">{{montanttauxvps}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : ">Remboursement avance sur salaire</td>
                                                                <td colspan="6" data-label="Base  : ">{{montant_avance_sur_salaire}}</td>

                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : ">Remboursement de prêt</td>
                                                                <td colspan="6" data-label="Base  : ">{{mensualite_pret}}</td>

                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : ">Autre retenu</td>
                                                                <td colspan="6" data-label="Base  : ">{{montant_autre_retenu}}</td>

                                                            </tr>
                                                            <tr>
                                                                <td data-label="Rubrique  : ">Retenu sur retard</td>
                                                                <td colspan="6" data-label="Base  : ">{{montant_retenu_sur_retard}}</td>


                                                            </tr>
                                                            <tr>
                                                                <td colspan="7" data-label=" ">-----------</td>

                                                            </tr>
                                                            <!--tr>
                                                                <td data-label="Rubrique  : ">NET IMPOSABLE</td>
                                                                <td colspan="6" data-label="Base  : ">montant_netimpossable</td>

                                                            </tr-->
                                                            <tr>
                                                                <td data-label="Rubrique  : ">NET A PAYER</td>
                                                                <td colspan="6" data-label="Base  : ">{{netapayer}}</td>

                                                            </tr>












                                                            </tbody>

                                                                <tfoot>
                                                                <tr>
                                                                    <td colspan="7" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start"></td>
                                                                </tr>
                                                                </tfoot>

                                                        </table>




                                                            </div>
                                                        </div>



                                                    </div>
                                                </div>
                                            </div>
                                        </div>








                                    </div>

                                    <div class="box-footer">
                                        <button v-if="nompersonnel!==''" @click="savemodification" style="background: green"   class="ti-btn ti-btn-primary-full w-full mt-4 " >
                                            Enregistrer
                                        </button>
                                    </div>

                                </div>
                            </div>




                    </div>










                </div>
            </div>
        </template>

    </templatesite>



</template>

<script>

    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/paie/permanent/menu";
    import {randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');
   /* import allfunction from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
   */


    export default {
        /* eslint-disable */
        name: "bulletindepaie",
        components: {
            templatesite,
            appheader,
            appsidebar,
            appmenu,

        },

        data() {
            return {
                //listecatalogue:[],
                dataval:[],
                datatable:[],
                datatablesearch:[],
                typedoc:"",
                loading:true,
                isdisplay:false,
                isdisplayform:false,
                messagesuppression:"",
                mshow:false,
                searchword:'',

                nompersonnel:'',



                montant_salaire_de_baise:0,
                montant_salaire_de_baiseok:0,
                montant_heure_supplementaire:0,
                montant_prime_d_habillement:0,
                montant_prime_de_logement:0,
                montant_prime_de_deplacement:0,
                montant_prime_de_communication:0,
                montant_prime_de_risque:0,
                montant_prime_de_salissure:0,
                montant_prime_de_responsabilite:0,
                montant_conges_paye:0,
                montant_autre_prime_gratification:0,

                montant_taxe_radiophonique:0,
                montant_taxe_televisuelle:0,


                montant_salaire_brut:0,
                montant_base_tps:0,
                montant_ipts_brute:0,
                montant_ipts:0,
                montant_taux_vps:0,
                montant_taux_cnss_salarie:0,
                montant_taux_cnss_employeur:0,

                montant_hs_12:0,
                montant_hs_35:0,
                montant_hs_50:0,
                montant_hs_100:0,
                montant_netimpossable:0,
                montant_netapayer:0,
                montant_avance_sur_salaire:0,
                mensualite_pret:0,
                montant_rappel:0,
                montant_autre_retenu:0,
                montant_retenu_sur_retard:0,


                nbredheuretotal:0,
                nbre_heure_12:0,
                nbre_heure_35:0,
                nbre_heure_50:0,
                nbre_heure_100:0,

                jourtravaille_basestatutaire:0,
                jourtravaille_nbrejrsnbrenonpaye:0,
                jourtravaille_nbrejrspayee:0,
                jourtravaille_taux:0,

                lpaie:{
                    __apg: 0,
                    __ar: 0,
                    __arss: 0,
                    __ass: 0,
                    __cp: 0,
                    __hs: 0,
                    __mensualite: 0,
                    __mtntbaseits: 0,
                    __mtntcnssemployeur: 0,
                    __mtntcnsssalarie: 0,
                    __mtntitsbrut: 0,
                    __nbre_heure50: 0,
                    __nbre_heure100: 0,
                    __nbreheure12: 0,
                    __nbreheure35: 0,
                    __nbreheuretotal: 0,
                    __nbrsp: 0,
                    __netapayer: 0,
                    __netimpossable: 0,
                    __pc: 0,
                    __prisque: 0,
                    __psalis: 0,
                    __presp: 0,
                    __pd: 0,
                    __ph: 0,
                    __pl: 0,
                    __rappel: 0,
                    __sb: 0,
                    __tauxcnssemployeur: 0,
                    __tauxcnsssalaire: 0,
                    __tauxitssalaire: 0,
                    __tauxmtntsalaire: 0,
                    __tauxvpsemployeur: 0,
                    __tid: "",
                    __tidpers: "",
                }

            };
        },
        computed:{


            filterItyemspersonnel(){
                let mvalue=this.datatable;

                if (this.searchword!==''){



                    mvalue = mvalue.filter( post => post.name.toLowerCase().includes(this.searchword.toLowerCase()))
                    //return this.postList.filter( post => post.intitule.toLowerCase().includes(this.searchword))
                }

                return  mvalue;
            },


            salairebrut(){


                this.jourtravaille_nbrejrspayee=parseFloat(this.jourtravaille_basestatutaire)-parseFloat(this.jourtravaille_nbrejrsnbrenonpaye);
                this.jourtravaille_taux=parseFloat(this.jourtravaille_nbrejrspayee)/parseFloat(this.jourtravaille_basestatutaire)
                this.jourtravaille_taux= this.jourtravaille_taux.toFixed(4)



                this.montant_salaire_brut=0;

                this.montant_salaire_de_baiseok=parseInt(parseInt(this.montant_salaire_de_baise)*parseFloat(this.jourtravaille_taux))

                var  tauxhoraire =parseInt(this.montant_salaire_de_baise)/parseFloat(this.$store.state.stauxhoraire)
                //var  tauxhoraire =parseInt(this.montant_salaire_de_baiseok)/parseFloat(this.$store.state.stauxhoraire)
/*
                if (this.nbredheuretotal<8){

                    this.montant_hs_12=parseInt((tauxhoraire*1.12)*parseFloat(this.nbre_heure_12))
                    this.montant_hs_35=0
                    this.montant_hs_50=0
                    this.montant_hs_100=0

                }
                else{

                    this.montant_hs_12=parseInt((tauxhoraire*1.12)*parseFloat(this.nbre_heure_12))
                    this.montant_hs_35=parseInt((tauxhoraire*1.35)*parseFloat(this.nbre_heure_35))
                    this.montant_hs_50=0
                    this.montant_hs_100=0


                }
*/
/*

                $request.montant_nbre_heure_12=parseInt(($tauxhoraire*1.12)*parseFloat($request.nbre_heure_12));
                $request.montant_nbre_heure_35=parseInt(($tauxhoraire*1.35)*parseFloat($request.nbre_heure_35));
                $request.montant_nbre_heure_50=parseFloat(($tauxhoraire*1.5)*parseFloat($request.nbre_heure_50));
                $request.montant_nbre_heure_100=parseFloat(($tauxhoraire*2)*parseFloat($request.nbre_heure_100));
                $request.heure_supp=parseInt($request.montant_nbre_heure_12)+parseInt($request.montant_nbre_heure_35)+parseInt($request.montant_nbre_heure_50)+parseInt($request.montant_nbre_heure_100);
*/

                this.montant_hs_12=parseInt((tauxhoraire*1.12)*parseFloat(this.nbre_heure_12))
                this.montant_hs_35=parseInt((tauxhoraire*1.35)*parseFloat(this.nbre_heure_35))
                this.montant_hs_50=parseInt((tauxhoraire*1.5)*parseFloat(this.nbre_heure_50))
                this.montant_hs_100=parseInt((tauxhoraire*2)*parseFloat(this.nbre_heure_100))



                this.montant_heure_supplementaire=parseInt(this.montant_hs_12)+parseInt(this.montant_hs_35)+parseInt(this.montant_hs_50)+parseInt(this.montant_hs_100)


                this.montant_salaire_brut=parseInt(this.montant_salaire_de_baiseok)+parseInt(this.montant_heure_supplementaire)+parseInt(this.montant_prime_d_habillement)+
                    parseInt(this.montant_prime_de_logement)+parseInt(this.montant_prime_de_deplacement)+parseInt(this.montant_prime_de_communication)+
                    parseInt(this.montant_conges_paye)+parseInt(this.montant_autre_prime_gratification)+parseInt(this.montant_rappel)
                    + parseInt(this.montant_prime_de_responsabilite)+parseInt(this.montant_prime_de_risque)+parseInt(this.montant_prime_de_salissure);



                this.montant_base_tps=this.montant_salaire_brut;


                return this.montant_salaire_brut
            },


            montantiptsbrute(){
                this.montant_ipts_brute=0;
                let t=this
                let datataux={}
                let montantsalairebrut=0;


                let $la_serie="000";
                let montant=t.montant_salaire_brut;
                //console.log('montant ',montant)
                var montantString = montant.toString();
                //console.log('montantString ',montantString)
                var $la_longueur=parseInt(montantString.length)
                let result = montantString.substr($la_longueur-3, $la_longueur);
                // console.log('result  ',result)
                let result1 = montantString.substr(0, $la_longueur-3);
                // console.log('result1  ',result1)

                // console.log('result1 new ',result1+$la_serie)
                let newmontant=parseInt(result1+$la_serie);
                // console.log('newmontant ',newmontant)
                montantsalairebrut=newmontant




                let cummulmontantits=0
                let isokcummulmontantits=0

                this.$store.state.tauxipts.forEach(function (taux,index) {

                    if (montantsalairebrut >= taux.min && montantsalairebrut <= taux.max){

                        datataux=taux;
                        isokcummulmontantits=1;
                    }

                    if (isokcummulmontantits===0){
                        // console.log('cummulmontantits ',cummulmontantits)

                        var vp=parseInt((parseInt(taux.max)-parseInt(taux.mmin-1))*taux.taux)
                        cummulmontantits= parseInt(cummulmontantits)+parseInt(vp)
                        // console.log('cummulmontantits1 ',cummulmontantits)
                    }




                })



                this.montant_ipts_brute=  (montantsalairebrut-(datataux.mmin-1))*datataux.taux

                this.montant_ipts_brute=parseInt(cummulmontantits)+parseInt(this.montant_ipts_brute)+
                    parseInt(this.montant_taxe_radiophonique) +parseInt(this.montant_taxe_televisuelle)
                //console.log('this.montant_ipts_brute ',this.montant_ipts_brute)
                return this.montant_ipts_brute
            },

            montantipts(){
                this.montant_ipts=0;


                this.montant_ipts= this.montant_ipts_brute

                return this.montant_ipts_brute
            },

            montanttauxvps(){
                this.montant_taux_vps=0;

                this.montant_taux_vps= parseInt(this.montant_salaire_brut*(this.$store.state.stauxvps/100))

                return this.montant_taux_vps
            },


            montanttauxcnsssalarie(){
                this.montant_taux_cnss_salarie=0;

                this.montant_taux_cnss_salarie= parseInt(this.montant_salaire_brut*(this.$store.state.stauxcnss_salarie/100))

                return this.montant_taux_cnss_salarie
            },

            montanttauxcnssemployeur(){
                this.montant_taux_cnss_employeur=0;

                this.montant_taux_cnss_employeur= parseInt(this.montant_salaire_brut*(this.$store.state.stauxcnss_employeur/100))

                return this.montant_taux_cnss_employeur
            },




            netapayer(){
                this.montant_netimpossable=0;
                this.montant_netapayer=0;

                this.montant_netimpossable=parseInt(this.montant_salaire_brut)-parseInt(this.montant_taux_cnss_salarie);
                this.montant_netapayer=parseInt(this.montant_salaire_brut)-parseInt(this.montant_taux_cnss_salarie)-parseInt(this.montant_ipts)
                    -parseInt(this.montant_avance_sur_salaire)-parseInt(this.mensualite_pret)
                    -parseInt(this.montant_autre_retenu)-parseInt(this.montant_retenu_sur_retard)


                return this.montant_netapayer



            },




        },
        methods: {

            async loadallpersonnelcontraisok(){
             this.$store.dispatch('lancerchargement',true)

               const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74dkjskdj5874ppaie771714dfe/paie/'+this.$store.state.appsociete+'/fichedepaie/'+randomString();
                let response =   axios  .post(url,{__p:'personnel',__d:t.$store.state.dataspersonnel},{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })
                let res= await response
                    .then(response => {
                        t.$store.dispatch('lancerchargement',false)

                        if(response.data.success){

                            t.datatable=response.data.m.datas;
                           // t.datatablesearch=response.data.datas;

                            t.selectionner(t.datatable[0].l,t.datatable[0].isl,t.datatable[0].name,0)

                        }else{


                            toastr['error']( res.data.message,"<b></b>");
                            // t.$store.dispatch('lancerchargement',false)
                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() => this.$store.dispatch('lancerchargement',false))





            },

            selectionner(value,isvalue,nompersonnel,indexbutton){


                if (isvalue===0){
                    toastr['error']( "Impossible de traiter cette ligne, veuillez contacter votre administrateur. ","<b></b>");
                    return;

                }



                // Retirer la classe copied de tous les boutons
                var buttons = document.querySelectorAll('.btncopie');
                buttons.forEach(function(btn) {
                    btn.classList.remove('selectedline');
                    btn.classList.add('noselectedline');
                });
                // Changer la classe du bouton pour appliquer le style après la copie


                // Par exemple, supposons que vous ayez un ID dynamique pour le bouton
                const dynamicButtonId = `btn${indexbutton}`;

                // Sélectionnez l'élément par son ID
                const button = document.getElementById(dynamicButtonId);

                // Vérifiez si l'élément a été trouvé
                if (button) {
                    // Appliquez les classes
                    button.classList.remove('noselectedline');
                    button.classList.add('selectedline');
                }

               /* button.classList.remove('nocopied');
                button.classList.add('copied');*/







                //*******************************************************************//

                this.lpaie=value;

                this.nompersonnel=nompersonnel

                this.montant_salaire_de_baise=value.__sb
                this.montant_prime_d_habillement=value.__ph
                this.montant_prime_de_logement=value.__pl
                this.montant_prime_de_deplacement=value.__pd
                this.montant_prime_de_communication=value.__pc
                this.montant_prime_de_risque=value.__prisque
                this.montant_prime_de_salissure=value.__psalis
                this.montant_prime_de_responsabilite=value.__presp
                this.montant_conges_paye=value.__cp
                this.montant_autre_prime_gratification=value.__apg
                this.montant_taxe_radiophonique=value.__montant_taxe_radiophonique
                this.montant_taxe_televisuelle=value.__montant_taxe_televisuelle
                this.montant_rappel=value.__rappel
                this.mensualite_pret=value.__mensualite
                this.montant_avance_sur_salaire=value.__ass
                this.montant_autre_retenu=value.__arss
                this.montant_retenu_sur_retard=value.__montantretenusurretard

                this.nbredheuretotal=value.__nbreheuretotal;
                this.jourtravaille_basestatutaire=value.__basestatuairejourdetrail;
                this.jourtravaille_nbrejrsnbrenonpaye=value.__nbredejourdabsence;



                this.montant_hs_12=value.__montantnbreheure12
                this.montant_hs_35=value.__montantnbreheure35
                this.montant_hs_50=value.__montantnbreheure50
                this.montant_hs_100=value.__montantnbreheure100


                this.nbre_heure_12=value.__nbreheure12
                this.nbre_heure_35=value.__nbreheure35
                this.nbre_heure_50=value.__nbreheure50
                this.nbre_heure_100=value.__nbreheure100
                this.montant_heure_supplementaire=parseInt(this.montant_hs_12)+parseInt(this.montant_hs_35)+parseInt(this.montant_hs_50)+parseInt(this.montant_hs_100)



            },

            async  savemodification(){


                if (this.lpaie.pid===""){
                    toastr['error']( "Impossible d'enregistrer ces informations, veuillez contacter votre administrateur. ","<b></b>");
                    return;

                }



                let lpaie={
                    // __montantretenusurretard: this.montant_retenu_sur_retard,
                    __apg: this.montant_autre_prime_gratification,
                    __ar: this.montant_autre_retenu,
                    __montant_taxe_radiophonique: this.montant_taxe_radiophonique,
                    __montant_taxe_televisuelle: this.montant_taxe_televisuelle,


                    __cp: this.montant_conges_paye,
                    __mtntbaseits: this.montant_base_tps,
                    __mtntcnssemployeur: this.montant_taux_cnss_employeur,
                    __mtntcnsssalarie: this.montant_taux_cnss_salarie,
                    __mtntitsbrut: this.montant_ipts_brute,

                    __netapayer: this.montant_netapayer,
                    __netimpossable: this.montant_netimpossable,
                    __pc: this.montant_prime_de_communication,

                    __prisque: this.montant_prime_de_risque,
                    __psalis: this.montant_prime_de_salissure,
                    __presp: this.montant_prime_de_responsabilite,


                    __pd: this.montant_prime_de_deplacement,
                    __ph: this.montant_prime_d_habillement,
                    __pl: this.montant_prime_de_logement,
                    __rappel: this.montant_rappel,
                    __sb: this.montant_salaire_de_baise,
                    __tauxcnssemployeur: this.$store.state.stauxcnss_employeur,
                    __tauxcnsssalaire: this.$store.state.stauxcnss_salarie,
                    __tauxitssalaire: 0,
                    __tauxmtntsalaire: 0,
                    __tauxvpsemployeur: this.$store.state.stauxvps,
                    __tid: this.lpaie.__tid,
                    __tidpers: this.lpaie.__tidpers,
                    montant_heure_supplementaire:this.montant_heure_supplementaire,
                    montant_hs_12:this.montant_hs_12,
                    montant_hs_35:this.montant_hs_35,
                    montant_hs_50:this.montant_hs_50,
                    montant_hs_100:this.montant_hs_100,
                }


                const t = this;
                let url=process.env.VUE_APP_API_URL+'/egl74dkjskdj5874ppaie771714dfe/paie/'+this.$store.state.appsociete+'/savefp/'+randomString();
                let response =   axios  .post(url,{__p:'personnel',__d:t.$store.state.dataspersonnel,__lp:lpaie},{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Accept':'application/json',
                        'Content-Type':'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                        'Access-Control-Allow-Headers': 'Content-Type',
                        'Authorization': 'Bearer ' + JwtService.getToken(),
                        'csrftoken': this.$store.state.appsociete
                    }
                })
                let res= await response
                        .then(response => {
                        t.$store.dispatch('lancerchargement',false)

                        if(response.data.success){

                            for (let $line of t.datatable){

                                if (response.data.mcle===$line.l.__tid){

                                    $line.l=response.data.m

                                }

                            }

                            toastr['success']( response.data.message,"<b></b>");

                        }
                else{


                            toastr['error']( response.data.message,"<b></b>");
                        }


                    })
                    .catch(

                        error => toastr['error']( "Une erreur est subvenue. <br/> veuillez actualiser la page.",
                            "<b></b>")
                    )
                    .finally(() => this.$store.dispatch('lancerchargement',false))






            },



        },
        mounted() {


          /*  if(this.authorizpage===0){
                this.$router.push({ name: 'indexpaie' }); return;


            }*/




            if (this.$store.state.dataspaie.typ==="personnel"){

                if(this.$store.state.moisencourIsok===0 || this.$store.state.moisencourIsok!==1){

                    toastr['error']( "Veuillez ouvrir un mois pour le traitement du salaire.")

                    this.$router.push({ name: 'tbpaie' });
                }
                else{
                     this.loadallpersonnelcontraisok();
                }


            }
            else{

                toastr['error']( "Veuillez ouvrir un mois pour le traitement du salaire.")

                this.$router.push({ name: 'tbpaie' });


            }



        }






    }
</script>

<style scoped>


    /* Changement de couleur de bordure lorsqu'un champ obtient le focus */
    input:focus {
        border-color: #007bff !important; /* Couleur de bordure lorsqu'un champ obtient le focus */
    }

    .boxbodyheigth{
        height: 450px!important;
    }

    .selectedline{
        background: greenyellow !important;

    }
    .noselectedline{
        background: transparent !important;

    }


    .divmenuheader{
        margin-top: 60px;
    }
    .divmenuheader header{
        padding: 15px;
    }

    @media screen and (max-width: 991px) {

        .boxbodyheigth{
            height: 100%!important;
        }






    }


</style>