<template>
    <div class="col-span-12">
        <div class="box">
            <div class="box-header">
                <h5 class="box-title">---</h5>
            </div>
            <div class="box-body">
                <header class="flex flex-wrap sm:justify-start sm:flex-nowrap z-40 w-full bg-white text-sm dark:bg-bodybg">
                    <nav class="w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between" aria-label="Global">
                        <div class="flex items-center justify-between">
                            <router-link :to="{name: 'tbpaie'}" class="header-logo">
                                Menu
                            </router-link>
                            <div class="sm:hidden">
                                <button type="button" class="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-2 rounded-sm border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-offset-white focus:ring-primary transition-all text-sm dark:bg-bodybg dark:hover:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-white dark:focus:ring-offset-white/10"
                                        data-hs-collapse="#navbar-with-collapse" aria-controls="navbar-with-collapse" aria-label="Toggle navigation">
                                    <svg class="hs-collapse-open:hidden w-4 h-4" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
                                    </svg>
                                    <svg class="hs-collapse-open:block hidden w-4 h-4" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div id="navbar-with-collapse" class="hidden basis-full grow sm:block">
                            <div class="flex flex-col gap-5 mt-5 sm:flex-row sm:items-center sm:justify-end sm:mt-0 sm:ps-5">
                                <router-link class="font-medium text-primary"  :to="{name: 'tbpaie'}" aria-current="page">Menu</router-link>



                                <router-link class="font-medium text-primary"  :to="{name: 'bulletindepaie',params:{mois: $store.state.dataspersonnel.moislettre,anne: $store.state.dataspersonnel.mannee}}"
                                             aria-current="page">Bulletin de paie</router-link>
                                <router-link class="font-medium text-primary" :to="{name:'traitementdebasedelapaiedespermanents',params:{typ:'pret',mois: $store.state.dataspersonnel.moislettre,anne: $store.state.dataspersonnel.mannee}}"
                                             aria-current="page">Prêt</router-link>
                                <router-link class="font-medium text-primary" :to="{name:'traitementdebasedelapaiedespermanents',params:{typ:'retenue',mois: $store.state.dataspersonnel.moislettre,anne: $store.state.dataspersonnel.mannee}}"
                                             aria-current="page">Retenues</router-link>
                                <router-link class="font-medium text-primary" :to="{name:'traitementdebasedelapaiedespermanents',params:{typ:'avance_sur_salaire',mois: $store.state.dataspersonnel.moislettre,anne: $store.state.dataspersonnel.mannee}}"
                                             aria-current="page">Avance sur salaire</router-link>
                                <router-link class="font-medium text-primary" :to="{name:'traitementdebasedelapaiedespermanents',params:{typ:'supplement_rappel',mois: $store.state.dataspersonnel.moislettre,anne: $store.state.dataspersonnel.mannee}}"
                                             aria-current="page">Supplément / Rappel</router-link>
                                <router-link class="font-medium text-primary" :to="{name:'heuresupplementairepaiedespermanents',params:{mois: $store.state.dataspersonnel.moislettre,anne: $store.state.dataspersonnel.mannee}}"
                                             aria-current="page">Heure supplémentaire</router-link>

                                <div class="hs-dropdown ti-dropdown">
                                    <a class="ti-btn ti-dropdown-toggle font-medium text-primary"
                                       href="javascript:void(0);"
                                       aria-expanded="false">
                                        Rapport / Etat<i class="ri-arrow-down-s-line align-middle ms-1 inline-block"></i>
                                    </a>
                                    <ul class="hs-dropdown-menu ti-dropdown-menu hidden" aria-labelledby="dropdownMenuLink">

                                        <li><router-link class="ti-dropdown-item" to="#" @click="btnbulletindepaie">Bulletin de salaire</router-link></li>
                                        <li><hr class="dropdown-divider"> </li>
                                        <li><router-link class="ti-dropdown-item" to="#" @click="btnlivredepaiedetaille">Livre de paie détaillé</router-link></li>
                                        <li><hr class="dropdown-divider"> </li>
                                        <li><router-link class="ti-dropdown-item" to="#" @click="btnetatdepaiement">Etat des paiements</router-link></li>
                                        <li><hr class="dropdown-divider"> </li>
                                        <li><router-link class="ti-dropdown-item" :to="{name:'indexdefinitiontiers', params:{typ:'mdreglement'} }">Ordre de virement <span style="color: red">(Bientôt disponible)</span></router-link></li>
                                        <li><hr class="dropdown-divider"> </li>
                                        <li><router-link class="ti-dropdown-item" :to="{name:'indexdefinitiontiers', params:{typ:'service'} }">Déclaration C.N.S.S <span style="color: red">(Bientôt disponible)</span></router-link></li>
                                        <li><hr class="dropdown-divider"> </li>
                                        <li><router-link class="ti-dropdown-item" :to="{name:'indexdefinitiontiers', params:{typ:'qualification'} }">Déclaration ITS <span style="color: red">(Bientôt disponible)</span></router-link></li>

                                    </ul>
                                </div>



                            </div>
                        </div>
                    </nav>
                </header>
            </div>
        </div>


        <modalbulletindepaie ref="modalbulletindepaie"></modalbulletindepaie>
        <modallivredepaiedetaille ref="modallivredepaiedetaille"></modallivredepaiedetaille>
        <modaletatdepaiement ref="modaletatdepaiement"></modaletatdepaiement>


    </div>


</template>

<script>
    import modalbulletindepaie from "@/components/views/pages/modal_etat/modal_bulletindepaie";
    import modallivredepaiedetaille from "@/components/views/pages/modal_etat/modal_livredepaiedetaille";
    import modaletatdepaiement from "@/components/views/pages/modal_etat/modal_etatdepaiement";




    export default {
        /* eslint-disable */
        name: "mymenu",
        components: {
            modalbulletindepaie,
            modallivredepaiedetaille,
            modaletatdepaiement,
        },
        methods: {



            btnbulletindepaie(){

                this.$refs.modalbulletindepaie.show("personnel",this.$store.state.dataspersonnel.mmois,this.$store.state.dataspersonnel.mannee);

            },

            btnlivredepaiedetaille(){

                this.$refs.modallivredepaiedetaille.show("personnel",this.$store.state.dataspersonnel.mmois,this.$store.state.dataspersonnel.mannee);

            },

            btnetatdepaiement(){

                this.$refs.modaletatdepaiement.show("personnel",this.$store.state.dataspersonnel.mmois,this.$store.state.dataspersonnel.mannee);

            },



        }
    }
</script>

<style scoped>

</style>