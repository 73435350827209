/*import Vuex from 'vuex'
import Vue from 'vue'
Vue.use(Vuex)*/

import {randomString} from "@/allfunction";

const JwtService=require("../common/jwt.service");
const axios =require('axios');
import { createStore } from 'vuex';

const state = {
    niveauclickmenu:0,
    myscreenSize:0,
    classdefautmenu:"",
    isAuthenticated:false,
    beartoken:'',
    lancerchargement:false,

    paramsystem:[],
    paramsapp:[],
    userdata:[],
    isRole:[],
    allccessapp:[],
    appuserpicture:"",
    appusername:"",
    approle:"",
    appsociete:"",

    baseurlapi:process.env.VUE_APP_API_URL,




    devise:"",
    separationdevise:"",

    isworkSunday:0,
    isworkSaturday:0,


//***************************** PAIE VARIABLE ****************************//


    moisencourIsok:0,
    moisencourIsokoccasionnel:0,
    dataspersonnel:[],
    datasoccasionel:[],
    storelistepersonnels:[],
    storelisteoccasionels:[],






//***************************** FIN ****************************//

    tauxipts:[],
    sysmodule:{},
    sbasestatuaire_jourdetrail:0,
    stauxvps:0,
    stauxcnss_salarie:0,
    stauxcnss_employeur:0,
    stauxhoraire:0,
    nbrepersautorise:0,

    nbredepersonnelpermannent:5,
    nbredepersonneloccsionnel:5,

    module:{
        "definition":1,
        "paie":1,
        "presence":1,
        "document":1,
        "etatedition":1,
        "paramsystem":1,
        "mpointeur":1,
        "absenceretard":1,
        "planningorregldepresense":2

    },
    etatlogo:"",
    etatraisonsocial:"",
    etatifu:"",
    etatrccm:"",
    etattelephone:"",
    etatadresse:"",


    dataspaie:{}





}


export default createStore({
    strict:true,
    state,
    getters:{

        etatlogo:function (state) {
            return state.etatlogo || ""
        },
        etatraisonsocial:function (state) {
            return state.etatraisonsocial || ""
        },
        etatifu:function (state) {
            return state.etatifu || ""
        },
        etatrccm:function (state) {
            return state.etatrccm || ""
        },
        etattelephone:function (state) {
            return state.etattelephone || ""
        },
        etatadresse:function (state) {
            return state.etatadresse || ""
        },



        tauxipts:function (state) {
            return state.tauxipts || []
        },

        paramsystem:function (state) {
            return state.paramsystem || []
        },

        dataspaie:function (state) {
            return state.dataspaie || {}
        },

        sysmodule:function (state) {
            return state.sysmodule || {}
        },

        sbasestatuaire_jourdetrail:function (state) {
            return state.sbasestatuaire_jourdetrail || 0
        },

        stauxvps:function (state) {
            return state.stauxvps || 0
        },

        stauxcnss_salarie:function (state) {
            return state.stauxcnss_salarie || 0
        },

        stauxcnss_employeur:function (state) {
            return state.stauxcnss_employeur || 0
        },

        stauxhoraire:function (state) {
            return state.stauxhoraire || 0
        },

        nbrepersautorise:function (state) {
            return state.nbrepersautorise || 0
        },

        storelistepersonnels:function (state) {
            return state.storelistepersonnels || []
        },


        storelisteoccasionels:function (state) {
            return state.storelisteoccasionels || []
        },


        userdata:function (state) {
            return state.userdata || []
        },

        dataspersonnel:function (state) {
            return state.dataspersonnel || []
        },

        datasoccasionel:function (state) {
            return state.datasoccasionel || []
        },
        moisencourIsok:function (state) {
            return state.moisencourIsok || 0
        },

        moisencourIsokoccasionnel:function (state) {
            return state.moisencourIsokoccasionnel || 0
        },


        devise:function (state) {
            return state.devise || ""
        },



        isworkSunday:function (state) {
            return state.isworkSunday || 0
        },



        isworkSaturday:function (state) {
            return state.isworkSaturday || 0
        },


        separationdevise:function (state) {
            return state.separationdevise || ""
        },










        niveauclickmenu:function (state) {
            return state.niveauclickmenu || 0
        },

        myscreenSize:function (state) {
            return state.myscreenSize || 990
        },



        isAuthenticated:function (state) {
            return state.isAuthenticated || false
        },

        beartoken:function (state) {
            return state.beartoken || ''
        },

        lancerchargement:function (state) {
            return state.lancerchargement || false
        },


        appusername:function (state) {
            return state.appusername || ""
        },

        approle:function (state) {
            return state.approle || ""
        },

        appuserpicture:function (state) {
            return state.appuserpicture || ""
        },




    },
    mutations:{


        tauxipts:function(state,{value}){

            state.tauxipts=value;
        },


        paramsystem:function(state,{value}){

            state.paramsystem=value;
        },

        dataspaie:function(state,{value}){

            state.dataspaie=value;
        },

        sysmodule:function(state,{value}){

            state.sysmodule=value;
        },


        sbasestatuaire_jourdetrail:function(state,{value}){

            state.sbasestatuaire_jourdetrail=value;
        },


        stauxvps:function(state,{value}){

            state.stauxvps=value;
        },


        stauxcnss_salarie:function(state,{value}){

            state.stauxcnss_salarie=value;
        },


        stauxcnss_employeur:function(state,{value}){

            state.stauxcnss_employeur=value;
        },


        stauxhoraire:function(state,{value}){

            state.stauxhoraire=value;
        },


        nbrepersautorise:function(state,{value}){

            state.nbrepersautorise=value;
        },


        storelistepersonnels:function(state,{value}){

            state.storelistepersonnels=value;
        },


        storelisteoccasionels:function(state,{value}){

            state.storelisteoccasionels=value;
        },
        etatlogo:function(state,{etatlogo}){

            state.etatlogo=etatlogo;
        },

        etatraisonsocial:function(state,{etatraisonsocial}){

            state.etatraisonsocial=etatraisonsocial;
        },

        etatifu:function(state,{etatifu}){

            state.etatifu=etatifu;
        },

        etatrccm:function(state,{etatrccm}){

            state.etatrccm=etatrccm;
        },

        etattelephone:function(state,{etattelephone}){

            state.etattelephone=etattelephone;
        },

        etatadresse:function(state,{etatadresse}){

            state.etatadresse=etatadresse;
        },


        moisencourIsok:function(state,{moisencourIsok}){

            state.moisencourIsok=moisencourIsok;
        },

        moisencourIsokoccasionnel:function(state,{moisencourIsokoccasionnel}){

            state.moisencourIsokoccasionnel=moisencourIsokoccasionnel;
        },

        dataspersonnel:function(state,{dataspersonnel}){

            state.dataspersonnel=dataspersonnel;
        },

        datasoccasionel:function(state,{datasoccasionel}){

            state.datasoccasionel=datasoccasionel;
        },


        devise:function(state,{name}){

            state.devise=name;
        },


        isworkSunday:function(state,{name}){

            state.isworkSunday=name;
        },


        isworkSaturday:function(state,{name}){

            state.isworkSaturday=name;
        },


        separationdevise:function(state,{name}){

            state.separationdevise=name;
        },

        userdata:function(state,{value}){

            state.userdata=value;
        },





        niveauclickmenu:function(state,{value}){

            state.niveauclickmenu=value;
        },

        myscreenSize:function(state,{value}){

            state.myscreenSize=value;
        },

        appsociete:function(state,{name}){

            state.appsociete=name;
        },

        lancerchargement:function(state,{value}){

            state.lancerchargement=value;
        },

        isAuthenticated:function(state,{value}){

            state.isAuthenticated=value;
        },

        beartoken:function(state,{value}){

            state.beartoken=value;
        },
        appusername:function(state,{name}){

            state.appusername=name;
        },
        approle:function(state,{name}){

            state.approle=name;
        },
        appuserpicture:function(state,{name}){

            state.appuserpicture=name;
        },


    },
    actions:{


        etatlogo: async function (context,data) {

            context.commit('etatlogo',{etatlogo:data})

        },



        etatraisonsocial: async function (context,data) {

            context.commit('etatraisonsocial',{etatraisonsocial:data})

        },



        etatifu: async function (context,data) {

            context.commit('etatifu',{etatifu:data})

        },



        etatrccm: async function (context,data) {

            context.commit('etatrccm',{etatrccm:data})

        },



        etattelephone: async function (context,data) {

            context.commit('etattelephone',{etattelephone:data})

        },

        etatadresse: async function (context,data) {

            context.commit('etatadresse',{etatadresse:data})

        },


        tauxipts:function(context,value){

            context.commit('tauxipts',{value:value});
        },

        paramsystem:function(context,value){

            context.commit('paramsystem',{value:value});
        },

        sysmodule:function(context,value){

            context.commit('sysmodule',{value:value});
        },

        dataspaie:function(context,value){

            context.commit('dataspaie',{value:value});
        },

        sbasestatuaire_jourdetrail:function(context,value){

            context.commit('sbasestatuaire_jourdetrail',{value:value});
        },

        stauxvps:function(context,value){

            context.commit('stauxvps',{value:value});
        },

        stauxcnss_salarie:function(context,value){

            context.commit('stauxcnss_salarie',{value:value});
        },

        stauxcnss_employeur:function(context,value){

            context.commit('stauxcnss_employeur',{value:value});
        },

        stauxhoraire:function(context,value){

            context.commit('stauxhoraire',{value:value});
        },

        nbrepersautorise:function(context,value){

            context.commit('nbrepersautorise',{value:value});
        },

        storelistepersonnels:function(context,value){

            context.commit('storelistepersonnels',{value:value});
        },


        storelisteoccasionels:function(context,value){

            context.commit('storelisteoccasionels',{value:value});
        },



        moisencourIsokoccasionnel:function(context,value){

            context.commit('moisencourIsokoccasionnel',{moisencourIsokoccasionnel:value});
        },


        moisencourIsok:function(context,value){

            context.commit('moisencourIsok',{moisencourIsok:value});
        },

        userdata:function(context,value){

            context.commit('userdata',{value:value});
        },



        dataspersonnel:function(context,value){

            context.commit('dataspersonnel',{dataspersonnel:value});
        },

        datasoccasionel:function(context,value){

            context.commit('datasoccasionel',{datasoccasionel:value});
        },






















        myscreenSize:function(context,value){

            context.commit('myscreenSize',{value:value});
        },

        niveauclickmenu:function(context,value){

            context.commit('niveauclickmenu',{value:value});
        },



        isAuthenticated:function(context,value){

            context.commit('isAuthenticated',{value:value});
        },

        beartoken:function(context,value){

            context.commit('beartoken',{value:value});
        },

        appuserpicture:function(context,value){

            context.commit('appuserpicture',{value:value});
        },

        appusername:function(context,value){

            context.commit('appusername',{value:value});
        },

        approle:function(context,value){

            context.commit('approle',{value:value});
        },

        lancerchargement:function(context,value){

            context.commit('lancerchargement',{value:value});
        },


/*
        isPermission:function (context,namepage){

            let mtableaudebord=[]
            mtableaudebord=context.state.allccessapp;
            let datapermissions =null;
            let mpermissions=false;

            if(mtableaudebord.length>0){

                datapermissions=mtableaudebord.find((item) => item.name === namepage );

                if(datapermissions === undefined){
                    mpermissions=false;
                }else{
                    mpermissions=datapermissions.permission;
                }


            }

            // return {mpermissions:mpermissions}
            return {mpermissions:true}

        },
*/

        /*  chektoken: async function (context,{idtoken,expirein=''}) {*/
        chektoken: async function (context,{idtoken}) {
           // context.commit('lancerchargement',{value:true});
            return new Promise( (resolve, reject) => {

                let url=context.state.baseurlapi+"/rfesh8495848484dfbfer498/"+idtoken+"/"+randomString(130)

                   axios
                    .get(url, {
                        headers: {
                           /* 'xccsrftoken':"tokenid",
                            'incsrftoken':"12458779955",
                            'btkcsrftoken':"rerueiriuerierieuiueri",*/
                        }
                    })
                    .then(response => {

                        console.log("response...",response)

                    if(response.data.refresh){

                        context.commit('isAuthenticated',{value:true})
                        context.commit('appusername',{name:JwtService.getTokenUser()})
                        context.commit('appuserpicture',{name:response.data.datas._picture})
                        context.commit('appsociete',{name:response.data.datas._utk})


                        context.commit('etatlogo',{etatlogo:response.data.datas.__etatinfo.etatlogo})
                        context.commit('etatraisonsocial',{etatraisonsocial:response.data.datas.__etatinfo.etatraisonsocial})
                        context.commit('etatifu',{etatifu:response.data.datas.__etatinfo.etatifu})
                        context.commit('etatrccm',{etatrccm:response.data.datas.__etatinfo.etatrccm})
                        context.commit('etattelephone',{etattelephone:response.data.datas.__etatinfo.etattelephone})
                        context.commit('etatadresse',{etatadresse:response.data.datas.__etatinfo.etatadresse})


                        context.commit('tauxipts',{value:response.data.datas.__tauxipts})
                        context.commit('sbasestatuaire_jourdetrail',{value:response.data.datas.__basestatuaire_jourdetrail})
                        context.commit('stauxcnss_employeur',{value:response.data.datas.__tauxcnss_employeur})
                        context.commit('stauxcnss_salarie',{value:response.data.datas.__tauxcnss_salarie})
                        context.commit('stauxvps',{value:response.data.datas.__tauxvps})
                        context.commit('stauxhoraire',{value:response.data.datas.__tauxhoraire})
                        context.commit('sysmodule',{value:response.data.datas.__module})
                        context.commit('paramsystem',{value:response.data.datas.__sssys})
                        context.commit('nbrepersautorise',{value:response.data.datas.__nbrpers})
                        context.commit('devise',{name:response.data.datas.devise})
                        context.commit('isworkSunday',{name:response.data.datas.isworkSunday})
                        context.commit('isworkSaturday',{name:response.data.datas.isworkSaturday})
                        context.commit('separationdevise',{name:response.data.datas.separationdevise})






                        /*
                                                context.commit('userdata',{value:response.data.userData.userinfo})
                                                context.commit('beartoken',{value:response.data.userData.sitetoken})
                        */

                      /*  context.commit('isAuthenticated',{value:true});
                        context.commit('appusername',{name:JwtService.getTokenUser()})
                        context.commit('approle',{name:JwtService.getTokenRole()})
                        context.commit('appsociete',{name:JwtService.getTokenSociete()})
                        context.commit('listeallcaisses',{listeallcaisses:response.data.allcaisse})
                        //context.commit('allccessapp',{allccessapp:response.data.iujhi7855})
                        context.commit('accescomponent',{accescomponent:response.data.iujhi7855})
                        context.commit('beartoken',{value:response.data.beartoken})


                        context.commit('authorizechangementprix',{authorizechangementprix:response.data.__auth.authorizechangementprix})
                        context.commit('authorizenormalisation',{authorizenormalisation:response.data.__auth.authorizenormalisation})
                        context.commit('authorizeprix0',{authorizeprix0:response.data.__auth.authorizeprix0})
                        context.commit('authorizefacturactionpartiel',{authorizefacturactionpartiel:response.data.__auth.authorizefacturactionpartiel})
                        context.commit('authorizepointdevente',{authorizepointdevente:response.data.__auth.authorizepointdevente})
                        context.commit('authorizelaproceduredevente',{authorizelaproceduredevente:response.data.__auth.authorizelaproceduredevente})
                        context.commit('authorizefacturepourgenereranalyse',{authorizefacturepourgenereranalyse:response.data.__auth.authorizefacturepourgenereranalyse})
                        context.commit('authorizelaproceduredevalidationdocstock',{authorizelaproceduredevalidationdocstock:response.data.__auth.authorizelaproceduredevalidationdocstock})



                        context.commit('lignefacturation1',{lignefacturation1:response.data.__etatinfo.lignefacturation1})
                        context.commit('lignefacturation2',{lignefacturation2:response.data.__etatinfo.lignefacturation2})
                        context.commit('lignefacturation3',{lignefacturation3:response.data.__etatinfo.lignefacturation3})
                        context.commit('lignefacturationfooter1',{lignefacturationfooter1:response.data.__etatinfo.lignefacturationfooter1})
                        context.commit('lignefacturationfooter2',{lignefacturationfooter2:response.data.__etatinfo.lignefacturationfooter2})
                        context.commit('etatlogo',{etatlogo:response.data.__etatinfo.etatlogo})
                        context.commit('typfenetreventeaucomptoire',{typfenetreventeaucomptoire:response.data.__etatinfo.typfenetreventeaucomptoire})
                        context.commit('etatraisonsocial',{etatraisonsocial:response.data.__etatinfo.etatraisonsocial})
                        context.commit('etatifu',{etatifu:response.data.__etatinfo.etatifu})
                        context.commit('etatrccm',{etatrccm:response.data.__etatinfo.etatrccm})
                        context.commit('etattelephone',{etattelephone:response.data.__etatinfo.etattelephone})
                        context.commit('etatadresse',{etatadresse:response.data.__etatinfo.etatadresse})



                        context.commit('paramsystem',{value:response.data.userData.__sssys})
                        context.commit('paramsapp',{value:response.data.userData.__sssysapp})




                        JwtService.setAuthorization(JwtService.getToken())*/

                        //this.$router.push({name: 'acceuilpage'});


                    }
                    else{

                        console.log("not found...")

                        JwtService.unsetTokenRole();
                        JwtService.unsetToken();
                       JwtService.unsetTokenEXPIRESIN();
                        JwtService.unsetTokenRole();
                        JwtService.unsetTokenID();
                        JwtService.unsetTokenUser();
                    }

                  return   resolve(response);
                }, error => {
                    // http failed, let the calling function know that action did not work out
                        return  reject(error);
                })
            })


        },





    },
});

