<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title headertitle" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="box-body">
                        <div class="border-b-0 border-gray-200 dark:border-white/10">
                            <nav class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" aria-label="Tabs">
                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 active"
                                        id="hs-tab-js-behavior-item-1" data-hs-tab="#hs-tab-js-behavior-1" aria-controls="hs-tab-js-behavior-1">
                                    -
                                </button>



                            </nav>
                        </div>

                        <div class="">
                            <div id="hs-tab-js-behavior-1" role="tabpanel" aria-labelledby="hs-tab-js-behavior-item-1" class="">
                                <div class="ti-custom-validation mt-5" >

                                    <!-- Start::row-1 -->
                                    <div class="w-full grid grid-cols-12">
                                        <div class="xl:col-span-12 col-span-12">
                                            <div class="box  overflow-hidden">
                                                <div class="box-header justify-between">

                                                    <div class="w-full grid grid-cols-12 gap-x-6">



                                                        <div class="mb-4  xl:col-span-6 col-span-12">
                                                            <label for="small-file-input" class="sr-only">Choose file</label>
                                                            <input type="file" name="small-file-input" id="small-file-input"
                                                                   class="block w-full border border-gray-200 focus:shadow-sm dark:focus:shadow-white/10 rounded-sm text-sm focus:z-10 focus:outline-0 focus:border-gray-200 dark:focus:border-white/10 dark:border-white/10 dark:text-white/50
                                       file:border-0
                                      file:bg-light file:me-4
                                      file:py-2 file:px-4
                                      dark:file:bg-black/20 dark:file:text-white/50">
                                                        </div>



                                                        <div class="mb-4  xl:col-span-6 col-span-12">
                                                            <input v-model="searchword" class="form-control form-control-lg" type="text"
                                                                   placeholder="Rechercher par le nom et prénom(s)"
                                                                   aria-label=".form-control-lg example">
                                                        </div>





                                                    </div>
                                                </div>


                                                <div class="box-body !p-0">



                                                    <div class="download-data">
                                                        <!--button type="button" class="ti-btn ti-btn-primary" ><i class="ri-file-excel-fill"></i> Exporter en excel</button>
                                                        <button type="button" class="ti-btn ti-btn-facebook" ><i class="ri-printer-fill"></i> Imprimer</button-->
                                                    </div>

                                                    <div class="table-responsive mt-3">

                                                        <div class="flex flex-col h-screen">
                                                            <div class="flex-grow overflow-auto">


                                                            <table class="table mytable table-hover whitespace-nowrap min-w-full">
                                                            <thead>

                                                             <tr>
                                                                 <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">KEYLINE</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">NOM ET PRÉNOM(S)</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">HEURE D'ARRIVÉE</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">HEURE DE SORTIE</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">DURÉE DE PAUSE</th>
                                                                <th scope="col" class="sticky top-0 px-6 py-3 text-red-900 bg-red-300 text-start">NOMBRE D'HEURE DE TRAVAIL
                                                                </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>


                                                                <tr >
                                                                    <td colspan="20"><div class="alert alert-danger text-center">Aucun enregistrement n'a été trouvé.</div></td>
                                                                </tr>



                                                            </tbody>


                                                                <tfoot>
                                                                <tr>
                                                                    <td colspan="20">
                                                                        <button class="w-full ti-btn ti-btn-secondary-full ti-btn-wave"> <i class="bi bi-upload"></i> Importer le fichier</button>
                                                                    </td>
                                                                </tr>
                                                                </tfoot>
                                                        </table>


                                                            </div>
                                                        </div>




                                                    </div>
                                                </div>





                                                <div class="box-footer border-t-0">
                                                    <div class="flex items-center flex-wrap overflow-auto">
                                                        <div class="mb-2 sm:mb-0">

                                                        </div>
                                                        <div class="ms-auto">


                                                            <!--nav aria-label="Page navigation">
                                                                <ul class="ti-pagination  mb-0">
                                                                    <li class="page-item "><button  :class="{ 'disabled': page <= 1 }" class="page-link px-3 py-[0.375rem]"  @click="page--">Previous</button></li>
                                                                    <li class="page-item">

                                                                        <button class="page-link px-3 py-[0.375rem]" :class="{ 'active': pageNumber === page }"
                                                                                :key="key" v-for="(pageNumber,key) in pages.slice(page-1, page+5)"
                                                                                @click="page = pageNumber">{{pageNumber}}</button>

                                                                    </li>
                                                                    <li class="page-item" ><button :class="{ 'disabled': page => pages.length }" class="page-link px-3 py-[0.375rem]" @click="page++" >Next</button></li>
                                                                </ul>
                                                            </nav-->



                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--End::row-1 -->















                                </div>
                            </div>



                        </div>
                    </div>




                </div>
                <div class="ti-modal-footer">
                    <button style="background: red" @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" >
                        Fermer
                    </button>
                    <button style="background: green" v-if="typeaction==='add'" class="ti-btn ti-btn-primary-full" href="javascript:void(0);">
                       Enregistrer
                    </button>
                    <button style="background: green" v-if="typeaction==='update'" class="ti-btn ti-btn-primary-full" href="javascript:void(0);">
                       Modifier
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



</template>

<script>

    export default {
        /* eslint-disable */
        name: "modalform",
        watch: {


        },

        computed:{




        },
        components: {


        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",
                searchword:"",
                typedoc:"",
                typeaction:"add",
            };
        },
        methods: {

            close(){

                this.isdisplay=false;

                this.$emit('closed');

            },

            show(titlemodal,typeaction){

                this.titlemodal=titlemodal
                this.typeaction=typeaction

                this.isdisplay=true;

            },





        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>
    .modalstyle{
        max-width: 90%!important;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


    }





</style>