<template>


    <div v-if="isdisplay" class=" open ti-modal" aria-overlay="false" tabindex="-1">
        <div class="hs-overlay-open:mt-7 ti-modal-box mt-0 ease-out min-h-[calc(100%-3.5rem)] flex items-center modalstyle" >
            <div class="ti-modal-content" style="width: 100%!important;">
                <div class="ti-modal-header">
                    <h6 class="modal-title" id="staticBackdropLabel2">{{titlemodal}}
                    </h6>
                    <button @click="close" type="button" class="hs-dropdown-toggle ti-modal-close-btn"  >
                        <span class="sr-only">Close</span>
                        <svg class="w-3.5 h-3.5" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z" fill="currentColor"/>
                        </svg>
                    </button>
                </div>
                <div class="ti-modal-body">

                    <div class="box-body">
                        <div class="border-b-0 border-gray-200 dark:border-white/10">
                            <nav class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400" aria-label="Tabs">
                                <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent
                                                hs-tab-active:text-primary dark:hs-tab-active:bg-transparent dark:hs-tab-active:border-b-white/10 dark:hs-tab-active:text-primary -mb-px py-2 px-3
                                                 inline-flex items-center gap-2 bg-gray-50 text-sm font-medium text-center border text-defaulttextcolor rounded-t-sm hover:text-primary
                                                 dark:bg-black/20 dark:border-white/10 dark:text-[#8c9097] dark:text-white/50 dark:hover:text-gray-300 active"
                                        id="hs-tab-js-behavior-item-1" data-hs-tab="#hs-tab-js-behavior-1" aria-controls="hs-tab-js-behavior-1">
                                    Information de base
                                </button>



                            </nav>
                        </div>

                        <div class="">
                            <div id="hs-tab-js-behavior-1" role="tabpanel" aria-labelledby="hs-tab-js-behavior-item-1" class="">
                                <div class="ti-custom-validation mt-5" >
                                    <div class="grid grid-cols-12 gap-x-6">


                                        <!--div class="space-y-2 xl:col-span-8 col-span-12">

                                            <div class="grid sm:grid-cols-3 gap-6">
                                                <div class="space-y-2">

                                                    <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">DATE</label>
                                                    <div style="padding: 0px;padding-left: 10px" class="input-group-text text-[#8c9097] dark:text-white/50">
                                                        <i style="padding: 5px;" class="ri-calendar-line"></i>
                                                    <input type="date" min="0" class="firstName my-auto ti-form-input  rounded-sm"
                                                         v-model="Item.dateheuresupp"  placeholder="DATE"   >
                                                </div>

                                                </div>

                                                <div class="space-y-2">
                                                    <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">HEURE DEBUT</label>
                                                    <div style="padding: 0px;padding-left: 10px" class="input-group-text text-[#8c9097] dark:text-white/50">
                                                        <i style="padding: 5px;" class="ri-time-line"></i>
                                                    <input type="time" min="0" class="firstName my-auto ti-form-input  rounded-sm"
                                                           v-model="Item.heuredebut"   placeholder="HEURE DEBUT"   >
                                                    </div>

                                                </div>

                                                <div class="space-y-2">
                                                    <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">HEURE DE FIN</label>

                                                    <div style="padding: 0px;padding-left: 10px" class="input-group-text text-[#8c9097] dark:text-white/50">
                                                        <i style="padding: 5px;" class="ri-time-line"></i>
                                                        <input type="time"  class="my-auto ti-form-input  rounded-sm"
                                                               v-model="Item.heurefin"    placeholder="HEURE DE FIN"  >
                                                    </div>



                                                </div>


                                            </div>




                                            <div class="space-y-2">
                                                <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">MOTIF</label>
                                                <textarea id="message" rows="4" class="block  my-auto ti-form-input  rounded-sm"
                                                          v-model="Item.motif"       placeholder="MOTIF..."></textarea>
                                            </div>



                                        </div-->

                                        <div class="space-y-2 xl:col-span-8 col-span-12">


                                            <div class="space-y-2 ">
                                                <label class="ti-form-label dark:text-defaulttextcolor/70 mb-0">Personnel</label>
                                                <div class="grid grid-cols-12  gap-x-6">

                                                    <div class="xl:col-span-10  col-span-12" >
                                                        <input placeholder="Veuillez sélectionner un personnel"  v-if="Item.personnel===null"
                                                               type="text" class="form-control" disabled>
                                                        <input v-else :value="Item.personnel.name" type="text" class="form-control" disabled>
                                                    </div>
                                                    <div v-if="typeaction==='add'" class="xl:col-span-2  col-span-12">
                                                        <button title="Sélectionner un personnel"  @click="btnmodallistedupersonnel('permanent')"  class="ti-btn ti-btn-success-full ti-btn-lg ti-btn-wave">...</button>
                                                    </div>

                                                </div>
                                            </div>





                                            <!--div class="space-y-2 mt-2">
                                                <label  >Nombre d'heure (12%) : </label>
                                                <input type="number" min="0"  class="firstName my-auto ti-form-input  rounded-sm"
                                                  v-model="Item.nbre_heure_12"    @input="validateInput"   placeholder="veuillez saisir le nombre d'heure supplémentaire de 12% "   >
                                            </div>
                                            <div class="space-y-2 mt-2">
                                                <label  >Nombre d'heure (35%) : <br> </label>
                                                <input type="number" min="0" class="firstName my-auto ti-form-input  rounded-sm"
                                                   v-model="Item.nbre_heure_35"    @input="validateInput35"      placeholder="veuillez saisir le nombre d'heure supplémentaire de 35%"   >
                                            </div-->

                                            <div class="space-y-2 mt-2">
                                                <label  >Nombre d'heure (12%) : </label>
                                                <input type="number" min="0"  class="firstName my-auto ti-form-input  rounded-sm"
                                                  v-model="Item.nbre_heure_12"    placeholder="veuillez saisir le nombre d'heure supplémentaire de 12% "   >
                                            </div>
                                            <div class="space-y-2 mt-2">
                                                <label  >Nombre d'heure (35%) : <br> </label>
                                                <input type="number" min="0" class="firstName my-auto ti-form-input  rounded-sm"
                                                   v-model="Item.nbre_heure_35"   placeholder="veuillez saisir le nombre d'heure supplémentaire de 35%"   >
                                            </div>
                                            <div class="space-y-2 mt-2">
                                                <label  >Nombre d'heure (50%) : <br> </label>
                                                <input type="number" min="0" class="firstName my-auto ti-form-input  rounded-sm"
                                                   v-model="Item.nbre_heure_50"    placeholder="number"   >
                                            </div>

                                            <div class="space-y-2 mt-2">
                                                <label  >Nombre d'heure (100%) : <br> </label>
                                                <input type="number" min="0" class="firstName my-auto ti-form-input  rounded-sm"
                                                 v-model="Item.nbre_heure_100"      placeholder="veuillez saisir le nombre d'heure supplémentaire de 100%"   >
                                            </div>

                                            <div class="space-y-2 mt-2">
                                                <label  >Nombre d'heure total : <br> </label>
                                                <h2>{{nbre_total_dheure}}</h2>
                                            </div>


                                        </div>




                                    </div>


                                </div>
                            </div>



                        </div>
                    </div>




                </div>
                <div class="ti-modal-footer">
                    <button style="background: red" @click="close" type="button" class="hs-dropdown-toggle ti-btn ti-btn-secondary-full" >
                        Fermer
                    </button>
                    <button style="background: green" @click="saveItem" v-if="typeaction==='add'" class="ti-btn ti-btn-primary-full" href="javascript:void(0);">
                       Enregistrer
                    </button>
                    <button style="background: green" @click="updateItem" v-if="typeaction==='update'" class="ti-btn ti-btn-primary-full" href="javascript:void(0);">
                       Modifier
                    </button>
                </div>
            </div>
        </div>
    </div>


    <div v-if="isdisplay" data-hs-overlay-backdrop-template=""
         class="transition duration fixed inset-0 z-50 bg-gray-900 bg-opacity-50 dark:bg-opacity-80 hs-overlay-backdrop"></div>



    <modallistedupersonnel @elementSelectionne="handleElementSelectionne" ref="modallistedupersonnel"></modallistedupersonnel>

</template>

<script>
    import templatesite from "@/components/template/apptemplate";
    import appheader from "@/components/template/appheader";
    import appsidebar from "@/components/template/appsidebar";
    import appmenu from "@/components/views/pages/definition/menu";
    import modallistedupersonnel from "@/components/views/pages/paie/modal/modal_liste_du_personnel";
    import {currentDate, randomString} from "@/allfunction";
    import * as JwtService from "@/common/jwt.service";
    const axios =require('axios');


    export default {
        /* eslint-disable */
        name: "modalform",
        watch: {


        },

        computed:{


          /*  nbre_heure_12(){

                return this.Item.nbre_heure_12;

            },

            nbre_heure_35(){

                return this.Item.nbre_heure_35;

            },

            nbre_heure_50(){

                return this.Item.nbre_heure_50;

            },
            nbre_heure_100(){

                return this.Item.nbre_heure_100;

            },*/


            nbre_total_dheure(){


                //create date format
                /*     var timeStart = new Date(this.Item.dateheuresupp+" "+ this.Item.heuredebut).getHours();
                     var timeEnd = new Date(this.Item.dateheuresupp+" "+ this.Item.heurefin).getHours();
             */

                /*var timeStart = new Date(this.Item.dateheuresupp+" "+ this.Item.heuredebut);
                var timeEnd = new Date(this.Item.dateheuresupp+" "+ this.Item.heurefin);

                var collecttimeheureStart=timeStart.getHours()
                var collecttimeheureend=timeEnd.getHours()

                var collecttimeminuteStart=timeStart.getMinutes()
                var collecttimeminuteend=timeEnd.getMinutes()

                collecttimeheureStart=collecttimeheureStart+(collecttimeminuteStart/60)
                collecttimeheureend=collecttimeheureend+(collecttimeminuteend/60)*/


                /* console.log('timeStart ',collecttimeheureStart)
                 console.log('timeEnd ',collecttimeheureend) */

               // var hourDiff = collecttimeheureend - collecttimeheureStart;

                /* console.log('hourDiff ',hourDiff) */
/*

                if (hourDiff<8){

                    this.Item.nbre_heure_12=hourDiff

                }else{
                    this.Item.nbre_heure_12=8
                    this.Item.nbre_heure_35=hourDiff-8

                }


                this.Item.nbre_total_dheure=hourDiff*/

                this.Item.nbre_total_dheure=parseFloat(this.Item.nbre_heure_12)+parseFloat(this.Item.nbre_heure_35)+parseFloat(this.Item.nbre_heure_50)
                +parseFloat(this.Item.nbre_heure_100)

                return this.Item.nbre_total_dheure;

            },






        },
        components: {
            templatesite,
            appheader,
            appsidebar,
            appmenu,
            modallistedupersonnel,

        },

        data() {
            return {
                isdisplay:false,
                titlemodal:"",
                mmontantsalaire:0,
                typedoc:"",
                typeaction:"add",
                listedupersonnel:[],
                Item: {pid:'',personnel:null,societe:"",typ:"heure_supplementaire"
                    ,dateheuresupp:currentDate(),heuredebut:'',heurefin:'',
                    nbre_heure_12:0,nbre_heure_35:0,nbre_heure_50:0,
                    nbre_heure_100:0,nbretotaldheure:0,motif:''},

            };
        },
        methods: {


            validateInput() {
                if (this.Item.nbre_heure_12 < 0) {
                    this.Item.nbre_heure_12 = 0;
                } else if (this.Item.nbre_heure_12 > 8) {
                    this.Item.nbre_heure_12 = 8;
                }
            },

            validateInput35() {

                if (this.Item.nbre_heure_12===0){this.Item.nbre_heure_35=0; return}

               /* if (this.Item.nbre_heure_12 < 0) {
                    this.Item.nbre_heure_12 = 0;
                } else if (this.Item.nbre_heure_12 > 8) {
                    this.Item.nbre_heure_12 = 8;
                }*/
            },



           async handleElementSelectionne(elementSelectionne) {
                // Gérer l'élément sélectionné reçu de ModalComponent

                //  console.log("elementSelectionne ********** ",elementSelectionne)

                /* if (elementSelectionne.typ==="service"){
                     this.Items.jsonv.service=elementSelectionne.value
                 }

                 if (elementSelectionne.typ==="fonction"){
                     this.Items.jsonv.fonction=elementSelectionne.value
                 }

                 if (elementSelectionne.typ==="qualification"){
                     this.Items.jsonv.qualification=elementSelectionne.value
                 }*/



                if (elementSelectionne.value!==null){

                    this.$store.dispatch('lancerchargement',true)
                    const t = this;
                    let url=process.env.VUE_APP_API_URL+'/egl74dkjskdj5874ppaie771714dfe/paie/'+this.$store.state.appsociete+'/operation/hspp/'+randomString()+'/'+randomString();
                    let response =   axios  .post(url,{__p:'personnel',datas:t.$store.state.dataspersonnel,
                        typ:'heure_supplementaire',__tt:'personnel',personnel:elementSelectionne.value},{
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                            'Accept':'application/json',
                            'Content-Type':'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                            'Access-Control-Allow-Headers': 'Content-Type',
                            'Authorization': 'Bearer ' + JwtService.getToken(),
                            'csrftoken': this.$store.state.appsociete
                        }
                    })

                    let res= await response
                        .then(function (res){
                            t.$store.dispatch('lancerchargement',false)

                            t.Item.personnel=elementSelectionne.value

                            if(res.data.success){

                                t.Item.nbre_heure_12=res.data.m.nbreheure12
                                t.Item.nbre_heure_35=res.data.m.nbreheure35
                                t.Item.nbre_heure_50=res.data.m.nbreheure50
                                t.Item.nbre_heure_100=res.data.m.nbreheure100
                                t.Item.nbretotaldheure=res.data.m.nbreheuretotal
                                t.Item.pid=res.data.m.keyline
                                t.Item.typ="mheure_supplementaire"
                                t.typeaction="update"




                            }
                            else{
                                t.Item.nbre_heure_12=res.data.m.nbreheure12
                                t.Item.nbre_heure_35=res.data.m.nbreheure35
                                t.Item.nbre_heure_50=res.data.m.nbreheure50
                                t.Item.nbre_heure_100=res.data.m.nbreheure100
                                t.Item.nbretotaldheure=res.data.m.nbreheuretotal
                                t.Item.pid=res.data.m.keyline
                                t.Item.typ="heure_supplementaire"
                                t.typeaction="add"
                            }

                        })
                        .catch((error)=>{

                                t.$store.dispatch('lancerchargement',false)
                            }
                        )
                        .finally(() =>{

                            t.$store.dispatch('lancerchargement',false)


                        })






                }
                else{

                    this.Item.personnel=elementSelectionne.value

                }



            },

            btnmodallistedupersonnel(typ){

                let listedatable=[],
                    titlemodal="",
                    typedoc=typ;


                if (typ==="permanent"){

                    listedatable=this.listedupersonnel
                    titlemodal="Liste du personnel permanent"
                }

                if (typ==="occasionnel"){

                    listedatable=this.listedupersonnel
                    titlemodal="Liste du personnel occasionnel"
                }

                this.$refs.modallistedupersonnel.show(titlemodal,listedatable,typedoc);

            },

            close(){

                this.isdisplay=false;

                this.$emit('closed');

            },

           /* show(titlemodal,typeaction,typedoc){

                this.titlemodal=titlemodal
                this.typeaction=typeaction
                this.typedoc=typedoc

                this.isdisplay=true;

            },*/

            show(titlemodal,typeaction,typedoc,listedupersonnel,Item){
                  this.titlemodal=titlemodal
                this.typeaction=typeaction
                this.typedoc=typedoc
                this.listedupersonnel=listedupersonnel
                this.Item=Item

                this.isdisplay=true;

            },

            async saveItem(){
                this.$store.dispatch('lancerchargement',true)
                this.Item.motif="heure supp";

                if (this.Item.personnel===null){

                    toastr['error']( 'Veuillez sélectionner un personnel',"<b></b>");
                    this.$store.dispatch('lancerchargement',false)
                    return

                }

                if (this.Item.motif===""){

                    toastr['error']( 'Veuillez saisir un motif.',"<b></b>");
                    this.$store.dispatch('lancerchargement',false)
                    return

                }



               /* if (this.Item.montant===0){

                    toastr['error']( 'Le montant du prêt n\'est pas valide.',"<b></b>");
                    this.$store.dispatch('lancerchargement',false)
                    return

                }*/






                this.Item.tp=this.typedoc;
                this.Item.montant=258000;
                this.Item.datas=this.$store.state.dataspersonnel;


                {


                    const t = this;
                    let url=process.env.VUE_APP_API_URL+'/egl74dkjskdj5874ppaie771714dfe/paie/'+this.$store.state.appsociete+'/soperation/'+this.typedoc+'/'+randomString();
                    let response =   axios  .post(url,this.Item,{
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                            'Accept':'application/json',
                            'Content-Type':'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                            'Access-Control-Allow-Headers': 'Content-Type',
                            'Authorization': 'Bearer ' + JwtService.getToken(),
                            'csrftoken': this.$store.state.appsociete
                        }
                    })
                    let res= await response
                        .then(function (res){
                          t.$store.dispatch('lancerchargement',false)
                            if(res.data.success){


                                toastr['success']( res.data.message,"<b></b>");
                                // t.$store.dispatch('listecatalogue',res.data.lst)

                                // t.isdisplay=false


                                t.close();

                            }else{


                                toastr['error']( res.data.message,"<b></b>");
                                // t.$store.dispatch('lancerchargement',false)
                            }


                        })
                        .catch(

                            error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                                "<b>Erreur</b>")
                        )
                        .finally(() => this.$store.dispatch('lancerchargement',false))






                }

            },



            async updateItem(){
                this.$store.dispatch('lancerchargement',true)

                this.Item.motif="Heure supplémentaire.";

                if (this.Item.personnel===null){

                    toastr['error']( 'Veuillez sélectionner un personnel',"<b></b>");
                    this.$store.dispatch('lancerchargement',false)
                    return

                }

                if (this.Item.motif===""){

                    toastr['error']( 'Veuillez saisir un motif.',"<b></b>");
                    this.$store.dispatch('lancerchargement',false)
                    return

                }



               /* if (this.Item.montant===0){

                    toastr['error']( 'Le montant du prêt n\'est pas valide.',"<b></b>");
                    this.$store.dispatch('lancerchargement',false)
                    return

                }*/






                this.Item.tp="mheure_supplementaire";
                this.Item.montant=258000;
                this.Item.datas=this.$store.state.dataspersonnel;


                {


                    const t = this;
                    let url=process.env.VUE_APP_API_URL+'/egl74dkjskdj5874ppaie771714dfe/paie/'+this.$store.state.appsociete+'/soperation/'+this.typedoc+'/'+randomString();
                    let response =   axios  .post(url,this.Item,{
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                            'Accept':'application/json',
                            'Content-Type':'application/json',
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Methods': 'OPTIONS,GET,PUT,POST,DELETE',
                            'Access-Control-Allow-Headers': 'Content-Type',
                            'Authorization': 'Bearer ' + JwtService.getToken(),
                            'csrftoken': this.$store.state.appsociete
                        }
                    })
                    let res= await response
                        .then(function (res){
                          t.$store.dispatch('lancerchargement',false)
                            if(res.data.success){


                                toastr['success']( res.data.message,"<b></b>");
                                // t.$store.dispatch('listecatalogue',res.data.lst)

                                // t.isdisplay=false


                                t.close();

                            }else{


                                toastr['error']( res.data.message,"<b></b>");
                                // t.$store.dispatch('lancerchargement',false)
                            }


                        })
                        .catch(

                            error => toastr['error']( "Une erreur est subvenue. <br/> veuillez contacter votre administrateur.",
                                "<b>Erreur</b>")
                        )
                        .finally(() => this.$store.dispatch('lancerchargement',false))






                }

            },








        },
        mounted() {

            // this.loaddata();
        }




    }
</script>

<style scoped>
    .modalstyle{
        max-width: 60%!important;
    }

    @media screen and (max-width: 768px) {

        .modalstyle{
            max-width: 100%!important;
        }


    }





</style>