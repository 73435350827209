import { createRouter, createWebHistory } from 'vue-router'
import index from "@/components/views/index";
//import convertisseur from "@/components/views/convertisseur";
//import marketcup from "@/components/views/marketcup";
import notification from "@/components/views/notification";


import tbdefinition from "@/components/views/pages/definition/tb";
import tbdefinitionpersonnel from "@/components/views/pages/definition/personnel/index";
import tbdefinitionoccasionnel from "@/components/views/pages/definition/occasionnel/index";
import indedefinitionfichierbase from "@/components/views/pages/definition/tiers/fichiersdebase/index";
import tbpaie from "@/components/views/pages/paie/tb";
import tbpresence from "@/components/views/pages/presence/tb";
import trapport from "@/components/views/pages/rapport/tb";
import indexpaiepermanentencour from "@/components/views/pages/paie/permanent/tb";
import bulletindepaie from "@/components/views/pages/paie/permanent/bulletindepaie/bulletindepaie";
import bulletindepaieoccasionnel from "@/components/views/pages/paie/occasionnel/bulletindepaie/bulletindepaie";
import avancesursalairepaieoccasionnel from "@/components/views/pages/paie/occasionnel/avance_sur_salaire/index";
import honoraireaotachepaieoccasionnel from "@/components/views/pages/paie/occasionnel/honoraireao/index";
import traitementdebasedelapaiedespermanents from "@/components/views/pages/paie/permanent/retenue/index";
import heuresupplementairepaiedespermanents from "@/components/views/pages/paie/permanent/heuresupplementaire/index";
import indexpaieencouroccasionnel from "@/components/views/pages/paie/occasionnel/tb";





import loginpage from "@/components/views/auth/login";
import registerpage from "@/components/views/auth/register";

const routes = [
   {
        name:'login',
    path: '/login',
    component: loginpage,
        meta: {
            requiresAuth: false,
            roles: ['Membre','Administrateur']
        }
  },
   {
        name:'register',
    path: '/register',
    component: registerpage,
        meta: {
            requiresAuth: false,
            roles: ['Membre','Administrateur']
        }
  },

   {
       name:'acceuilpage',
    path: '/',
    component: index,
       meta: {
           requiresAuth: true,
           roles: ['Membre','Administrateur']
       }
  },



   {
       name:'tbpresence',
    path: '/presence',
    component: tbpresence,
       meta: {
           requiresAuth: true,
           roles: ['Membre','Administrateur']
       }
  },

   {
       name:'trapport',
    path: '/rapport',
    component: trapport,
       meta: {
           requiresAuth: true,
           roles: ['Membre','Administrateur']
       }
  },

   {
       name:'tbpaie',
    path: '/paie',
    component: tbpaie,
       meta: {
           requiresAuth: true,
           roles: ['Membre','Administrateur']
       }
  },

    {
        name: 'indexpaiepermanentencour',
        path: '/paie/paie_en_cour/permanent/:mois/:anne',
        component: indexpaiepermanentencour,
        props:true,
        meta: {
            requiresAuth: true,
            roles: ['Membre','Administrateur']
        }
    },

    {
        name: 'bulletindepaie',
        path: '/paie/paie_en_cour/permanent/bulletindepaie/:mois/:anne',
        component: bulletindepaie,
        props:true,
        meta: {
            requiresAuth: true,
            roles: ['Membre','Administrateur']
        }
    },


    {
        name: 'bulletindepaieoccasionnel',
        path: '/paie/paie_en_cour/occasionnel/bulletindepaie/:mois/:anne',
        component: bulletindepaieoccasionnel,
        props:true,
        meta: {
            requiresAuth: true,
            roles: ['Membre','Administrateur']
        }
    },



    {
        name: 'honoraireaotachepaieoccasionnel',
        path: '/paie/paie_en_cour/occasionnel/honoraire_ao/:mois/:anne',
        component: honoraireaotachepaieoccasionnel,
        props:true,
        meta: {
            requiresAuth: true,
            roles: ['Membre','Administrateur']
        }
    },


    {
        name: 'avancesursalairepaieoccasionnel',
        path: '/paie/paie_en_cour/occasionnel/:typ/:mois/:anne',
        component: avancesursalairepaieoccasionnel,
        props:true,
        meta: {
            requiresAuth: true,
            roles: ['Membre','Administrateur']
        }
    },



    {
        name: 'heuresupplementairepaiedespermanents',
        path: '/paie/paie_en_cour/permanent/heure_supplementaire/:mois/:anne',
        component: heuresupplementairepaiedespermanents,
        props:true,
        meta: {
            requiresAuth: true,
            roles: ['Membre','Administrateur']
        }
    },



    {
        name: 'traitementdebasedelapaiedespermanents',
        path: '/paie/paie_en_cour/permanent/:typ/:mois/:anne',
        component: traitementdebasedelapaiedespermanents,
        props:true,
        meta: {
            requiresAuth: true,
            roles: ['Membre','Administrateur']
        }
    },


    {
        name: 'indexpaieencouroccasionnel',
        path: '/paie/paie_en_cour/occasionnel/:mois/:anne',
        component: indexpaieencouroccasionnel,
        props:true,
        meta: {
            requiresAuth: true,
            roles: ['Membre','Administrateur']
        }
    },

   {
       name:'tbdefinition',
    path: '/definition',
    component: tbdefinition,
       meta: {
           requiresAuth: true,
           roles: ['Membre','Administrateur']
       }
  },

   {
       name:'tbdefinitionpersonnel',
    path: '/definition/personnel',
    component: tbdefinitionpersonnel,
       meta: {
           requiresAuth: true,
           roles: ['Membre','Administrateur']
       }
  },

    {
        name: 'indexdefinitiontiers',
        path: '/definition/tiers/:typ',
        component: indedefinitionfichierbase,
        meta: {
            requiresAuth: true,
            roles: ['Administrateur']
        }
    },

   {
       name:'tbdefinitionoccasionnel',
    path: '/definition/occasionnel',
    component: tbdefinitionoccasionnel,
       meta: {
           requiresAuth: true,
           roles: ['Membre','Administrateur']
       }
  },







   {
       name:'notification',
    path: '/notification',
    component: notification,
       meta: {
           requiresAuth: true,
           roles: ['Membre','Administrateur']
       }
  },






]

const router = createRouter({
 // history: createWebHistory(process.env.BASE_URL),
   history: createWebHistory(),
  routes
})

export default router




